"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.KMLTO_MPG = void 0;
exports.convertToBase = convertToBase;
exports.convertFromBase = convertFromBase;
exports.convertUnits = convertUnits;
exports.getConversionFactor = getConversionFactor;
exports.convertToKJ = convertToKJ;
exports.getEnergyConsumptionValue = getEnergyConsumptionValue;
exports.compareEnergyUnitTypes = compareEnergyUnitTypes;
const type_1 = require("../type");
const unitConversions = {
  [type_1.ConsumptionType.FUEL_SOLID]: {
    [type_1.SolidUnit.POUND]: 2000,
    [type_1.SolidUnit.SHORT_TON]: 1,
    [type_1.SolidUnit.METRIC_TON]: 0.90718474,
    [type_1.SolidUnit.LONG_TON]: 0.892857143,
    [type_1.SolidUnit.KG]: 907.18474,
    [type_1.SolidUnit.GRAM]: 907184.74
  },
  [type_1.ConsumptionType.FUEL_LIQUID]: {
    [type_1.LiquidUnit.GALLON]: 1,
    [type_1.LiquidUnit.LITTER]: 3.78541178,
    [type_1.EnergyLiquidUnit.KILLO_LITTER]: 3785.41178,
    [type_1.GasUnit.STANDARD_CUBIC_FOOT]: 0.133680556,
    [type_1.EnergyGasUnit.STANDARD_CUBIC_METER]: 0.00378541,
    [type_1.EnergyGasUnit.CUBIC_METER]: 0.00378541
  },
  [type_1.ConsumptionType.FUEL_GAS]: {
    [type_1.GasUnit.STANDARD_CUBIC_FOOT]: 1,
    [type_1.EnergyGasUnit.STANDARD_CUBIC_METER]: 35.3147,
    [type_1.EnergyGasUnit.CUBIC_METER]: 0.0283168
  },
  [type_1.ConsumptionType.FUEL_ELECTRIC]: {
    [type_1.ElectricUnit.KILOWATT_HOUR]: 1
  },
  [type_1.ConsumptionType.ELECTRICITY]: {
    [type_1.ElectricPowerUnit.KILOWATT_HOUR]: 1,
    [type_1.ElectricPowerUnit.MEGAWATT_HOUR]: 0.001,
    [type_1.ElectricPowerUnit.GIGAWATT_HOUR]: 0.000001
  },
  [type_1.ConsumptionType.HEAT_STEAM_GAS]: {
    [type_1.HeatUnit.MEGAJOULE]: 1055.05585,
    [type_1.HeatUnit.GIGAJOULE]: 1.05505585,
    [type_1.HeatUnit.MMBTU]: 1
  },
  [type_1.ConsumptionType.HEATING]: {
    [type_1.HeatSteamHeatingUnit.BRITISH_THERMAL_UNIT]: 1000000,
    [type_1.HeatSteamHeatingUnit.THERM]: 10,
    [type_1.HeatSteamHeatingUnit.CUBIC_FEET]: 1020,
    [type_1.HeatSteamHeatingUnit.KILOWATT_HOUR]: 293.07,
    [type_1.HeatSteamHeatingUnit.GALLON]: 7.14,
    [type_1.HeatSteamHeatingUnit.LITTER]: 27.4,
    [type_1.HeatSteamHeatingUnit.JOULE]: 1055055850,
    [type_1.HeatSteamHeatingUnit.MEGAJOULE]: 1055.05585,
    [type_1.HeatSteamHeatingUnit.GIGAJOULE]: 1.05506,
    [type_1.HeatUnit.MMBTU]: 1
  },
  [type_1.ConsumptionType.COOLING]: {
    [type_1.HeatSteamCoolingUnit.TON]: 83.33,
    [type_1.HeatSteamCoolingUnit.KILOWATT_HOUR]: 293.07,
    [type_1.HeatSteamCoolingUnit.BRITISH_THERMAL_UNIT]: 1000000,
    [type_1.HeatSteamCoolingUnit.MEGAJOULE]: 1055.05585,
    [type_1.HeatUnit.MMBTU]: 1
  },
  [type_1.ConsumptionType.STEAM]: {
    [type_1.HeatSteamUnit.POUND]: 0.001,
    [type_1.HeatSteamUnit.KG]: 22.68,
    [type_1.HeatSteamUnit.BRITISH_THERMAL_UNIT]: 1000000,
    [type_1.HeatSteamUnit.KILOWATT_HOUR]: 293.07,
    [type_1.HeatSteamUnit.MEGAJOULE]: 1055.05585,
    [type_1.HeatSteamUnit.GIGAJOULE]: 1.05506,
    [type_1.HeatUnit.MMBTU]: 1
  },
  [type_1.ConsumptionType.DISTANCE]: {
    [type_1.DistanceUnit.KILOMETER]: 1.609344,
    [type_1.DistanceUnit.MILE]: 1,
    [type_1.DistanceUnit.NAUTICAL_MILE]: 0.868976
  },
  [type_1.ConsumptionType.CURRENCY]: {
    [type_1.Currency.USD]: 1,
    [type_1.Currency.INR]: 0.013,
    [type_1.Currency.EUR]: 1.18,
    [type_1.Currency.GBP]: 1.39,
    [type_1.Currency.JPY]: 0.0091,
    [type_1.Currency.AUD]: 0.74,
    [type_1.Currency.CNY]: 0.15
  },
  [type_1.ConsumptionType.ENERGY]: {
    [type_1.EnergyUnit.KILOJOULE]: 1,
    [type_1.EnergyUnit.MEGAJOULE]: 0.001,
    [type_1.EnergyUnit.GIGAJOULE]: 0.000001,
    [type_1.EnergyUnit.CALORIE]: 0.239006,
    [type_1.EnergyUnit.KILOCALORIE]: 0.000239006,
    [type_1.EnergyUnit.WATTHOUR]: 0.000277778,
    [type_1.EnergyUnit.KILOWATT_HOUR]: 0.000000277778,
    [type_1.EnergyUnit.MEGAWATT_HOUR]: 0.000000000277778,
    [type_1.EnergyUnit.GIGAWATT_HOUR]: 0.000000000000277778,
    [type_1.EnergyUnit.BRITISH_THERMAL_UNIT]: 0.000947817,
    [type_1.EnergyUnit.THERM]: 9.4804e-9,
    [type_1.EnergyUnit.MMBTU]: 9.4804e-7,
    [type_1.EnergyUnit.TON_HOURS]: 0.000284345,
    [type_1.EnergyUnit.POUND_OF_STEAM]: 0.00044,
    [type_1.EnergyUnit.KG]: 0.000442
  }
};
const energyUnitConversions = {
  [type_1.ConsumptionType.ENERGY]: {
    [type_1.EnergyUnit.KILOJOULE]: 1,
    [type_1.EnergyUnit.MEGAJOULE]: 1000,
    [type_1.EnergyUnit.GIGAJOULE]: 1000000,
    [type_1.EnergyUnit.CALORIE]: 0.004184,
    [type_1.EnergyUnit.KILOCALORIE]: 4.184,
    [type_1.EnergyUnit.WATTHOUR]: 3.6,
    [type_1.EnergyUnit.KILOWATT_HOUR]: 3600,
    [type_1.EnergyUnit.MEGAWATT_HOUR]: 3600000,
    [type_1.EnergyUnit.GIGAWATT_HOUR]: 3600000000,
    [type_1.EnergyUnit.BRITISH_THERMAL_UNIT]: 1.05506,
    [type_1.EnergyUnit.THERM]: 105506,
    [type_1.EnergyUnit.MMBTU]: 1055060,
    [type_1.EnergyUnit.TON_HOURS]: 3516,
    [type_1.EnergyUnit.POUND_OF_STEAM]: 2.257,
    [type_1.EnergyUnit.KG]: 2257
  }
};
exports.KMLTO_MPG = 2.352145;
function convertToBase(value, unitType, fromUnit) {
  const conversionFactor = unitConversions[unitType][fromUnit];
  if (conversionFactor === undefined) {
    throw new Error(`conversion from ${fromUnit} to base unit of ${unitType} is not defined.`);
  }
  return {
    value: value / conversionFactor,
    type: unitType
  };
}
function convertFromBase(value, unitType, toUnit, type) {
  const conversionFactor = unitConversions[type][toUnit];
  if (conversionFactor === undefined) {
    throw new Error(`conversion from base unit of ${type} to ${toUnit} is not defined.`);
  }
  return value * conversionFactor;
}
function convertUnits(value, unitType, fromUnit, toUnit) {
  if (fromUnit === toUnit) {
    return value;
  }
  const {
    value: valueInBase,
    type
  } = convertToBase(value, unitType, fromUnit);
  return convertFromBase(valueInBase, unitType, toUnit, type);
}
function getConversionFactor(unitType, fromUnit) {
  const conversionFactor = unitConversions[unitType][fromUnit];
  if (conversionFactor === undefined) {
    throw new Error(`conversion factor from ${fromUnit} to base unit of ${unitType} is not defined.`);
  }
  return conversionFactor;
}
function convertToKJ(amount, unitType, fromUnit) {
  const factor = unitConversions[unitType][fromUnit];
  if (factor === undefined) {
    throw new Error(`conversion factor for ${fromUnit} not found`);
  }
  return amount * factor;
}
function getEnergyConsumptionValue(unitType, unit) {
  return energyUnitConversions[unitType][unit];
}
function compareEnergyUnitTypes(unit1, unit2) {
  const unit1Category = type_1.EnergyUnitCategoryMap[unit1];
  const unit2Category = type_1.EnergyUnitCategoryMap[unit2];
  if (!unit1Category) {
    throw new Error(`unit '${unit1}' is not defined in EnergyUnitCategoryMap.`);
  }
  if (!unit2Category) {
    throw new Error(`unit '${unit2}' is not defined in EnergyUnitCategoryMap.`);
  }
  if (unit1Category === unit2Category) {
    return false;
  }
  return unit2Category;
}
