"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.BusinessPartnersEnum = void 0;
var BusinessPartnersEnum;
(function (BusinessPartnersEnum) {
  BusinessPartnersEnum["SUPPLIERS"] = "SUPPLIERS";
  BusinessPartnersEnum["AGENTS"] = "AGENTS";
  BusinessPartnersEnum["DISTRIBUTORS"] = "DISTRIBUTORS";
  BusinessPartnersEnum["LOBBYISTS"] = "LOBBYISTS";
  BusinessPartnersEnum["JOINT_VENTURE_PARTNERS"] = "JOINT_VENTURE_PARTNERS";
  BusinessPartnersEnum["CONSORTIA_PARTNERS"] = "CONSORTIA_PARTNERS";
  BusinessPartnersEnum["GOVERNMENTS"] = "GOVERNMENTS";
  BusinessPartnersEnum["CUSTOMERS"] = "CUSTOMERS";
  BusinessPartnersEnum["CLIENTS"] = "CLIENTS";
  BusinessPartnersEnum["OTHERS"] = "OTHERS";
  BusinessPartnersEnum["NA"] = "N/A";
})(BusinessPartnersEnum || (exports.BusinessPartnersEnum = BusinessPartnersEnum = {}));
