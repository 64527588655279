"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ReportOperations = exports.ExportFormat = exports.ReportField = void 0;
var ReportField;
(function (ReportField) {
  ReportField["TEXT"] = "TEXT";
  ReportField["NUMBER"] = "NUMBER";
  ReportField["DESCRIPTION"] = "DESCRIPTION";
  ReportField["TABLE"] = "TABLE";
})(ReportField || (exports.ReportField = ReportField = {}));
var ExportFormat;
(function (ExportFormat) {
  ExportFormat["PDF"] = "PDF";
  ExportFormat["DOCX"] = "DOCX";
  ExportFormat["XBRL"] = "XBRL";
})(ExportFormat || (exports.ExportFormat = ExportFormat = {}));
var ReportOperations;
(function (ReportOperations) {
  ReportOperations["MULTIPLY"] = "MULTIPLY";
  ReportOperations["FETCH"] = "FETCH";
  ReportOperations["COUNT"] = "COUNT";
  ReportOperations["DIVIDE"] = "DIVIDE";
  ReportOperations["PERCENT"] = "PERCENT";
  ReportOperations["SUM"] = "SUM";
  ReportOperations["SUBTRACT"] = "SUBTRACT";
  ReportOperations["AVERAGE"] = "AVERAGE";
  ReportOperations["MAX"] = "MAX";
  ReportOperations["MIN"] = "MIN";
  ReportOperations["ROUND"] = "ROUND";
  ReportOperations["CONCAT"] = "CONCAT";
})(ReportOperations || (exports.ReportOperations = ReportOperations = {}));
