"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.WaterUnit = exports.WaterArea = void 0;
var WaterArea;
(function (WaterArea) {
  WaterArea["ALL"] = "All";
  WaterArea["AREAS_WITH_WATER_STRESS"] = "Areas with Water Stress";
})(WaterArea || (exports.WaterArea = WaterArea = {}));
var WaterUnit;
(function (WaterUnit) {
  WaterUnit["KILOLITERS"] = "Kiloliters";
  WaterUnit["CUBIC_METERS"] = "Cubic meters";
  WaterUnit["MEGALITERS"] = "Megaliters";
  WaterUnit["GALLONS"] = "Gallons";
})(WaterUnit || (exports.WaterUnit = WaterUnit = {}));
