"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.createReportObjectFromTemplate = createReportObjectFromTemplate;
const enum_1 = require("../enum");
function createReportObjectFromTemplate(sections) {
  const result = {};
  sections.forEach(section => {
    section.subsections.forEach(subsection => {
      const subsectionObject = {};
      subsection.fields.forEach(field => {
        if (field.type !== enum_1.ReportField.DESCRIPTION) {
          if (field.type === enum_1.ReportField.TABLE) {
            subsectionObject[field.id] = field.rows.map(row => {
              const rowObject = {};
              row.forEach(cell => {
                rowObject[cell.id] = cell.type !== enum_1.ReportField.DESCRIPTION ? null : cell.description;
              });
              return rowObject;
            });
          } else {
            subsectionObject[field.id] = null;
          }
        }
      });
      result[subsection.subsectionId] = subsectionObject;
    });
  });
  return result;
}
