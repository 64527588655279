"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.StakeholderCategoryEnum = void 0;
var StakeholderCategoryEnum;
(function (StakeholderCategoryEnum) {
  StakeholderCategoryEnum["BOARD_OF_DIRECTORS"] = "BOARD_OF_DIRECTORS";
  StakeholderCategoryEnum["KEY_MANAGERIAL_PERSONNEL"] = "KEY_MANAGERIAL_PERSONNEL";
  StakeholderCategoryEnum["PERMANENT_EMPLOYEES"] = "PERMANENT_EMPLOYEES";
  StakeholderCategoryEnum["OTHER_EMPLOYEES"] = "OTHER_EMPLOYEES";
  StakeholderCategoryEnum["PERMANENT_WORKERS"] = "PERMANENT_WORKERS";
  StakeholderCategoryEnum["OTHER_WORKERS"] = "OTHER_WORKERS";
  StakeholderCategoryEnum["VALUE_CHAIN_PARTNERS"] = "VALUE_CHAIN_PARTNERS";
})(StakeholderCategoryEnum || (exports.StakeholderCategoryEnum = StakeholderCategoryEnum = {}));
