"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Assessor = exports.AssessmentType = exports.AssessmentMethodology = exports.AssessmentArea = exports.PrincipleTopics = exports.PurchasedEleCalculation = exports.EmissionTypeRefrigerant = exports.ActivityTypeMobile = void 0;
var ActivityTypeMobile;
(function (ActivityTypeMobile) {
  ActivityTypeMobile["DISTANCE_ACTIVITY"] = "DISTANCE_ACTIVITY";
  ActivityTypeMobile["FUEL_USE"] = "FUEL_USE";
})(ActivityTypeMobile || (exports.ActivityTypeMobile = ActivityTypeMobile = {}));
var EmissionTypeRefrigerant;
(function (EmissionTypeRefrigerant) {
  EmissionTypeRefrigerant["ASSEMBLY_EMISSIONS"] = "ASSEMBLY_EMISSIONS";
  EmissionTypeRefrigerant["OPERATION_EMISSIONS"] = "OPERATION_EMISSIONS";
  EmissionTypeRefrigerant["DISPOSAL_EMISSIONS"] = "DISPOSAL_EMISSIONS";
})(EmissionTypeRefrigerant || (exports.EmissionTypeRefrigerant = EmissionTypeRefrigerant = {}));
var PurchasedEleCalculation;
(function (PurchasedEleCalculation) {
  PurchasedEleCalculation["LOCATION_BASED"] = "LOCATION_BASED";
  PurchasedEleCalculation["MARKET_BASED"] = "MARKET_BASED";
})(PurchasedEleCalculation || (exports.PurchasedEleCalculation = PurchasedEleCalculation = {}));
var PrincipleTopics;
(function (PrincipleTopics) {
  PrincipleTopics["PRINCIPLE_1_ETHICS_TRANSPARENCY_ACCOUNTABILITY"] = "PRINCIPLE_1_ETHICS_TRANSPARENCY_ACCOUNTABILITY";
  PrincipleTopics["PRINCIPLE_2_PRODUCT_LIFE_CYCLE_SUSTAINABILITY"] = "PRINCIPLE_2_PRODUCT_LIFE_CYCLE_SUSTAINABILITY";
  PrincipleTopics["PRINCIPLE_3_EMPLOYEE_WELL_BEING"] = "PRINCIPLE_3_EMPLOYEE_WELL_BEING";
  PrincipleTopics["PRINCIPLE_4_STAKEHOLDER_ENGAGEMENT"] = "PRINCIPLE_4_STAKEHOLDER_ENGAGEMENT";
  PrincipleTopics["PRINCIPLE_5_HUMAN_RIGHTS"] = "PRINCIPLE_5_HUMAN_RIGHTS";
  PrincipleTopics["PRINCIPLE_6_ENVIRONMENT"] = "PRINCIPLE_6_ENVIRONMENT";
  PrincipleTopics["PRINCIPLE_7_POLICY_ADVOCACY"] = "PRINCIPLE_7_POLICY_ADVOCACY";
  PrincipleTopics["PRINCIPLE_8_INCLUSIVE_GROWTH"] = "PRINCIPLE_8_INCLUSIVE_GROWTH";
  PrincipleTopics["PRINCIPLE_9_CUSTOMER_VALUE"] = "PRINCIPLE_9_CUSTOMER_VALUE";
  PrincipleTopics["OTHER_TOPIC"] = "OTHER_TOPIC";
})(PrincipleTopics || (exports.PrincipleTopics = PrincipleTopics = {}));
var AssessmentArea;
(function (AssessmentArea) {
  AssessmentArea["SEXUAL_HARASSMENT"] = "SEXUAL_HARASSMENT";
  AssessmentArea["DISCRIMINATION_AT_WORKPLACE"] = "DISCRIMINATION_AT_WORKPLACE";
  AssessmentArea["CHILD_LABOR"] = "CHILD_LABOR";
  AssessmentArea["FORCED_LABOR_INVOLUNTARY_LABOR"] = "FORCED_LABOR_INVOLUNTARY_LABOR";
  AssessmentArea["WAGES"] = "WAGES";
  AssessmentArea["OTHER"] = "OTHER";
})(AssessmentArea || (exports.AssessmentArea = AssessmentArea = {}));
var AssessmentMethodology;
(function (AssessmentMethodology) {
  AssessmentMethodology["SELF_ASSESSMENT_QUESTIONNAIRE"] = "SELF_ASSESSMENT_QUESTIONNAIRE";
  AssessmentMethodology["ON_SITE_AUDIT"] = "ON_SITE_AUDIT";
  AssessmentMethodology["DOCUMENT_REVIEW"] = "DOCUMENT_REVIEW";
  AssessmentMethodology["THIRD_PARTY_CERTIFICATION"] = "THIRD_PARTY_CERTIFICATION";
  AssessmentMethodology["MULTIPLE_METHODS"] = "MULTIPLE_METHODS";
  AssessmentMethodology["OTHER"] = "OTHER";
})(AssessmentMethodology || (exports.AssessmentMethodology = AssessmentMethodology = {}));
var AssessmentType;
(function (AssessmentType) {
  AssessmentType["HEALTH_AND_SAFETY_PRACTICES"] = "HEALTH_AND_SAFETY_PRACTICES";
  AssessmentType["WORKING_CONDITIONS"] = "WORKING_CONDITIONS";
})(AssessmentType || (exports.AssessmentType = AssessmentType = {}));
var Assessor;
(function (Assessor) {
  Assessor["INTERNAL_ENTITY"] = "INTERNAL (ENTITY)";
  Assessor["STATUTORY_AUTHORITY"] = "STATUTORY AUTHORITY";
  Assessor["THIRD_PARTY"] = "THIRD PARTY";
  Assessor["MULTIPLE_ASSESSORS"] = "MULTIPLE ASSESSORS";
})(Assessor || (exports.Assessor = Assessor = {}));
