"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.EnergyBasisCalculation = exports.BusinessMetric = exports.EnergyActivityCategories = exports.EnergyDownstream = exports.EnergyUpstream = exports.EnergyActivity = exports.EnergyType = exports.EnergyOperation = exports.MaritalStatus = exports.Religion = exports.SocioeconomicStatus = exports.LGBTQIdentity = exports.VeteranStatus = exports.IndigenousStatus = exports.Nationality = exports.EducationalLevel = exports.Disability = exports.Ethnicity = exports.CategoryOfEmployee = exports.EmployeeCategory = exports.DiversityIndicators = exports.AgeGroups = exports.GovernanceBodyCategories = exports.DisposalOperation = exports.SiteLocation = exports.RecoveryOperation = exports.WasteComposition = exports.StakeholderSegment = exports.StakeholderSegmentBasis = exports.CoverageCategory = exports.Reversibility = exports.MaterialityContactType = exports.ImpactScale = exports.ImpactSeverity = exports.ImpactExposure = exports.ImpactFactor = exports.LocalCommunityImpactType = exports.ImpactCategory = exports.WasteSource = exports.WasteType = exports.WasteMaterial = exports.EmployeeFunction = exports.EmployeeLevel = exports.IllHealthTypes = exports.InjurySeverities = exports.InjuryTypes = exports.Genders = exports.WorkerTypes = exports.AuditTypes = exports.WorkerCategories = void 0;
exports.leasedAsset = exports.leasedBuilding = exports.leasedSizeUnit = exports.leasedMeteringType = exports.leasedMethod = exports.fuelEnergyUtilityType = exports.FuelEnergyMethod = exports.FuelEnergyType = exports.AirEmissionsCalculationApproaches = exports.AirEmissionTypes = exports.ODSEmissionsFactorSources = exports.ODSEmissionsSubstances = exports.ReductionInitiative = exports.EmissionTypes = exports.BaseMetricUnit = exports.BaseMetric = exports.WasteTreatment = exports.WasteGeneratedType = exports.WasteMethod = exports.TransportMode = exports.UpstreamDownstreamFuelCalculationMethod = exports.EmissionFromTransportation = exports.UpstreamDownstreamType = exports.SecondaryImpactCategory = exports.ImpactCategories = exports.SustainableTechnologiesInvestmentTypes = exports.ImpactMagnitude = exports.Likelihoods = exports.PublicPolicyType = exports.PolicyCategory = exports.PolicyMethod = exports.Impacts = exports.TimeFrame = exports.RiskOpportunityTypes = exports.Duration = exports.TypeOfAssistance = exports.TypeOfLegalAction = exports.WorkerCategory = exports.Benefits = exports.Competency = exports.UnderRepresentedGroupsDescription = exports.CommitteeType = exports.TypeOfWorker = exports.ContractualRelationship = exports.ThirdPartyDescription = exports.TypeOfWork = exports.TypeOfEmployee = exports.HeatSteamUtilityType = exports.EnergyTypeReduction = exports.EnergyReductionSource = void 0;
exports.BenefitType = exports.EmploymentStatus = exports.WorkforceType = exports.ProductWasteType = exports.SupplierCateory = exports.ValueTypes = exports.OpennessOfBusinessMetrics = exports.ConcentrationOfPurchases = exports.OpennessOfBusinessParameterTypes = exports.AreasAssessedTypes = exports.AssessorTypes = exports.StakeholderGroups = exports.ElectricityInstruments = exports.ElectricitySource = exports.BlockchainCallStatus = exports.PurchaseGoodsEmissionCategory = exports.CapitalPurchasedGoodsMethod = exports.CommputingTansportMode = exports.CommputingVehicleType = exports.TeleWorkingType = exports.CommutingActivity = exports.ECDistanceBasedMethod = exports.ECFuelBasedCalculationMethods = exports.EmployeeCommutingMethods = exports.VehicleTypes = exports.TravelMethods = exports.BusinessTraveFuelCalculationMethods = exports.BusinessTravelMethods = exports.PartnershipStatuses = exports.RestorationSuccessTypes = exports.ImpactReversibilityTypes = exports.ImpactTypes = exports.ImpactNatureTypes = exports.BiodiversityValueAttributes = exports.OperationTypes = exports.RelationToProtectedArea = exports.SubsurfaceLandStatuses = exports.OwnershipStatuses = exports.ExtinctionRiskLevels = exports.YesNo = exports.Ghg = exports.FuelAndFeedStock = exports.UseSoldMethod = exports.UseSoldType = exports.FranchiseBuildingType = exports.FranchiseType = exports.FranchiseMethod = exports.EndOfLifeType = exports.EndOfLifeMethod = exports.ProcessingSoldMethod = void 0;
exports.ReachEnum = exports.AssociationTypeEnum = exports.StakeholderGroupEnum = exports.statusEnum = exports.complianceTypeEnum = exports.ngrbcPrincipleEnum = exports.STATUS_ENUM = exports.COMPLIANCE_TYPE_ENUM = exports.NGRBC_PRINCIPLE_ENUM = exports.EntityTypeEnum = exports.ReviewMethodEnum = exports.ReviewFrequencyEnum = exports.ReviewTypeEnum = exports.InternalExternalEnum = exports.FrequencyEnum = exports.TargetAudienceEnum = exports.ProgramTypeEnum = exports.TrainingTypeEnum = exports.TrainingCategoryEnum = exports.LeaveType = exports.EmploymentWorkerCategory = exports.ActionTakenTypes = exports.IndividualCategoryTypes = exports.IncidentStatusTypes = exports.CorruptionTypes = exports.FormatTypes = exports.ActivityTypes = exports.WageWorkerCategory = exports.WagePeriod = exports.FundingMethod = exports.FundingStatus = exports.CategoryType = exports.PlanType = exports.TypeOfBenefitPlan = exports.LocationCategoryEnum = exports.RecallTypeEnum = void 0;
var WorkerCategories;
(function (WorkerCategories) {
  WorkerCategories["EMPLOYEE"] = "EMPLOYEE";
  WorkerCategories["CONTROLLED_WORKER"] = "CONTROLLED-WORKER";
})(WorkerCategories || (exports.WorkerCategories = WorkerCategories = {}));
var AuditTypes;
(function (AuditTypes) {
  AuditTypes["INTERNAL"] = "INTERNAL";
  AuditTypes["EXTERNAL"] = "EXTERNAL";
})(AuditTypes || (exports.AuditTypes = AuditTypes = {}));
var WorkerTypes;
(function (WorkerTypes) {
  WorkerTypes["PERMANENT_EMPLOYEES"] = "PERMANENT_EMPLOYEES";
  WorkerTypes["OTHER_EMPLOYEES"] = "OTHER_EMPLOYEES";
  WorkerTypes["PERMANENT_WORKERS"] = "PERMANENT_WORKERS";
  WorkerTypes["OTHER_WORKERS"] = "OTHER_WORKERS";
})(WorkerTypes || (exports.WorkerTypes = WorkerTypes = {}));
var Genders;
(function (Genders) {
  Genders["MALE"] = "MALE";
  Genders["FEMALE"] = "FEMALE";
  Genders["OTHERS"] = "OTHERS";
})(Genders || (exports.Genders = Genders = {}));
var InjuryTypes;
(function (InjuryTypes) {
  InjuryTypes["FATALITY"] = "FATALITY";
  InjuryTypes["HIGH_CONSEQUENCE"] = "HIGH-CONSEQUENCE";
  InjuryTypes["RECORDABLE"] = "RECORDABLE";
})(InjuryTypes || (exports.InjuryTypes = InjuryTypes = {}));
var InjurySeverities;
(function (InjurySeverities) {
  InjurySeverities["MILD"] = "MILD";
  InjurySeverities["MODERATE"] = "MODERATE";
  InjurySeverities["SEVERE"] = "SEVERE";
  InjurySeverities["FATAL"] = "FATAL";
})(InjurySeverities || (exports.InjurySeverities = InjurySeverities = {}));
var IllHealthTypes;
(function (IllHealthTypes) {
  IllHealthTypes["RESPIRATORY_CONDITIONS"] = "RESPIRATORY_CONDITIONS";
  IllHealthTypes["MUSCULOSKELETAL_DISORDERS"] = "MUSCULOSKELETAL_DISORDERS";
  IllHealthTypes["SKIN_DISEASES"] = "SKIN_DISEASES";
  IllHealthTypes["HEARING_LOSS"] = "HEARING_LOSS";
  IllHealthTypes["PSYCHOLOGICAL_DISORDERS"] = "PSYCHOLOGICAL_DISORDERS";
  IllHealthTypes["CARDIOVASCULAR_DISORDERS"] = "CARDIOVASCULAR_DISEASES";
  IllHealthTypes["GASTROINTESTINAL_DISORDERS"] = "GASTROINTESTINAL_DISORDERS";
  IllHealthTypes["VISION_IMPAIRMENT"] = "VISION_IMPAIRMENT";
  IllHealthTypes["OCCUPATIONAL_DISORDERS"] = "OCCUPATIONAL_CANCERS";
  IllHealthTypes["INFECTIOUS_DISORDERS"] = "INFECTIOUS_DISEASES";
})(IllHealthTypes || (exports.IllHealthTypes = IllHealthTypes = {}));
var EmployeeLevel;
(function (EmployeeLevel) {
  EmployeeLevel["EXECUTIVE_LEVEL"] = "EXECUTIVE_LEVEL";
  EmployeeLevel["SENIOR_MANAGEMENT"] = "SENIOR_MANAGEMENT";
  EmployeeLevel["MIDDLE_MANAGEMENT"] = "MIDDLE_MANAGEMENT";
  EmployeeLevel["JUNIOR_MANAGEMENT"] = "JUNIOR_MANAGEMENT";
  EmployeeLevel["PROFESSIONAL_STAFF"] = "PROFESSIONAL_STAFF";
  EmployeeLevel["TECHNICAL_STAFF"] = "TECHNICAL_STAFF";
  EmployeeLevel["ADMINISTRATIVE_STAFF"] = "ADMINISTRATIVE_STAFF";
  EmployeeLevel["OPERATIONAL_STAFF"] = "OPERATIONAL_STAFF";
  EmployeeLevel["ENTRY_LEVEL_STAFF"] = "ENTRY_LEVEL_STAFF";
})(EmployeeLevel || (exports.EmployeeLevel = EmployeeLevel = {}));
var EmployeeFunction;
(function (EmployeeFunction) {
  EmployeeFunction["TECHNICAL"] = "TECHNICAL";
  EmployeeFunction["ADMINISTRATIVE"] = "ADMINISTRATIVE";
  EmployeeFunction["PRODUCTION"] = "PRODUCTION";
  EmployeeFunction["SALES"] = "SALES";
  EmployeeFunction["MARKETING"] = "MARKETING";
  EmployeeFunction["HUMAN_RESOURCES"] = "HUMAN_RESOURCES";
  EmployeeFunction["FINANCE"] = "FINANCE";
  EmployeeFunction["CUSTOMER_SERVICE"] = "CUSTOMER_SERVICE";
  EmployeeFunction["RESEARCH_AND_DEVELOPMENT"] = "RESEARCH_AND_DEVELOPMENT";
  EmployeeFunction["IT_SUPPORT"] = "IT_SUPPORT";
  EmployeeFunction["LEGAL"] = "LEGAL";
  EmployeeFunction["LOGISTICS"] = "LOGISTICS";
})(EmployeeFunction || (exports.EmployeeFunction = EmployeeFunction = {}));
var WasteMaterial;
(function (WasteMaterial) {
  WasteMaterial["FABRIC"] = "Fabric";
  WasteMaterial["USED_OIL"] = "Used Oil";
  WasteMaterial["PACKAGING_WASTE"] = "Packaging Waste";
  WasteMaterial["BATTERIES"] = "Batteries";
  WasteMaterial["OTHER"] = "Other (values to be specified later)";
  WasteMaterial["PLASTIC_WASTE"] = "Plastic Waste";
  WasteMaterial["E_WASTE"] = "E-Waste";
  WasteMaterial["BATTERY_WASTE_LEAD_ACID_NI_CD_DRY_CELL"] = "Battery Waste (Lead Acid + Ni-Cd + Dry Cell)";
  WasteMaterial["CONSTRUCTION_WASTE"] = "Construction Waste";
  WasteMaterial["BIO_MEDICAL_WASTE"] = "Bio-Medical Waste";
  WasteMaterial["RADIOACTIVE_WASTE"] = "Radioactive Waste";
  WasteMaterial["CANTEEN_FOOD_WASTE_COMPOSTING"] = "Canteen Food Waste (Composting)";
  WasteMaterial["PAPER_WASTE_CARTONS"] = "Paper Waste Cartons";
  WasteMaterial["INSULATION_WASTE"] = "Insulation Waste";
  WasteMaterial["SPUN_FILTERS"] = "Spun Filters";
  WasteMaterial["USED_OR_SPENT_OIL"] = "5.1 Used or Spent Oil";
  WasteMaterial["WASTE_OR_RESIDUES_CONTAINING_OIL"] = "5.2 Waste or Residues Containing Oil";
  WasteMaterial["SPENT_CATALYST"] = "18.1 Spent Catalyst";
  WasteMaterial["EMPTY_BARRELS_CONTAINERS_LINERS_CONTAMINATED_WITH_HAZ_CHEMICALS_WASTES"] = "33.1 Empty Barrels / Containers / Liners Contaminated with Haz Chemicals / Wastes";
  WasteMaterial["KMNO4_SLUDGE_PROCESS_RESIDUE"] = "35.1 KMnO4 Sludge / Process Residue";
  WasteMaterial["SPRAY_CANS"] = "33.1 Spray Cans";
  WasteMaterial["USED_CONTAINERS"] = "33.1 Used Containers";
  WasteMaterial["SPENT_PLATINUM_RHODIUM_CATALYST"] = "17.2 Spent Platinum Rhodium Catalyst";
  WasteMaterial["SPENT_USED_DENOX_CATALYST"] = "17.2 Spent/Used DeNOx Catalyst";
  WasteMaterial["SPENT_ION_EXCHANGE_RESINS_DM_RESIN"] = "35.2 Spent Ion Exchange Resins (DM Resin)";
  WasteMaterial["USED_OIL_FILTER_NON_METALLIC"] = "5.2 Used Oil Filter (non-Metallic)";
  WasteMaterial["COAL_ASH"] = "Coal Ash";
  WasteMaterial["ASH_DUE_TO_COAL_LIME_TREATMENT"] = "Ash due to Coal & Lime treatment";
  WasteMaterial["DISCARDED_CONTAINERS_LINERS"] = "33.1 Discarded Containers / Liners";
  WasteMaterial["CHEMICAL_SLUDGE_FROM_WASTE_WATER_TREATMENT"] = "35.3 Chemical Sludge from Waste Water Treatment";
  WasteMaterial["CHEMICAL_CONTAINING_RESIDUE_FROM_DECONTAMINATION"] = "34.1 Chemical-Containing residue arising from decontamination";
  WasteMaterial["IRON_OXIDE_CATALYST"] = "17.2 Iron Oxide Catalyst";
  WasteMaterial["NICKEL_CATALYST_FROM_REFORMER"] = "17.2 Nickel Catalyst from Reformer";
  WasteMaterial["ZINC_OXIDE"] = "17.2 Zinc Oxide";
  WasteMaterial["SPENT_ION_EXCHANGE_RESINS"] = "35.2 Spent Ion Exchange Resins";
  WasteMaterial["SPENT_OIL_DRUMS"] = "Spent Oil Drums";
  WasteMaterial["MIX_SCRAP_METAL"] = "Mix Scrap (Metal)";
  WasteMaterial["CHEMICAL_TREATED_WOOD"] = "Chemical Treated Wood";
  WasteMaterial["PVC_FRILLS"] = "PVC Frills";
  WasteMaterial["MS_SCRAP"] = "MS Scrap";
  WasteMaterial["WOODEN_SCRAP"] = "Wooden Scrap";
  WasteMaterial["RAW_MATERIAL_CONTAMINATED_BAGS_PLASTIC"] = "Raw Material Contaminated Bags (Plastic)";
  WasteMaterial["SCRAP_GI"] = "Scrap GI";
  WasteMaterial["MS_DRUMS_DAMAGED_RUSTED"] = "MS Drums Damaged/Rusted";
  WasteMaterial["ALUMINIUM_BODY_FILTERS"] = "Aluminium Body Filters";
  WasteMaterial["EMPTY_CARBOYS"] = "Empty Carboys";
  WasteMaterial["GI_BODY_FILTERS"] = "GI Body Filters";
  WasteMaterial["EMPTY_TORN_BAGS_SCRAP"] = "Empty Torn Bags Scrap";
  WasteMaterial["ALUMINIUM_SCRAP"] = "Aluminium Scrap";
  WasteMaterial["SS_BODY_FILTERS"] = "SS Body Filters";
  WasteMaterial["RUBBER_SCRAP"] = "Rubber Scrap";
  WasteMaterial["SCRAP_HDPE_PVC_DRUMS"] = "Scrap HDPE/PVC Drums";
  WasteMaterial["SS_SCRAP"] = "SS Scrap";
  WasteMaterial["SCRAP_MIX_CABLE"] = "Scrap Mix Cable";
  WasteMaterial["MS_DRUMS_210_LITR_CAP_CLOSED_MOUTH"] = "MS Drums 210 Litr. Cap. Closed Mouth";
  WasteMaterial["MS_DRUMS_210_LITR_CAP_OPEN_MOUTH"] = "MS Drums 210 Litr. Cap. Open Mouth";
  WasteMaterial["SCRAP_EMPTY_TORRENT_CONTAINERS"] = "Scrap Empty Torrent Containers";
  WasteMaterial["PAPER_SCRAP"] = "Paper Scrap";
  WasteMaterial["STORES_SCRAP_GARBAGE"] = "Stores - Scrap & Garbage";
  WasteMaterial["ADMIN_SCRAP_GARBAGE"] = "Admin - Scrap & Garbage";
  WasteMaterial["BIOLOGICAL_SLUDGE_FROM_WASTE_WATER_TREATMENT"] = "Biological Sludge from Waste Water Treatment";
  WasteMaterial["ABSORB_GEL_FROM_LDAN_BAGGING"] = "34.1 Absorb Gel from LDAN Bagging";
  WasteMaterial["OIL_SOAKED_COTTON_WASTE"] = "5.2 Oil Soaked cotton waste";
  WasteMaterial["SPENT_ION_EXCHANGE_RESIN_CONTAINING_TOXIC_METALS"] = "35.2 Spent Ion Exchange Resin containing Toxic Metals";
  WasteMaterial["METALLIC_WASTE_IRON_STEEL_ALUMINIUM_COPPER"] = "Metallic Waste (Iron, Steel, Aluminium, Copper)";
  WasteMaterial["METALLIC_FILTERS"] = "Metallic Filters";
  WasteMaterial["WOOD_WASTE"] = "Wood Waste";
  WasteMaterial["RUBBER_WASTE"] = "Rubber Waste";
  WasteMaterial["BAGS"] = "Bags";
  WasteMaterial["PAPER"] = "Paper";
  WasteMaterial["DRUMS_CONTAINERS"] = "Drums & Container (that are not reported above)";
  WasteMaterial["USED_SPENT_OIL"] = "5.1 Used/Spent Oil";
  WasteMaterial["USED_OIL_FILTERS"] = "5.2 Used oil filters";
  WasteMaterial["MIXED_GARBAGE"] = "Mixed Garbages (including paper, packing material, thermocoal)";
  WasteMaterial["STP_SLUDGE"] = "STP Sludge";
})(WasteMaterial || (exports.WasteMaterial = WasteMaterial = {}));
var WasteType;
(function (WasteType) {
  WasteType["HAZARDOUS"] = "Hazardous";
  WasteType["NON_HAZARDOUS"] = "Non-Hazardous";
  WasteType["PLASTIC_WASTE"] = "Plastic Waste";
  WasteType["E_WASTE"] = "E-Waste";
  WasteType["BATTERY_WASTE"] = "Battery Waste";
  WasteType["CONSTRUCTION_WASTE"] = "Construction Waste";
  WasteType["BIO_MEDICAL_WASTE"] = "Bio-Medical Waste";
  WasteType["RADIOACTIVE_WASTE"] = "Radioactive Waste";
  WasteType["SCRAP"] = "Scrap";
})(WasteType || (exports.WasteType = WasteType = {}));
var WasteSource;
(function (WasteSource) {
  WasteSource["OWN_ACTIVITIES"] = "Own Activities";
  WasteSource["UPSTREAM_ACTIVITIES"] = "Upstream Activities";
  WasteSource["DOWNSTREAM_ACTIVITIES"] = "Downstream Activities";
})(WasteSource || (exports.WasteSource = WasteSource = {}));
var ImpactCategory;
(function (ImpactCategory) {
  ImpactCategory["ENVIRONMENT"] = "ENVIRONMENT";
  ImpactCategory["SOCIAL"] = "SOCIAL";
  ImpactCategory["ECONOMIC"] = "ECONOMIC";
})(ImpactCategory || (exports.ImpactCategory = ImpactCategory = {}));
var LocalCommunityImpactType;
(function (LocalCommunityImpactType) {
  LocalCommunityImpactType["POSITIVE"] = "POSITIVE";
  LocalCommunityImpactType["NEGATIVE"] = "NEGATIVE";
})(LocalCommunityImpactType || (exports.LocalCommunityImpactType = LocalCommunityImpactType = {}));
var ImpactFactor;
(function (ImpactFactor) {
  ImpactFactor["PHYSICAL_ECONOMIC_ISOLATION"] = "PHYSICAL_ECONOMIC_ISOLATION";
  ImpactFactor["SOCIOECONOMIC_DEVELOPMENT"] = "SOCIOECONOMIC_DEVELOPMENT";
  ImpactFactor["GENDER_EQUALITY"] = "GENDER_EQUALITY";
  ImpactFactor["SOCIOECONOMIC_INFRASTRUCTURE"] = "SOCIOECONOMIC_INFRASTRUCTURE";
  ImpactFactor["HEALTH_AND_EDUCATION_INFRASTRUCTURE"] = "HEALTH_AND_EDUCATION_INFRASTRUCTURE";
  ImpactFactor["PROXIMITY_TO_OPERATIONS"] = "PROXIMITY_TO_OPERATIONS";
  ImpactFactor["LEVEL_OF_SOCIAL_ORGANIZATION"] = "LEVEL_OF_SOCIAL_ORGANIZATION";
  ImpactFactor["STRENGTH_AND_QUALITY_OF_THE_GOVERNANCE"] = "STRENGTH_AND_QUALITY_OF_THE_GOVERNANCE";
  ImpactFactor["OTHER_SPECIFY"] = "OTHER_SPECIFY";
})(ImpactFactor || (exports.ImpactFactor = ImpactFactor = {}));
var ImpactExposure;
(function (ImpactExposure) {
  ImpactExposure["USE_OF_HAZARDOUS_SUBSTANCES"] = "USE_OF_HAZARDOUS_SUBSTANCES";
  ImpactExposure["POLLUTION"] = "POLLUTION";
  ImpactExposure["EMPLOYMENT"] = "EMPLOYMENT";
  ImpactExposure["LAND_CONVERSION_AND_RESETTLEMENT"] = "LAND_CONVERSION_AND_RESETTLEMENT";
  ImpactExposure["NATURAL_RESOURCE_CONVERSION"] = "NATURAL_RESOURCE_CONVERSION";
  ImpactExposure["OTHER_SPECIFY"] = "OTHER_SPECIFY";
})(ImpactExposure || (exports.ImpactExposure = ImpactExposure = {}));
var ImpactSeverity;
(function (ImpactSeverity) {
  ImpactSeverity["HIGH"] = "HIGH";
  ImpactSeverity["MEDIUM"] = "MEDIUM";
  ImpactSeverity["LOW"] = "LOW";
})(ImpactSeverity || (exports.ImpactSeverity = ImpactSeverity = {}));
var ImpactScale;
(function (ImpactScale) {
  ImpactScale["HIGH"] = "HIGH";
  ImpactScale["MEDIUM"] = "MEDIUM";
  ImpactScale["LOW"] = "LOW";
})(ImpactScale || (exports.ImpactScale = ImpactScale = {}));
var MaterialityContactType;
(function (MaterialityContactType) {
  MaterialityContactType["INTERNAL"] = "INTERNAL";
  MaterialityContactType["EXTERNAL"] = "EXTERNAL";
  MaterialityContactType["USER"] = "USER";
  MaterialityContactType["PARTNER"] = "PARTNER";
})(MaterialityContactType || (exports.MaterialityContactType = MaterialityContactType = {}));
var Reversibility;
(function (Reversibility) {
  Reversibility["REVERSIBLE"] = "REVERSIBLE";
  Reversibility["IRREVERSIBLE"] = "IRREVERSIBLE";
})(Reversibility || (exports.Reversibility = Reversibility = {}));
var CoverageCategory;
(function (CoverageCategory) {
  CoverageCategory["LOCAL_COMMUNITY_ENGAGEMENT"] = "LOCAL_COMMUNITY_ENGAGEMENT";
  CoverageCategory["IMPACT_ASSESSMENT"] = "IMPACT_ASSESSMENT";
  CoverageCategory["DEVELOPMENT_PROGRAM"] = "DEVELOPMENT_PROGRAM";
})(CoverageCategory || (exports.CoverageCategory = CoverageCategory = {}));
var StakeholderSegmentBasis;
(function (StakeholderSegmentBasis) {
  StakeholderSegmentBasis["ETHNIC_BACKGROUND"] = "ETHNIC_BACKGROUND";
  StakeholderSegmentBasis["GENDER"] = "GENDER";
  StakeholderSegmentBasis["AGE"] = "AGE";
  StakeholderSegmentBasis["MIGRANT_STATUS"] = "MIGRANT_STATUS";
  StakeholderSegmentBasis["SOCIOECONOMIC_STATUS"] = "SOCIOECONOMIC_STATUS";
  StakeholderSegmentBasis["LITERACY_LEVELS"] = "LITERACY_LEVELS";
  StakeholderSegmentBasis["VULNERABLE_GROUPS"] = "VULNERABLE_GROUPS";
  StakeholderSegmentBasis["OTHER"] = "OTHER";
})(StakeholderSegmentBasis || (exports.StakeholderSegmentBasis = StakeholderSegmentBasis = {}));
var StakeholderSegment;
(function (StakeholderSegment) {
  StakeholderSegment["ASIAN"] = "ASIAN";
  StakeholderSegment["BLACK_OR_AFRICAN_AMERICAN"] = "BLACK_OR_AFRICAN_AMERICAN";
  StakeholderSegment["HISPANIC_OR_LATINO"] = "HISPANIC_OR_LATINO";
  StakeholderSegment["NATIVE_AMERICAN_OR_ALASKA_NATIVE"] = "NATIVE_AMERICAN_OR_ALASKA_NATIVE";
  StakeholderSegment["NATIVE_HAWAIIAN_OR_OTHER_PACIFIC_ISLANDER"] = "NATIVE_HAWAIIAN_OR_OTHER_PACIFIC_ISLANDER";
  StakeholderSegment["WHITE_OR_CAUCASIAN"] = "WHITE_OR_CAUCASIAN";
  StakeholderSegment["MIDDLE_EASTERN_OR_NORTH_AFRICAN"] = "MIDDLE_EASTERN_OR_NORTH_AFRICAN";
  StakeholderSegment["MULTIRACIAL_OR_BIRACIAL"] = "MULTIRACIAL_OR_BIRACIAL";
  StakeholderSegment["SOUTH_ASIAN_INDIAN_PAKISTANI_BANGLADESHI"] = "SOUTH_ASIAN_INDIAN_PAKISTANI_BANGLADESHI";
  StakeholderSegment["EAST_ASIAN_CHINESE_JAPANESE_KOREAN"] = "EAST_ASIAN_CHINESE_JAPANESE_KOREAN";
  StakeholderSegment["SOUTHEAST_ASIAN_VIETNAMESE_FILIPINO_THAI"] = "SOUTHEAST_ASIAN_VIETNAMESE_FILIPINO_THAI";
  StakeholderSegment["CENTRAL_ASIAN_UZBEK_KAZAKH"] = "CENTRAL_ASIAN_UZBEK_KAZAKH";
  StakeholderSegment["AFRO_CARIBBEAN"] = "AFRO_CARIBBEAN";
  StakeholderSegment["INDIGENOUS_OR_ABORIGINAL"] = "INDIGENOUS_OR_ABORIGINAL";
  StakeholderSegment["LATINX"] = "LATINX";
  StakeholderSegment["JEWISH"] = "JEWISH";
  StakeholderSegment["ROMA_OR_ROMANI"] = "ROMA_OR_ROMANI";
  StakeholderSegment["OTHER_PLEASE_SPECIFY"] = "OTHER_PLEASE_SPECIFY";
})(StakeholderSegment || (exports.StakeholderSegment = StakeholderSegment = {}));
var WasteComposition;
(function (WasteComposition) {
  WasteComposition["BIOMASS"] = "Biomass";
  WasteComposition["PLASTICS"] = "Plastics";
  WasteComposition["TEXTILES"] = "Textiles";
  WasteComposition["METALS"] = "Metals";
  WasteComposition["MON_METALLIC_MINERALS"] = "Mon-metallic minerals";
})(WasteComposition || (exports.WasteComposition = WasteComposition = {}));
var RecoveryOperation;
(function (RecoveryOperation) {
  RecoveryOperation["REUSE"] = "Reuse";
  RecoveryOperation["RECYCLE"] = "Recycle";
  RecoveryOperation["OTHER"] = "Other ";
})(RecoveryOperation || (exports.RecoveryOperation = RecoveryOperation = {}));
var SiteLocation;
(function (SiteLocation) {
  SiteLocation["ONSITE"] = "Onsite";
  SiteLocation["OFFSITE"] = "Offsite";
})(SiteLocation || (exports.SiteLocation = SiteLocation = {}));
var DisposalOperation;
(function (DisposalOperation) {
  DisposalOperation["INCINERATION_WITH_ENERGY_RECOVERY"] = "Incineration (with energy recovery)";
  DisposalOperation["INCINERATION_WITHOUT_ENERGY_RECOVERY"] = "Incineration (without energy recovery)";
  DisposalOperation["LAND_FILLING"] = "Land filling";
  DisposalOperation["DUMPING"] = "Dumping";
  DisposalOperation["OPEN_BURNING"] = "Open Burning";
  DisposalOperation["DEEP_WELL_INJECTION"] = "Deep Well Injection";
  DisposalOperation["OTHER"] = "Other ";
})(DisposalOperation || (exports.DisposalOperation = DisposalOperation = {}));
var GovernanceBodyCategories;
(function (GovernanceBodyCategories) {
  GovernanceBodyCategories["BOARD_OF_DIRECTORS"] = "BOARD_OF_DIRECTORS";
  GovernanceBodyCategories["EXECUTIVE_MANAGEMENT_TEAM"] = "EXECUTIVE_MANAGEMENT_TEAM";
  GovernanceBodyCategories["KEY_MANAGEMENT_PERSONNEL"] = "KEY_MANAGEMENT_PERSONNEL";
  GovernanceBodyCategories["ADVISORY_COMMITTEES"] = "ADVISORY_COMMITTEES";
  GovernanceBodyCategories["AUDIT_COMMITTEE"] = "AUDIT_COMMITTEE";
  GovernanceBodyCategories["COMPENSATION_COMMITTEE"] = "COMPENSATION_COMMITTEE";
  GovernanceBodyCategories["NOMINATIONS_COMMITTEE"] = "NOMINATIONS_COMMITTEE";
  GovernanceBodyCategories["CORPORATE_GOVERNANCE_COMMITTEE"] = "CORPORATE_GOVERNANCE_COMMITTEE";
  GovernanceBodyCategories["ETHICS_OR_COMPLIANCE_COMMITTEE"] = "ETHICS_OR_COMPLIANCE_COMMITTEE";
  GovernanceBodyCategories["RISK_MANAGEMENT_COMMITTEE"] = "RISK_MANAGEMENT_COMMITTEE";
  GovernanceBodyCategories["SUSTAINABILITY_CSR_COMMITTEE"] = "SUSTAINABILITY_CSR_COMMITTEE";
  GovernanceBodyCategories["SPECIALIZED_COMMITTEES"] = "SPECIALIZED_COMMITTEES";
})(GovernanceBodyCategories || (exports.GovernanceBodyCategories = GovernanceBodyCategories = {}));
var AgeGroups;
(function (AgeGroups) {
  AgeGroups["UNDER"] = "UNDER";
  AgeGroups["MID"] = "MID";
  AgeGroups["OVER"] = "OVER";
})(AgeGroups || (exports.AgeGroups = AgeGroups = {}));
var DiversityIndicators;
(function (DiversityIndicators) {
  DiversityIndicators["ETHNICITY"] = "ETHNICITY";
  DiversityIndicators["DISABILITY"] = "DISABILITY";
  DiversityIndicators["EDUCATIONAL_LEVEL"] = "EDUCATIONAL_LEVEL";
  DiversityIndicators["NATIONALITY"] = "NATIONALITY";
  DiversityIndicators["INDIGENOUS_STATUS"] = "INDIGENOUS_STATUS";
  DiversityIndicators["VETERAN_STATUS"] = "VETERAN_STATUS";
  DiversityIndicators["LGBTQ_IDENTITY"] = "LGBTQ_IDENTITY";
  DiversityIndicators["SOCIOECONOMIC_STATUS"] = "SOCIOECONOMIC_STATUS";
  DiversityIndicators["RELIGION"] = "RELIGION";
  DiversityIndicators["MARITAL_STATUS"] = "MARITAL_STATUS";
})(DiversityIndicators || (exports.DiversityIndicators = DiversityIndicators = {}));
var EmployeeCategory;
(function (EmployeeCategory) {
  EmployeeCategory["PERMANENT_EMPLOYEES"] = "PERMANENT_EMPLOYEES";
  EmployeeCategory["OTHER_THAN_PERMANENT_EMPLOYEES"] = "OTHER_THAN_PERMANENT_EMPLOYEES";
  EmployeeCategory["PERMANENT_WORKERS"] = "PERMANENT_WORKERS";
  EmployeeCategory["OTHER_THAN_PERMANENT_WORKERS"] = "OTHER_THAN_PERMANENT_WORKERS";
})(EmployeeCategory || (exports.EmployeeCategory = EmployeeCategory = {}));
var CategoryOfEmployee;
(function (CategoryOfEmployee) {
  CategoryOfEmployee["BOARD_OF_DIRECTORS"] = "BOARD_OF_DIRECTORS";
  CategoryOfEmployee["KEY_MANAGERIAL_PERSONNEL"] = "KEY_MANAGERIAL_PERSONNEL";
  CategoryOfEmployee["PERMANENT_EMPLOYEES"] = "PERMANENT_EMPLOYEES";
  CategoryOfEmployee["OTHER_EMPLOYEES"] = "OTHER_EMPLOYEES";
  CategoryOfEmployee["PERMANENT_WORKERS"] = "PERMANENT_WORKERS";
  CategoryOfEmployee["OTHER_WORKERS"] = "OTHER_WORKERS";
})(CategoryOfEmployee || (exports.CategoryOfEmployee = CategoryOfEmployee = {}));
var Ethnicity;
(function (Ethnicity) {
  Ethnicity["HISPANIC_OR_LATINO"] = "HISPANIC_OR_LATINO";
  Ethnicity["BLACK_OR_AFRICAN_AMERICAN"] = "BLACK_OR_AFRICAN_AMERICAN";
  Ethnicity["WHITE"] = "WHITE";
  Ethnicity["ASIAN"] = "ASIAN";
  Ethnicity["NATIVE_AMERICAN_OR_ALASKA_NATIVE"] = "NATIVE_AMERICAN_OR_ALASKA_NATIVE";
  Ethnicity["NATIVE_HAWAIIAN_OR_OTHER_PACIFIC_ISLANDER"] = "NATIVE_HAWAIIAN_OR_OTHER_PACIFIC_ISLANDER";
  Ethnicity["TWO_OR_MORE_RACES"] = "TWO_OR_MORE_RACES";
  Ethnicity["OTHER"] = "OTHER";
})(Ethnicity || (exports.Ethnicity = Ethnicity = {}));
var Disability;
(function (Disability) {
  Disability["NO_DISABILITY"] = "NO_DISABILITY";
  Disability["VISUAL_IMPAIRMENT"] = "VISUAL_IMPAIRMENT";
  Disability["HEARING_IMPAIRMENT"] = "HEARING_IMPAIRMENT";
  Disability["PHYSICAL_DISABILITY"] = "PHYSICAL_DISABILITY";
  Disability["COGNITIVE_DISABILITY"] = "COGNITIVE_DISABILITY";
  Disability["MENTAL_HEALTH_CONDITION"] = "MENTAL_HEALTH_CONDITION";
  Disability["NEURODIVERSITY"] = "NEURODIVERSITY";
  Disability["SPEECH_IMPAIRMENT"] = "SPEECH_IMPAIRMENT";
  Disability["INVISIBLE_DISABILITY"] = "INVISIBLE_DISABILITY";
})(Disability || (exports.Disability = Disability = {}));
var EducationalLevel;
(function (EducationalLevel) {
  EducationalLevel["NO_FORMAL_EDUCATION"] = "NO_FORMAL_EDUCATION";
  EducationalLevel["PRIMARY_EDUCATION"] = "PRIMARY_EDUCATION";
  EducationalLevel["SECONDARY_EDUCATION"] = "SECONDARY_EDUCATION";
  EducationalLevel["VOCATIONAL_TRAINING"] = "VOCATIONAL_TRAINING";
  EducationalLevel["ASSOCIATE_DEGREE"] = "ASSOCIATE_DEGREE";
  EducationalLevel["BACHELORS_DEGREE"] = "BACHELORS_DEGREE";
  EducationalLevel["MASTERS_DEGREE"] = "MASTERS_DEGREE";
  EducationalLevel["DOCTORAL_DEGREE"] = "DOCTORAL_DEGREE";
  EducationalLevel["PROFESSIONAL_DEGREE"] = "PROFESSIONAL_DEGREE";
})(EducationalLevel || (exports.EducationalLevel = EducationalLevel = {}));
var Nationality;
(function (Nationality) {
  Nationality["AMERICAN"] = "AMERICAN";
  Nationality["CANADIAN"] = "CANADIAN";
  Nationality["BRITISH"] = "BRITISH";
  Nationality["FRENCH"] = "FRENCH";
  Nationality["GERMAN"] = "GERMAN";
  Nationality["INDIAN"] = "INDIAN";
  Nationality["CHINESE"] = "CHINESE";
  Nationality["BRAZILIAN"] = "BRAZILIAN";
  Nationality["SOUTH_AFRICAN"] = "SOUTH_AFRICAN";
  Nationality["JAPANESE"] = "JAPANESE";
})(Nationality || (exports.Nationality = Nationality = {}));
var IndigenousStatus;
(function (IndigenousStatus) {
  IndigenousStatus["INDIGENOUS"] = "INDIGENOUS";
  IndigenousStatus["NON_INDIGENOUS"] = "NON_INDIGENOUS";
  IndigenousStatus["PREFER_NOT_TO_SAY"] = "PREFER_NOT_TO_SAY";
})(IndigenousStatus || (exports.IndigenousStatus = IndigenousStatus = {}));
var VeteranStatus;
(function (VeteranStatus) {
  VeteranStatus["VETERAN"] = "VETERAN";
  VeteranStatus["NON_VETERAN"] = "NON_VETERAN";
  VeteranStatus["ACTIVE_DUTY"] = "ACTIVE_DUTY";
  VeteranStatus["RESERVE"] = "RESERVE";
  VeteranStatus["PREFER_NOT_TO_SAY"] = "PREFER_NOT_TO_SAY";
})(VeteranStatus || (exports.VeteranStatus = VeteranStatus = {}));
var LGBTQIdentity;
(function (LGBTQIdentity) {
  LGBTQIdentity["IDENTIFIES_AS_LGBTQ_PLUS"] = "IDENTIFIES_AS_LGBTQ_PLUS";
  LGBTQIdentity["DOES_NOT_IDENTIFY_AS_LGBTQ_PLUS"] = "DOES_NOT_IDENTIFY_AS_LGBTQ_PLUS";
  LGBTQIdentity["PREFER_NOT_TO_SAY"] = "PREFER_NOT_TO_SAY";
})(LGBTQIdentity || (exports.LGBTQIdentity = LGBTQIdentity = {}));
var SocioeconomicStatus;
(function (SocioeconomicStatus) {
  SocioeconomicStatus["LOW_SES"] = "LOW_SES";
  SocioeconomicStatus["MIDDLE_SES"] = "MIDDLE_SES";
  SocioeconomicStatus["HIGH_SES"] = "HIGH_SES";
  SocioeconomicStatus["PREFER_NOT_TO_SAY"] = "PREFER_NOT_TO_SAY";
})(SocioeconomicStatus || (exports.SocioeconomicStatus = SocioeconomicStatus = {}));
var Religion;
(function (Religion) {
  Religion["CHRISTIANITY"] = "CHRISTIANITY";
  Religion["ISLAM"] = "ISLAM";
  Religion["HINDUISM"] = "HINDUISM";
  Religion["BUDDHISM"] = "BUDDHISM";
  Religion["JUDAISM"] = "JUDAISM";
  Religion["SIKHISM"] = "SIKHISM";
  Religion["ATHEISM"] = "ATHEISM";
  Religion["AGNOSTICISM"] = "AGNOSTICISM";
  Religion["OTHER"] = "OTHER";
  Religion["PREFER_NOT_TO_SAY"] = "PREFER_NOT_TO_SAY";
})(Religion || (exports.Religion = Religion = {}));
var MaritalStatus;
(function (MaritalStatus) {
  MaritalStatus["SINGLE"] = "SINGLE";
  MaritalStatus["MARRIED"] = "MARRIED";
  MaritalStatus["DIVORCED"] = "DIVORCED";
  MaritalStatus["WIDOWED"] = "WIDOWED";
  MaritalStatus["SEPARATED"] = "SEPARATED";
  MaritalStatus["IN_A_PARTNERSHIP"] = "IN_A_PARTNERSHIP";
  MaritalStatus["PREFER_NOT_TO_SAY"] = "PREFER_NOT_TO_SAY";
})(MaritalStatus || (exports.MaritalStatus = MaritalStatus = {}));
var EnergyOperation;
(function (EnergyOperation) {
  EnergyOperation["CONSUMED"] = "CONSUMED";
  EnergyOperation["SOLD"] = "SOLD";
})(EnergyOperation || (exports.EnergyOperation = EnergyOperation = {}));
var EnergyType;
(function (EnergyType) {
  EnergyType["ELECTRICITY"] = "ELECTRICITY";
  EnergyType["HEATING"] = "HEATING";
  EnergyType["COOLING"] = "COOLING";
  EnergyType["STEAM"] = "STEAM";
})(EnergyType || (exports.EnergyType = EnergyType = {}));
var EnergyActivity;
(function (EnergyActivity) {
  EnergyActivity["UPSTREAM"] = "UPSTREAM";
  EnergyActivity["DOWNSTREAM"] = "DOWNSTREAM";
})(EnergyActivity || (exports.EnergyActivity = EnergyActivity = {}));
var EnergyUpstream;
(function (EnergyUpstream) {
  EnergyUpstream["PURCHASED_GOODS_AND_SERVICES"] = "PURCHASED_GOODS_AND_SERVICES";
  EnergyUpstream["CAPITAL_GOODS"] = "CAPITAL_GOODS";
  EnergyUpstream["FUEL_AND_ENERGY_RELATED_ACTIVITIES"] = "FUEL_AND_ENERGY_RELATED_ACTIVITIES";
  EnergyUpstream["UPSTREAM_TRANSPORTATION_AND_DISTRIBUTION"] = "UPSTREAM_TRANSPORTATION_AND_DISTRIBUTION";
  EnergyUpstream["WASTE_GENERATED_IN_OPERATIONS"] = "WASTE_GENERATED_IN_OPERATIONS";
  EnergyUpstream["BUSINESS_TRAVEL"] = "BUSINESS_TRAVEL";
  EnergyUpstream["EMPLOYEE_COMMUTING"] = "EMPLOYEE_COMMUTING";
  EnergyUpstream["UPSTREAM_LEASED_ASSETS"] = "UPSTREAM_LEASED_ASSETS";
})(EnergyUpstream || (exports.EnergyUpstream = EnergyUpstream = {}));
var EnergyDownstream;
(function (EnergyDownstream) {
  EnergyDownstream["DOWNSTREAM_TRANSPORTATION_AND_DISTRIBUTION"] = "DOWNSTREAM_TRANSPORTATION_AND_DISTRIBUTION";
  EnergyDownstream["PROCESSING_OF_SOLD_PRODUCTS"] = "PROCESSING_OF_SOLD_PRODUCTS";
  EnergyDownstream["USE_OF_SOLD_PRODUCTS"] = "USE_OF_SOLD_PRODUCTS";
  EnergyDownstream["END_OF_LIFE_TREATMENT_OF_SOLD_PRODUCTS"] = "END_OF_LIFE_TREATMENT_OF_SOLD_PRODUCTS";
  EnergyDownstream["DOWNSTREAM_LEASED_ASSETS"] = "DOWNSTREAM_LEASED_ASSETS";
  EnergyDownstream["FRANCHISES"] = "FRANCHISES";
  EnergyDownstream["INVESTMENTS"] = "INVESTMENTS";
})(EnergyDownstream || (exports.EnergyDownstream = EnergyDownstream = {}));
exports.EnergyActivityCategories = {
  ...EnergyUpstream,
  ...EnergyDownstream
};
var BusinessMetric;
(function (BusinessMetric) {
  BusinessMetric["PRODUCTION_BASED_METRICS"] = "PRODUCTION_BASED_METRICS";
  BusinessMetric["SIZE_AREA_BASED_METRICS"] = "SIZE_AREA_BASED_METRICS";
  BusinessMetric["PERSONNEL_BASED_METRICS"] = "PERSONNEL_BASED_METRICS";
  BusinessMetric["FINANCIAL_METRICS"] = "FINANCIAL_METRICS";
  BusinessMetric["OPERATIONAL_METRICS"] = "OPERATIONAL_METRICS";
})(BusinessMetric || (exports.BusinessMetric = BusinessMetric = {}));
var EnergyBasisCalculation;
(function (EnergyBasisCalculation) {
  EnergyBasisCalculation["BASE_YEAR"] = "BASE_YEAR";
  EnergyBasisCalculation["BASELINE"] = "BASELINE";
})(EnergyBasisCalculation || (exports.EnergyBasisCalculation = EnergyBasisCalculation = {}));
var EnergyReductionSource;
(function (EnergyReductionSource) {
  EnergyReductionSource["ESTIMATED"] = "ESTIMATED";
  EnergyReductionSource["MODELED"] = "MODELED";
  EnergyReductionSource["DIRECT_MEASUREMENT"] = "DIRECT_MEASUREMENT";
})(EnergyReductionSource || (exports.EnergyReductionSource = EnergyReductionSource = {}));
exports.EnergyTypeReduction = {
  ALL: 'ALL',
  ...EnergyType
};
exports.HeatSteamUtilityType = {
  HEATING: EnergyType.HEATING,
  COOLING: EnergyType.COOLING,
  STEAM: EnergyType.STEAM
};
var TypeOfEmployee;
(function (TypeOfEmployee) {
  TypeOfEmployee["PERMANENT_EMPLOYEES"] = "PERMANENT_EMPLOYEES";
  TypeOfEmployee["TEMPORARY_EMPLOYEES"] = "TEMPORARY_EMPLOYEES";
  TypeOfEmployee["NON_GUARANTEED_HOURS_EMPLOYEES"] = "NON_GUARANTEED_HOURS_EMPLOYEES";
  TypeOfEmployee["FULL_TIME"] = "FULL_TIME";
  TypeOfEmployee["PART_TIME"] = "PART_TIME";
})(TypeOfEmployee || (exports.TypeOfEmployee = TypeOfEmployee = {}));
var TypeOfWork;
(function (TypeOfWork) {
  TypeOfWork["ON_SITE_LABOR"] = "ON_SITE_LABOR";
  TypeOfWork["MANUFACTURING_SUPPORT"] = "MANUFACTURING_SUPPORT";
  TypeOfWork["OPERATIONAL_SUPPORT"] = "OPERATIONAL_SUPPORT";
  TypeOfWork["SUPPLY_CHAIN_SERVICES"] = "SUPPLY_CHAIN_SERVICES";
  TypeOfWork["CLIENT_SITE_WORK"] = "CLIENT_SITE_WORK";
  TypeOfWork["MAINTENANCE_SERVICES"] = "MAINTENANCE_SERVICES";
  TypeOfWork["PRODUCT_DEVELOPMENT"] = "PRODUCT_DEVELOPMENT";
  TypeOfWork["CONSULTING_SERVICES"] = "CONSULTING_SERVICES";
  TypeOfWork["PROJECT_BASED_WORK"] = "PROJECT_BASED_WORK";
  TypeOfWork["REMOTE_SUPPORT_SERVICES"] = "REMOTE_SUPPORT_SERVICES";
  TypeOfWork["OTHER"] = "OTHER";
})(TypeOfWork || (exports.TypeOfWork = TypeOfWork = {}));
var ThirdPartyDescription;
(function (ThirdPartyDescription) {
  ThirdPartyDescription["CONTRACTOR"] = "CONTRACTOR";
  ThirdPartyDescription["EMPLOYMENT_AGENCY"] = "EMPLOYMENT_AGENCY";
  ThirdPartyDescription["VENDOR"] = "VENDOR";
  ThirdPartyDescription["FREELANCER"] = "FREELANCER";
  ThirdPartyDescription["CONSULTANT"] = "CONSULTANT";
  ThirdPartyDescription["LABOR_HIRE"] = "LABOR_HIRE";
  ThirdPartyDescription["NA"] = "NA";
})(ThirdPartyDescription || (exports.ThirdPartyDescription = ThirdPartyDescription = {}));
var ContractualRelationship;
(function (ContractualRelationship) {
  ContractualRelationship["INDIRECT"] = "INDIRECT";
  ContractualRelationship["DIRECT"] = "DIRECT";
})(ContractualRelationship || (exports.ContractualRelationship = ContractualRelationship = {}));
var TypeOfWorker;
(function (TypeOfWorker) {
  TypeOfWorker["AGENCY_WORKER"] = "AGENCY_WORKER";
  TypeOfWorker["APPRENTICE"] = "APPRENTICE";
  TypeOfWorker["CONTRACTOR"] = "CONTRACTOR";
  TypeOfWorker["INTERN"] = "INTERN";
  TypeOfWorker["SELF_EMPLOYED"] = "SELF_EMPLOYED";
})(TypeOfWorker || (exports.TypeOfWorker = TypeOfWorker = {}));
exports.CommitteeType = {
  AUDIT_COMMITTEE: GovernanceBodyCategories.AUDIT_COMMITTEE,
  RISK_MANAGEMENT_COMMITTEE: GovernanceBodyCategories.RISK_MANAGEMENT_COMMITTEE,
  COMPENSATION_COMMITTEE: GovernanceBodyCategories.COMPENSATION_COMMITTEE,
  NOMINATIONS_AND_GOVERNANCE_COMMITTEE: 'NOMINATIONS_AND_GOVERNANCE_COMMITTEE',
  ETHICS_AND_COMPLIANCE_COMMITTEE: 'ETHICS_AND_COMPLIANCE_COMMITTEE',
  SUSTAINABILITY_AND_CORPORATE_RESPONSIBILITY_COMMITTEE: 'SUSTAINABILITY_AND_CORPORATE_RESPONSIBILITY_COMMITTEE',
  FINANCE_COMMITTEE: 'FINANCE_COMMITTEE',
  RESEARCH_AND_DEVELOPMENT_COMMITTEE: 'RESEARCH_AND_DEVELOPMENT_COMMITTEE',
  EXECUTIVE_COMMITTEE: 'EXECUTIVE_COMMITTEE',
  INVESTMENT_COMMITTEE: 'INVESTMENT_COMMITTEE',
  TECHNOLOGY_COMMITTEE: 'TECHNOLOGY_COMMITTEE',
  HUMAN_RESOURCES_COMMITTEE: 'HUMAN_RESOURCES_COMMITTEE',
  PUBLIC_POLICY_AND_GOVERNMENT_AFFAIRS_COMMITTEE: 'PUBLIC_POLICY_AND_GOVERNMENT_AFFAIRS_COMMITTEE',
  HEALTH_SAFETY_AND_ENVIRONMENT_COMMITTEE: 'HEALTH_SAFETY_AND_ENVIRONMENT_COMMITTEE',
  STRATEGIC_PLANNING_COMMITTEE: 'STRATEGIC_PLANNING_COMMITTEE',
  INNOVATION_COMMITTEE: 'INNOVATION_COMMITTEE',
  OTHER: 'OTHER'
};
var UnderRepresentedGroupsDescription;
(function (UnderRepresentedGroupsDescription) {
  UnderRepresentedGroupsDescription["WOMEN"] = "WOMEN";
  UnderRepresentedGroupsDescription["ETHNIC_MINORITIES"] = "ETHNIC_MINORITIES";
  UnderRepresentedGroupsDescription["INDIGENOUS_PEOPLES"] = "INDIGENOUS_PEOPLES";
  UnderRepresentedGroupsDescription["LGBTQ"] = "LGBTQ";
  UnderRepresentedGroupsDescription["PERSONS_WITH_DISABILITIES"] = "PERSONS_WITH_DISABILITIES";
  UnderRepresentedGroupsDescription["VETERANS"] = "VETERANS";
  UnderRepresentedGroupsDescription["YOUNG_PERSONS"] = "YOUNG_PERSONS";
  UnderRepresentedGroupsDescription["SENIOR_CITIZENS"] = "SENIOR_CITIZENS";
  UnderRepresentedGroupsDescription["SOCIOECONOMICALLY_DISADVANTAGED_INDIVIDUALS"] = "SOCIOECONOMICALLY_DISADVANTAGED_INDIVIDUALS";
  UnderRepresentedGroupsDescription["PERSONS_FROM_RURAL_AREAS"] = "PERSONS_FROM_RURAL_AREAS";
  UnderRepresentedGroupsDescription["OTHERS"] = "OTHERS";
})(UnderRepresentedGroupsDescription || (exports.UnderRepresentedGroupsDescription = UnderRepresentedGroupsDescription = {}));
var Competency;
(function (Competency) {
  Competency["STRATEGIC_PLANNING"] = "STRATEGIC_PLANNING";
  Competency["FINANCIAL_OVERSIGHT"] = "FINANCIAL_OVERSIGHT";
  Competency["RISK_MANAGEMENT"] = "RISK_MANAGEMENT";
  Competency["ENVIRONMENTAL_SUSTAINABILITY"] = "ENVIRONMENTAL_SUSTAINABILITY";
  Competency["CORPORATE_GOVERNANCE"] = "CORPORATE_GOVERNANCE";
  Competency["LEGAL_COMPLIANCE"] = "LEGAL_COMPLIANCE";
  Competency["HUMAN_RESOURCES"] = "HUMAN_RESOURCES";
  Competency["TECHNOLOGY_AND_INNOVATION"] = "TECHNOLOGY_AND_INNOVATION";
  Competency["MARKETING_AND_COMMUNICATIONS"] = "MARKETING_AND_COMMUNICATIONS";
  Competency["INTERNATIONAL_BUSINESS"] = "INTERNATIONAL_BUSINESS";
  Competency["PUBLIC_POLICY"] = "PUBLIC_POLICY";
  Competency["COMMUNITY_ENGAGEMENT"] = "COMMUNITY_ENGAGEMENT";
  Competency["SUPPLY_CHAIN_MANAGEMENT"] = "SUPPLY_CHAIN_MANAGEMENT";
  Competency["HEALTH_AND_SAFETY"] = "HEALTH_AND_SAFETY";
  Competency["CRISIS_MANAGEMENT"] = "CRISIS_MANAGEMENT";
  Competency["RESEARCH_AND_DEVELOPMENT"] = "RESEARCH_AND_DEVELOPMENT";
})(Competency || (exports.Competency = Competency = {}));
var Benefits;
(function (Benefits) {
  Benefits["HEALTH_INSURANCE"] = "HEALTH_INSURANCE";
  Benefits["ACCIDENT_INSURANCE"] = "ACCIDENT_INSURANCE";
  Benefits["MATERNITY_BENEFITS"] = "MATERNITY_BENEFITS";
  Benefits["PATERNITY_BENEFITS"] = "PATERNITY_BENEFITS";
  Benefits["DAY_CARE_FACILITIES"] = "DAY_CARE_FACILITIES";
  Benefits["LIFE_INSURANCE"] = "LIFE_INSURANCE";
  Benefits["DISABILITY_COVERAGE"] = "DISABILITY_COVERAGE";
  Benefits["RETIREMENT_PROVISION"] = "RETIREMENT_PROVISION";
  Benefits["STOCK_OWNERSHIP"] = "STOCK_OWNERSHIP";
  Benefits["OTHER"] = "OTHER";
})(Benefits || (exports.Benefits = Benefits = {}));
var WorkerCategory;
(function (WorkerCategory) {
  WorkerCategory["EMPLOYEES"] = "EMPLOYEES";
  WorkerCategory["OTHER_WORKERS"] = "OTHER_WORKERS";
})(WorkerCategory || (exports.WorkerCategory = WorkerCategory = {}));
var TypeOfLegalAction;
(function (TypeOfLegalAction) {
  TypeOfLegalAction["ANTI_COMPETITIVE_BEHAVIOR"] = "ANTI_COMPETITIVE_BEHAVIOR";
  TypeOfLegalAction["VIOLATIONS_OF_ANTI_TRUST"] = "VIOLATIONS_OF_ANTI_TRUST";
  TypeOfLegalAction["MONOPOLY_LEGISLATION"] = "MONOPOLY_LEGISLATION";
})(TypeOfLegalAction || (exports.TypeOfLegalAction = TypeOfLegalAction = {}));
var TypeOfAssistance;
(function (TypeOfAssistance) {
  TypeOfAssistance["TAX_RELIEF"] = "TAX_RELIEF";
  TypeOfAssistance["TAX_CREDIT"] = "TAX_CREDIT";
  TypeOfAssistance["SUBSIDY"] = "SUBSIDY";
  TypeOfAssistance["INVESTMENT_GRANT"] = "INVESTMENT_GRANT";
  TypeOfAssistance["R_AND_D_GRANT"] = "R_AND_D_GRANT";
  TypeOfAssistance["AWARD"] = "AWARD";
  TypeOfAssistance["ROYALTY_HOLIDAY"] = "ROYALTY_HOLIDAY";
  TypeOfAssistance["ECA_ASSISTANCE"] = "ECA_ASSISTANCE";
  TypeOfAssistance["FINANCIAL_INCENTIVE"] = "FINANCIAL_INCENTIVE";
  TypeOfAssistance["OTHER_FINANCIAL_BENEFITS"] = "OTHER_FINANCIAL_BENEFITS";
})(TypeOfAssistance || (exports.TypeOfAssistance = TypeOfAssistance = {}));
var Duration;
(function (Duration) {
  Duration["ONE_TIME"] = "ONE_TIME";
  Duration["ANNUAL"] = "ANNUAL";
  Duration["MULTI_YEAR"] = "MULTI_YEAR";
})(Duration || (exports.Duration = Duration = {}));
var RiskOpportunityTypes;
(function (RiskOpportunityTypes) {
  RiskOpportunityTypes["PHYSICAL"] = "PHYSICAL";
  RiskOpportunityTypes["REGULATORY"] = "REGULATORY";
  RiskOpportunityTypes["MARKET"] = "MARKET";
  RiskOpportunityTypes["REPUTATIONAL"] = "REPUTATIONAL";
})(RiskOpportunityTypes || (exports.RiskOpportunityTypes = RiskOpportunityTypes = {}));
var TimeFrame;
(function (TimeFrame) {
  TimeFrame["SHORT_TERM"] = "SHORT_TERM";
  TimeFrame["MEDIUM_TERM"] = "MEDIUM_TERM";
  TimeFrame["LONG_TERM"] = "LONG_TERM";
})(TimeFrame || (exports.TimeFrame = TimeFrame = {}));
var Impacts;
(function (Impacts) {
  Impacts["INDIRECT"] = "INDIRECT";
  Impacts["DIRECT"] = "DIRECT";
})(Impacts || (exports.Impacts = Impacts = {}));
var PolicyMethod;
(function (PolicyMethod) {
  PolicyMethod["DIRECT"] = "DIRECT";
  PolicyMethod["INDIRECT"] = "INDIRECT";
})(PolicyMethod || (exports.PolicyMethod = PolicyMethod = {}));
var PolicyCategory;
(function (PolicyCategory) {
  PolicyCategory["FINANCIAL"] = "FINANCIAL";
  PolicyCategory["IN_KIND"] = "IN_KIND";
})(PolicyCategory || (exports.PolicyCategory = PolicyCategory = {}));
var PublicPolicyType;
(function (PublicPolicyType) {
  PublicPolicyType["DONATIONS"] = "DONATIONS";
  PublicPolicyType["LOANS"] = "LOANS";
  PublicPolicyType["SPONSORSHIP"] = "SPONSORSHIP";
  PublicPolicyType["RETAINERS"] = "RETAINERS";
  PublicPolicyType["PURCHASE_OF_EVENT_TICKETS"] = "PURCHASE_OF_EVENT_TICKETS";
  PublicPolicyType["ADVERTISING"] = "ADVERTISING";
  PublicPolicyType["USE_OF_FACILITIES"] = "USE_OF_FACILITIES";
  PublicPolicyType["DESIGN_AND_PRINTING"] = "DESIGN_AND_PRINTING";
  PublicPolicyType["DONATION_OF_EQUIPMENT"] = "DONATION_OF_EQUIPMENT";
  PublicPolicyType["PROVISION_OF_BOARD_MEMBERSHIP"] = "PROVISION_OF_BOARD_MEMBERSHIP";
  PublicPolicyType["EMPLOYMENT_CONSULTANCY_WORK_OFFERED"] = "EMPLOYMENT_CONSULTANCY_WORK_OFFERED";
  PublicPolicyType["OTHER"] = "OTHER";
})(PublicPolicyType || (exports.PublicPolicyType = PublicPolicyType = {}));
var Likelihoods;
(function (Likelihoods) {
  Likelihoods["HIGH"] = "HIGH";
  Likelihoods["MEDIUM"] = "MEDIUM";
  Likelihoods["LOW"] = "LOW";
})(Likelihoods || (exports.Likelihoods = Likelihoods = {}));
var ImpactMagnitude;
(function (ImpactMagnitude) {
  ImpactMagnitude["MAJOR"] = "MAJOR";
  ImpactMagnitude["MODERATE"] = "MODERATE";
  ImpactMagnitude["MINOR"] = "MINOR";
})(ImpactMagnitude || (exports.ImpactMagnitude = ImpactMagnitude = {}));
var SustainableTechnologiesInvestmentTypes;
(function (SustainableTechnologiesInvestmentTypes) {
  SustainableTechnologiesInvestmentTypes["RD_INVESTMENT"] = "RD_INVESTMENT";
  SustainableTechnologiesInvestmentTypes["CAPITAL_EXPENDITURE"] = "CAPITAL_EXPENDITURE";
})(SustainableTechnologiesInvestmentTypes || (exports.SustainableTechnologiesInvestmentTypes = SustainableTechnologiesInvestmentTypes = {}));
var ImpactCategories;
(function (ImpactCategories) {
  ImpactCategories["ENERGY_EFFICIENCY"] = "ENERGY_EFFICIENCY";
  ImpactCategories["RENEWABLE_ENERGY"] = "RENEWABLE_ENERGY";
  ImpactCategories["WATER_CONSERVATION"] = "WATER_CONSERVATION";
  ImpactCategories["WASTE_REDUTION"] = "WASTE_REDUCTION";
  ImpactCategories["EMISSIONS_REDUCTION"] = "EMISSIONS_REDUCTION";
  ImpactCategories["MATERIAL_EFFICIENCY"] = "MATERIAL_EFFICIENCY";
  ImpactCategories["PRODUCT_SAFETY"] = "PRODUCT_SAFETY";
  ImpactCategories["WORKER_HEALTH_SAFETY"] = "WORKER_HEALTH_SAFETY";
  ImpactCategories["COMMUNITY_WELLBEING"] = "COMMUNITY_WELLBEING";
  ImpactCategories["CIRCULAR_ECONOMY"] = "CIRCULAR_ECONOMY";
  ImpactCategories["BIODIVERSITY_PROTECTION"] = "BIODIVERSITY_PROTECTION";
  ImpactCategories["OTHER"] = "OTHER";
})(ImpactCategories || (exports.ImpactCategories = ImpactCategories = {}));
var SecondaryImpactCategory;
(function (SecondaryImpactCategory) {})(SecondaryImpactCategory || (exports.SecondaryImpactCategory = SecondaryImpactCategory = {}));
var UpstreamDownstreamType;
(function (UpstreamDownstreamType) {
  UpstreamDownstreamType["EMISSIONS_FROM_TRANSPORTATION"] = "EMISSIONS_FROM_TRANSPORTATION";
  UpstreamDownstreamType["EMISSIONS_FROM_DISTRIBUTION"] = "EMISSIONS_FROM_DISTRIBUTION";
})(UpstreamDownstreamType || (exports.UpstreamDownstreamType = UpstreamDownstreamType = {}));
var EmissionFromTransportation;
(function (EmissionFromTransportation) {
  EmissionFromTransportation["FUEL_BASED_METHOD"] = "FUEL_BASED_METHOD";
  EmissionFromTransportation["DISTANCE_BASED_METHOD"] = "DISTANCE_BASED_METHOD";
  EmissionFromTransportation["SPEND_BASED_METHOD"] = "SPEND_BASED_METHOD";
  EmissionFromTransportation["SITE_SPECIFIC_METHOD"] = "SITE_SPECIFIC_METHOD";
  EmissionFromTransportation["AVERAGE_DATA_METHOD"] = "AVERAGE_DATA_METHOD";
})(EmissionFromTransportation || (exports.EmissionFromTransportation = EmissionFromTransportation = {}));
var UpstreamDownstreamFuelCalculationMethod;
(function (UpstreamDownstreamFuelCalculationMethod) {
  UpstreamDownstreamFuelCalculationMethod["SPEND_BASED"] = "SPEND_BASED";
  UpstreamDownstreamFuelCalculationMethod["DISTANCE_BASED"] = "DISTANCE_BASED";
})(UpstreamDownstreamFuelCalculationMethod || (exports.UpstreamDownstreamFuelCalculationMethod = UpstreamDownstreamFuelCalculationMethod = {}));
var TransportMode;
(function (TransportMode) {
  TransportMode["ROAD_TRANSPORT_SMALL_TRUCK"] = "ROAD_TRANSPORT_SMALL_TRUCK";
  TransportMode["ROAD_TRANSPORT_MEDIUM_TRUCK"] = "ROAD_TRANSPORT_MEDIUM_TRUCK";
  TransportMode["ROAD_TRANSPORT_HEAVY_TRUCK"] = "ROAD_TRANSPORT_HEAVY_TRUCK";
  TransportMode["ROAD_TRANSPORT_VAN"] = "ROAD_TRANSPORT_VAN";
  TransportMode["ROAD_TRANSPORT_MOTORCYCLE"] = "ROAD_TRANSPORT_MOTORCYCLE";
  TransportMode["ROAD_TRANSPORT_BUS"] = "ROAD_TRANSPORT_BUS";
  TransportMode["RAIL_TRANSPORT_FREIGHT_TRAIN"] = "RAIL_TRANSPORT_FREIGHT_TRAIN";
  TransportMode["RAIL_TRANSPORT_HIGH_SPEED_TRAIN"] = "RAIL_TRANSPORT_HIGH_SPEED_TRAIN";
  TransportMode["RAIL_TRANSPORT_PASSENGER_TRAIN"] = "RAIL_TRANSPORT_PASSENGER_TRAIN";
  TransportMode["RAIL_TRANSPORT_ELECTRIC_TRAIN"] = "RAIL_TRANSPORT_ELECTRIC_TRAIN";
  TransportMode["RAIL_TRANSPORT_DIESEL_TRAIN"] = "RAIL_TRANSPORT_DIESEL_TRAIN";
  TransportMode["AIR_TRANSPORT_CARGO_AIRCRAFT_SHORT_HAUL"] = "AIR_TRANSPORT_CARGO_AIRCRAFT_SHORT_HAUL";
  TransportMode["AIR_TRANSPORT_CARGO_AIRCRAFT_MEDIUM_HAUL"] = "AIR_TRANSPORT_CARGO_AIRCRAFT_MEDIUM_HAUL";
  TransportMode["AIR_TRANSPORT_CARGO_AIRCRAFT_LONG_HAUL"] = "AIR_TRANSPORT_CARGO_AIRCRAFT_LONG_HAUL";
  TransportMode["AIR_TRANSPORT_PASSENGER_AIRCRAFT_SHORT_HAUL"] = "AIR_TRANSPORT_PASSENGER_AIRCRAFT_SHORT_HAUL";
  TransportMode["AIR_TRANSPORT_PASSENGER_AIRCRAFT_MEDIUM_HAUL"] = "AIR_TRANSPORT_PASSENGER_AIRCRAFT_MEDIUM_HAUL";
  TransportMode["AIR_TRANSPORT_PASSENGER_AIRCRAFT_LONG_HAUL"] = "AIR_TRANSPORT_PASSENGER_AIRCRAFT_LONG_HAUL";
  TransportMode["MARITIME_TRANSPORT_CONTAINER_SHIP_SMALL"] = "MARITIME_TRANSPORT_CONTAINER_SHIP_SMALL";
  TransportMode["MARITIME_TRANSPORT_CONTAINER_SHIP_MEDIUM"] = "MARITIME_TRANSPORT_CONTAINER_SHIP_MEDIUM";
  TransportMode["MARITIME_TRANSPORT_CONTAINER_SHIP_LARGE"] = "MARITIME_TRANSPORT_CONTAINER_SHIP_LARGE";
  TransportMode["MARITIME_TRANSPORT_BULK_CARRIER"] = "MARITIME_TRANSPORT_BULK_CARRIER";
  TransportMode["MARITIME_TRANSPORT_ROLL_ON_ROLL_OFF_SHIP"] = "MARITIME_TRANSPORT_ROLL_ON_ROLL_OFF_SHIP";
  TransportMode["MARITIME_TRANSPORT_OIL_TANKER"] = "MARITIME_TRANSPORT_OIL_TANKER";
  TransportMode["MARITIME_TRANSPORT_LNG_TANKER"] = "MARITIME_TRANSPORT_LNG_TANKER";
  TransportMode["INLAND_WATER_TRANSPORT_BARGE_RIVER"] = "INLAND_WATER_TRANSPORT_BARGE_RIVER";
  TransportMode["INLAND_WATER_TRANSPORT_FERRY_PASSENGER"] = "INLAND_WATER_TRANSPORT_FERRY_PASSENGER";
  TransportMode["INLAND_WATER_TRANSPORT_FERRY_VEHICLE_AND_PASSENGER"] = "INLAND_WATER_TRANSPORT_FERRY_VEHICLE_AND_PASSENGER";
  TransportMode["PIPELINE_TRANSPORT_OIL_PIPELINE"] = "PIPELINE_TRANSPORT_OIL_PIPELINE";
  TransportMode["PIPELINE_TRANSPORT_GAS_PIPELINE"] = "PIPELINE_TRANSPORT_GAS_PIPELINE";
  TransportMode["PIPELINE_TRANSPORT_WATER_PIPELINE"] = "PIPELINE_TRANSPORT_WATER_PIPELINE";
  TransportMode["OTHER_MODES_BICYCLE_COURIER"] = "OTHER_MODES_BICYCLE_COURIER";
  TransportMode["OTHER_MODES_ELECTRIC_VEHICLE"] = "OTHER_MODES_ELECTRIC_VEHICLE";
  TransportMode["OTHER_MODES_HYBRID_VEHICLE"] = "OTHER_MODES_HYBRID_VEHICLE";
  TransportMode["OTHER_MODES_HYDROGEN_VEHICLE"] = "OTHER_MODES_HYDROGEN_VEHICLE";
  TransportMode["OTHER_MODES_ANIMAL_DRAWN_VEHICLE"] = "OTHER_MODES_ANIMAL_DRAWN_VEHICLE";
  TransportMode["OTHER_MODES_ELECTRIC_SCOOTER"] = "OTHER_MODES_ELECTRIC_SCOOTER";
})(TransportMode || (exports.TransportMode = TransportMode = {}));
var WasteMethod;
(function (WasteMethod) {
  WasteMethod["SUPPLIER_SPECIFIC_METHOD"] = "SUPPLIER_SPECIFIC_METHOD";
  WasteMethod["WASTE_TYPE_SPECIFIC_METHOD"] = "WASTE_TYPE_SPECIFIC_METHOD";
  WasteMethod["AVERAGE_DATA_METHOD"] = "AVERAGE_DATA_METHOD";
})(WasteMethod || (exports.WasteMethod = WasteMethod = {}));
var WasteGeneratedType;
(function (WasteGeneratedType) {
  WasteGeneratedType["ORGANIC_WASTE_FOOD_WASTE"] = "ORGANIC_WASTE_FOOD_WASTE";
  WasteGeneratedType["ORGANIC_WASTE_AGRICULTURAL_WASTE"] = "ORGANIC_WASTE_AGRICULTURAL_WASTE";
  WasteGeneratedType["ORGANIC_WASTE_GREEN_WASTE"] = "ORGANIC_WASTE_GREEN_WASTE";
  WasteGeneratedType["EMPTY_BARREL"] = "EMPTY_BARREL";
  WasteGeneratedType["BOILER_ASH"] = "BOILER_ASH";
  WasteGeneratedType["ASH"] = "INORGANIC_WASTE_ASH";
  WasteGeneratedType["PLASTIC_WASTE_PET"] = "PLASTIC_WASTE_PET";
  WasteGeneratedType["PLASTIC_WASTE_HDPE"] = "PLASTIC_WASTE_HDPE";
  WasteGeneratedType["PLASTIC_WASTE_LDPE"] = "PLASTIC_WASTE_LDPE";
  WasteGeneratedType["PLASTIC_WASTE_PVC"] = "PLASTIC_WASTE_PVC";
  WasteGeneratedType["PLASTIC_WASTE_MIXED_PLASTICS"] = "PLASTIC_WASTE_MIXED_PLASTICS";
  WasteGeneratedType["METAL_WASTE_FERROUS_METALS"] = "METAL_WASTE_FERROUS_METALS";
  WasteGeneratedType["METAL_WASTE_NON_FERROUS_METALS"] = "METAL_WASTE_NON_FERROUS_METALS";
  WasteGeneratedType["METAL_WASTE_ELECTRONIC_SCRAP"] = "METAL_WASTE_ELECTRONIC_SCRAP";
  WasteGeneratedType["METAL_WASTE_MIXED_METALS"] = "METAL_WASTE_MIXED_METALS";
  WasteGeneratedType["PAPER_AND_CARDBOARD_WASTE_OFFICE_PAPER"] = "PAPER_AND_CARDBOARD_WASTE_OFFICE_PAPER";
  WasteGeneratedType["PAPER_AND_CARDBOARD_WASTE_NEWSPAPER"] = "PAPER_AND_CARDBOARD_WASTE_NEWSPAPER";
  WasteGeneratedType["PAPER_AND_CARDBOARD_WASTE_CARDBOARD"] = "PAPER_AND_CARDBOARD_WASTE_CARDBOARD";
  WasteGeneratedType["PAPER_AND_CARDBOARD_WASTE_MIXED_PAPER"] = "PAPER_AND_CARDBOARD_WASTE_MIXED_PAPER";
  WasteGeneratedType["GLASS_WASTE_CLEAR_GLASS"] = "GLASS_WASTE_CLEAR_GLASS";
  WasteGeneratedType["GLASS_WASTE_COLORED_GLASS"] = "GLASS_WASTE_COLORED_GLASS";
  WasteGeneratedType["GLASS_WASTE_MIXED_GLASS"] = "GLASS_WASTE_MIXED_GLASS";
  WasteGeneratedType["HAZARDOUS_WASTE_CHEMICAL_WASTE"] = "HAZARDOUS_WASTE_CHEMICAL_WASTE";
  WasteGeneratedType["HAZARDOUS_WASTE_MEDICAL_WASTE"] = "HAZARDOUS_WASTE_MEDICAL_WASTE";
  WasteGeneratedType["HAZARDOUS_WASTE_ASBESTOS"] = "HAZARDOUS_WASTE_ASBESTOS";
  WasteGeneratedType["HAZARDOUS_WASTE_PAINTS_AND_COATINGS"] = "HAZARDOUS_WASTE_PAINTS_AND_COATINGS";
  WasteGeneratedType["HAZARDOUS_WASTE_BATTERIES"] = "HAZARDOUS_WASTE_BATTERIES";
  WasteGeneratedType["HAZARDOUS_WASTE_PESTICIDES_AND_HERBICIDES"] = "HAZARDOUS_WASTE_PESTICIDES_AND_HERBICIDES";
  WasteGeneratedType["CONSTRUCTION_AND_DEMOLITION_WASTE_CONCRETE"] = "CONSTRUCTION_AND_DEMOLITION_WASTE_CONCRETE";
  WasteGeneratedType["CONSTRUCTION_AND_DEMOLITION_WASTE_ASPHALT"] = "CONSTRUCTION_AND_DEMOLITION_WASTE_ASPHALT";
  WasteGeneratedType["CONSTRUCTION_AND_DEMOLITION_WASTE_BRICKS"] = "CONSTRUCTION_AND_DEMOLITION_WASTE_BRICKS";
  WasteGeneratedType["CONSTRUCTION_AND_DEMOLITION_WASTE_WOOD"] = "CONSTRUCTION_AND_DEMOLITION_WASTE_WOOD";
  WasteGeneratedType["CONSTRUCTION_AND_DEMOLITION_WASTE_GYPSUM"] = "CONSTRUCTION_AND_DEMOLITION_WASTE_GYPSUM";
  WasteGeneratedType["CONSTRUCTION_AND_DEMOLITION_WASTE_MIXED_CD_WASTE"] = "CONSTRUCTION_AND_DEMOLITION_WASTE_MIXED_CD_WASTE";
  WasteGeneratedType["TEXTILE_WASTE_COTTON_FABRICS"] = "TEXTILE_WASTE_COTTON_FABRICS";
  WasteGeneratedType["TEXTILE_WASTE_SYNTHETIC_FABRICS"] = "TEXTILE_WASTE_SYNTHETIC_FABRICS";
  WasteGeneratedType["TEXTILE_WASTE_MIXED_TEXTILES"] = "TEXTILE_WASTE_MIXED_TEXTILES";
  WasteGeneratedType["RUBBER_WASTE_TIRES"] = "RUBBER_WASTE_TIRES";
  WasteGeneratedType["RUBBER_WASTE_RUBBER_PRODUCTS"] = "RUBBER_WASTE_RUBBER_PRODUCTS";
  WasteGeneratedType["WOOD_WASTE_UNTREATED_WOOD"] = "WOOD_WASTE_UNTREATED_WOOD";
  WasteGeneratedType["WOOD_WASTE_TREATED_WOOD"] = "WOOD_WASTE_TREATED_WOOD";
  WasteGeneratedType["WOOD_WASTE_WOOD_CHIPS_AND_SAWDUST"] = "WOOD_WASTE_WOOD_CHIPS_AND_SAWDUST";
  WasteGeneratedType["ELECTRONIC_WASTE_COMPUTERS_AND_LAPTOPS"] = "ELECTRONIC_WASTE_COMPUTERS_AND_LAPTOPS";
  WasteGeneratedType["ELECTRONIC_WASTE_MOBILE_PHONES"] = "ELECTRONIC_WASTE_MOBILE_PHONES";
  WasteGeneratedType["ELECTRONIC_WASTE_TELEVISIONS"] = "ELECTRONIC_WASTE_TELEVISIONS";
  WasteGeneratedType["ELECTRONIC_WASTE_HOUSEHOLD_APPLIANCES"] = "ELECTRONIC_WASTE_HOUSEHOLD_APPLIANCES";
  WasteGeneratedType["ELECTRONIC_WASTE_CABLES_AND_WIRES"] = "ELECTRONIC_WASTE_CABLES_AND_WIRES";
  WasteGeneratedType["CERAMIC_AND_PORCELAIN_WASTE_TILES"] = "CERAMIC_AND_PORCELAIN_WASTE_TILES";
  WasteGeneratedType["CERAMIC_AND_PORCELAIN_WASTE_SANITARY_WARE"] = "CERAMIC_AND_PORCELAIN_WASTE_SANITARY_WARE";
  WasteGeneratedType["SLUDGE_WASTE_SEWAGE_SLUDGE"] = "SLUDGE_WASTE_SEWAGE_SLUDGE";
  WasteGeneratedType["SLUDGE_WASTE_INDUSTRIAL_SLUDGE"] = "SLUDGE_WASTE_INDUSTRIAL_SLUDGE";
  WasteGeneratedType["MIXED_MUNICIPAL_WASTE_HOUSEHOLD_WASTE"] = "MIXED_MUNICIPAL_WASTE_HOUSEHOLD_WASTE";
  WasteGeneratedType["MIXED_MUNICIPAL_WASTE_COMMERCIAL_WASTE"] = "MIXED_MUNICIPAL_WASTE_COMMERCIAL_WASTE";
  WasteGeneratedType["OTHER_SPECIALIZED_WASTE_PHARMACEUTICAL_WASTE"] = "OTHER_SPECIALIZED_WASTE_PHARMACEUTICAL_WASTE";
  WasteGeneratedType["OTHER_SPECIALIZED_WASTE_BIOMASS_WASTE"] = "OTHER_SPECIALIZED_WASTE_BIOMASS_WASTE";
  WasteGeneratedType["OTHER_SPECIALIZED_WASTE_OIL_AND_GREASE"] = "OTHER_SPECIALIZED_WASTE_OIL_AND_GREASE";
  WasteGeneratedType["OTHER_SPECIALIZED_WASTE_SLAUGHTERHOUSE_WASTE"] = "OTHER_SPECIALIZED_WASTE_SLAUGHTERHOUSE_WASTE";
})(WasteGeneratedType || (exports.WasteGeneratedType = WasteGeneratedType = {}));
var WasteTreatment;
(function (WasteTreatment) {
  WasteTreatment["DISPOSAL_IN_LANDFILL"] = "DISPOSAL_IN_LANDFILL";
  WasteTreatment["DISPOSAL_IN_LANDFILL_WITH_LANDFILL_GAS_TO_ENERGY_LFGTE"] = "DISPOSAL_IN_LANDFILL_WITH_LANDFILL_GAS_TO_ENERGY_LFGTE";
  WasteTreatment["RECOVERY_FOR_RECYCLING"] = "RECOVERY_FOR_RECYCLING";
  WasteTreatment["INCINERATION"] = "INCINERATION";
  WasteTreatment["COMPOSTING"] = "COMPOSTING";
  WasteTreatment["WASTE_TO_ENERGY_WTE_OR_ENERGY_FROM_WASTE_EFW"] = "WASTE_TO_ENERGY_WTE_OR_ENERGY_FROM_WASTE_EFW";
  WasteTreatment["WASTEWATER_TREATMENT"] = "WASTEWATER_TREATMENT";
})(WasteTreatment || (exports.WasteTreatment = WasteTreatment = {}));
var BaseMetric;
(function (BaseMetric) {
  BaseMetric["UNITS_OF_PRODUCT"] = "UNITS_OF_PRODUCT";
  BaseMetric["PRODUCTION_VOLUME"] = "PRODUCTION_VOLUME";
  BaseMetric["SIZE"] = "SIZE";
  BaseMetric["NUMBER_OF_FULL_TIME_EMPLOYEES"] = "NUMBER_OF_FULL_TIME_EMPLOYEES";
  BaseMetric["REVENUE"] = "REVENUE";
})(BaseMetric || (exports.BaseMetric = BaseMetric = {}));
var BaseMetricUnit;
(function (BaseMetricUnit) {
  BaseMetricUnit["PER_KILOGRAM"] = "PER_KILOGRAM";
  BaseMetricUnit["UNIT_OF_PRODUCT"] = "UNIT_OF_PRODUCT";
  BaseMetricUnit["PER_METRIC_TONS"] = "PER_METRIC_TONS";
  BaseMetricUnit["PER_LITER"] = "PER_LITER";
  BaseMetricUnit["PER_MWH"] = "PER_MWH";
  BaseMetricUnit["PER_SQUARE_METERS"] = "PER_SQUARE_METERS";
  BaseMetricUnit["PER_CUBIC_METERS"] = "PER_CUBIC_METERS";
  BaseMetricUnit["NUMBER"] = "NUMBER";
  BaseMetricUnit["REVENUE"] = "REVENUE";
  BaseMetricUnit["SALES"] = "SALES";
  BaseMetricUnit["NET_SALES"] = "NET_SALES";
  BaseMetricUnit["VALUE_ADDED"] = "VALUE_ADDED";
  BaseMetricUnit["EBITDA"] = "EBITDA";
  BaseMetricUnit["OPERATING_PROFIT_INCOME"] = "OPERATING_PROFIT_INCOME";
  BaseMetricUnit["NET_PROFIT_INCOME"] = "NET_PROFIT_INCOME";
  BaseMetricUnit["MARKET_CAPITALIZATION"] = "MARKET_CAPITALIZATION";
  BaseMetricUnit["TOTAL_ASSETS"] = "TOTAL_ASSETS";
  BaseMetricUnit["SHAREHOLDER_EQUITY"] = "SHAREHOLDER_EQUITY";
})(BaseMetricUnit || (exports.BaseMetricUnit = BaseMetricUnit = {}));
var EmissionTypes;
(function (EmissionTypes) {
  EmissionTypes["SCOPE_1_EMISSIONS"] = "SCOPE_1_EMISSIONS";
  EmissionTypes["SCOPE_2_EMISSIONS"] = "SCOPE_2_EMISSIONS";
  EmissionTypes["SCOPE_3_EMISSIONS"] = "SCOPE_3_EMISSIONS";
  EmissionTypes["TOTAL_EMISSIONS"] = "TOTAL_EMISSIONS";
})(EmissionTypes || (exports.EmissionTypes = EmissionTypes = {}));
var ReductionInitiative;
(function (ReductionInitiative) {
  ReductionInitiative["PROCESS_REDESIGN"] = "PROCESS_REDESIGN";
  ReductionInitiative["CHANGES_TO_EQUIPMENTS"] = "CHANGES_TO_EQUIPMENTS";
  ReductionInitiative["FUEL_SWITCHING"] = "FUEL_SWITCHING";
  ReductionInitiative["CHANGES_IN_BEHAVIOR"] = "CHANGES_IN_BEHAVIOR";
  ReductionInitiative["OFFSETS"] = "OFFSETS";
})(ReductionInitiative || (exports.ReductionInitiative = ReductionInitiative = {}));
var ODSEmissionsSubstances;
(function (ODSEmissionsSubstances) {
  ODSEmissionsSubstances["CFCS"] = "CFCS";
  ODSEmissionsSubstances["HCFCS"] = "HCFCS";
  ODSEmissionsSubstances["HALONS"] = "HALONS";
  ODSEmissionsSubstances["MEHTYL_BROMIDE"] = "MEHTYL_BROMIDE";
})(ODSEmissionsSubstances || (exports.ODSEmissionsSubstances = ODSEmissionsSubstances = {}));
var ODSEmissionsFactorSources;
(function (ODSEmissionsFactorSources) {
  ODSEmissionsFactorSources["CFC_11_IPCC_AR5"] = "CFC_11_IPCC_AR5";
})(ODSEmissionsFactorSources || (exports.ODSEmissionsFactorSources = ODSEmissionsFactorSources = {}));
var AirEmissionTypes;
(function (AirEmissionTypes) {
  AirEmissionTypes["NO"] = "NO";
  AirEmissionTypes["SO"] = "SO";
  AirEmissionTypes["PERSISTENT_ORGANIC_POLLUTANTS"] = "PERSISTENT_ORGANIC_POLLUTANTS";
  AirEmissionTypes["VOLATILE_ORGANIC_COMPOUNDS"] = "VOLATILE_ORGANIC_COMPOUNDS";
  AirEmissionTypes["HAZARDOUS_AIR_POLLUTANTS"] = "HAZARDOUS_AIR_POLLUTANTS";
  AirEmissionTypes["PARTICULATE_MATTER"] = "PARTICULATE_MATTER";
})(AirEmissionTypes || (exports.AirEmissionTypes = AirEmissionTypes = {}));
var AirEmissionsCalculationApproaches;
(function (AirEmissionsCalculationApproaches) {
  AirEmissionsCalculationApproaches["DIRECT_MEASUREMENT"] = "DIRECT_MEASUREMENT";
  AirEmissionsCalculationApproaches["SITE_SPECIFIC"] = "SITE_SPECIFIC";
  AirEmissionsCalculationApproaches["EMISSION_FACTOR_BASED"] = "EMISSION_FACTOR_BASED";
  AirEmissionsCalculationApproaches["ESTIMATION"] = "ESTIMATION";
})(AirEmissionsCalculationApproaches || (exports.AirEmissionsCalculationApproaches = AirEmissionsCalculationApproaches = {}));
var FuelEnergyType;
(function (FuelEnergyType) {
  FuelEnergyType["UPSTREAM_EMISSIONS_OF_PURCHASED_FUELS"] = "UPSTREAM_EMISSIONS_OF_PURCHASED_FUELS";
  FuelEnergyType["UPSTREAM_EMISSIONS_OF_PURCHASED_ELECTRICITY"] = "UPSTREAM_EMISSIONS_OF_PURCHASED_ELECTRICITY";
  FuelEnergyType["T_D_LOSSES"] = "T_D_LOSSES";
  FuelEnergyType["GENERATION_OF_PURCHASED_ELECTRICITY_THAT_IS_SOLD_TO_END_USERS"] = "GENERATION_OF_PURCHASED_ELECTRICITY_THAT_IS_SOLD_TO_END_USERS";
})(FuelEnergyType || (exports.FuelEnergyType = FuelEnergyType = {}));
var FuelEnergyMethod;
(function (FuelEnergyMethod) {
  FuelEnergyMethod["SUPPLIER_SPECIFIC_METHOD"] = "SUPPLIER_SPECIFIC_METHOD";
  FuelEnergyMethod["AVERAGE_DATA_METHOD"] = "AVERAGE_DATA_METHOD";
})(FuelEnergyMethod || (exports.FuelEnergyMethod = FuelEnergyMethod = {}));
var fuelEnergyUtilityType;
(function (fuelEnergyUtilityType) {
  fuelEnergyUtilityType["ELECTRICITY"] = "ELECTRICITY";
  fuelEnergyUtilityType["HEATING"] = "HEATING";
  fuelEnergyUtilityType["COOLING"] = "COOLING";
  fuelEnergyUtilityType["STEAM"] = "STEAM";
})(fuelEnergyUtilityType || (exports.fuelEnergyUtilityType = fuelEnergyUtilityType = {}));
var leasedMethod;
(function (leasedMethod) {
  leasedMethod["ASSET_SPECIFIC_METHOD"] = "ASSET_SPECIFIC_METHOD";
  leasedMethod["LESSOR_SPECIFIC_METHOD"] = "LESSOR_SPECIFIC_METHOD";
  leasedMethod["AVERAGE_DATA_METHOD"] = "AVERAGE_DATA_METHOD";
})(leasedMethod || (exports.leasedMethod = leasedMethod = {}));
var leasedMeteringType;
(function (leasedMeteringType) {
  leasedMeteringType["SUB_METERED"] = "SUB_METERED";
  leasedMeteringType["NON_SUB_METERED"] = "NON_SUB_METERED";
})(leasedMeteringType || (exports.leasedMeteringType = leasedMeteringType = {}));
var leasedSizeUnit;
(function (leasedSizeUnit) {
  leasedSizeUnit["SQUARE_METERS"] = "SQUARE_METERS";
  leasedSizeUnit["SQUARE_FEET"] = "SQUARE_FEET";
  leasedSizeUnit["SQUARE_YARD"] = "SQUARE_YARD";
  leasedSizeUnit["ACRES"] = "ACRES";
  leasedSizeUnit["HECTARES"] = "HECTARES";
  leasedSizeUnit["SQARE_KILOMETER"] = "SQARE_KILOMETER";
  leasedSizeUnit["SQUARE_MILES"] = "SQUARE_MILES";
  leasedSizeUnit["CUBIC_METER"] = "CUBIC_METER";
  leasedSizeUnit["CUBIC_FEET"] = "CUBIC_FEET";
  leasedSizeUnit["LITERS"] = "LITERS";
  leasedSizeUnit["GALLONS"] = "GALLONS";
  leasedSizeUnit["BARRELS"] = "BARRELS";
  leasedSizeUnit["CUBIC_YARDS"] = "CUBIC_YARDS";
  leasedSizeUnit["MILILITERS"] = "MILILITERS";
  leasedSizeUnit["PIECE"] = "PIECE";
  leasedSizeUnit["UNIT"] = "UNIT";
  leasedSizeUnit["DOZEN"] = "DOZEN";
  leasedSizeUnit["GROSS"] = "GROSS";
  leasedSizeUnit["BOX"] = "BOX";
  leasedSizeUnit["CARTON"] = "CARTON";
  leasedSizeUnit["PACK"] = "PACK";
  leasedSizeUnit["SET"] = "SET";
  leasedSizeUnit["BUNDLE"] = "BUNDLE";
})(leasedSizeUnit || (exports.leasedSizeUnit = leasedSizeUnit = {}));
var leasedBuilding;
(function (leasedBuilding) {
  leasedBuilding["OFFICE"] = "OFFICE";
  leasedBuilding["WAREHOUSE"] = "WAREHOUSE";
  leasedBuilding["RETAIL"] = "RETAIL";
  leasedBuilding["INDUSTRIAL"] = "INDUSTRIAL";
  leasedBuilding["EDUCATIONAL"] = "EDUCATIONAL";
  leasedBuilding["HEALTHCARE"] = "HEALTHCARE";
  leasedBuilding["RESIDENTIAL"] = "RESIDENTIAL";
  leasedBuilding["HOTEL"] = "HOTEL";
  leasedBuilding["PARKING_GARAGE"] = "PARKING_GARAGE";
  leasedBuilding["MIXED_USE"] = "MIXED_USE";
  leasedBuilding["GOVERMENT"] = "GOVERMENT";
  leasedBuilding["LABORATORY"] = "LABORATORY";
  leasedBuilding["DATA_CENTER"] = "DATA_CENTER";
})(leasedBuilding || (exports.leasedBuilding = leasedBuilding = {}));
var leasedAsset;
(function (leasedAsset) {
  leasedAsset["VEHICLES"] = "VEHICLES";
  leasedAsset["EQUIPMENT"] = "EQUIPMENT";
  leasedAsset["IT_ASSETS"] = "IT_ASSETS";
  leasedAsset["FURNITURE"] = "FURNITURE";
  leasedAsset["CONSTRUCTION_EQUIPMENT"] = "CONSTRUCTION_EQUIPMENT";
  leasedAsset["MEDICAL_EQUIPMENT"] = "MEDICAL_EQUIPMENT";
  leasedAsset["AGRICULTURE_EQUIPMENT"] = "AGRICULTURE_EQUIPMENT";
  leasedAsset["AIRCRAFT"] = "AIRCRAFT";
  leasedAsset["MARINE_VESSELS"] = "MARINE_VESSELS";
  leasedAsset["RAIL_TRANSPORT"] = "RAIL_TRANSPORT";
  leasedAsset["TELECOMMUNICATIONS_EQUIPMENT"] = "TELECOMMUNICATIONS_EQUIPMENT";
  leasedAsset["ENERGY_SYSTEMS"] = "ENERGY_SYSTEMS";
})(leasedAsset || (exports.leasedAsset = leasedAsset = {}));
var ProcessingSoldMethod;
(function (ProcessingSoldMethod) {
  ProcessingSoldMethod["SITE_SPECIFIC_METHOD"] = "SITE_SPECIFIC_METHOD";
  ProcessingSoldMethod["AVERAGE_DATA_METHOD"] = "AVERAGE_DATA_METHOD";
})(ProcessingSoldMethod || (exports.ProcessingSoldMethod = ProcessingSoldMethod = {}));
var EndOfLifeMethod;
(function (EndOfLifeMethod) {
  EndOfLifeMethod["WASTE_TYPE_SPECIFIC_METHOD"] = "WASTE_TYPE_SPECIFIC_METHOD";
})(EndOfLifeMethod || (exports.EndOfLifeMethod = EndOfLifeMethod = {}));
var EndOfLifeType;
(function (EndOfLifeType) {
  EndOfLifeType["PRODUCT"] = "PRODUCT";
  EndOfLifeType["PACKAGING"] = "PACKAGING";
})(EndOfLifeType || (exports.EndOfLifeType = EndOfLifeType = {}));
var FranchiseMethod;
(function (FranchiseMethod) {
  FranchiseMethod["FRANCHISE_SPECIFIC_METHOD"] = "FRANCHISE_SPECIFIC_METHOD";
  FranchiseMethod["AVERAGE_DATA_METHOD"] = "AVERAGE_DATA_METHOD";
})(FranchiseMethod || (exports.FranchiseMethod = FranchiseMethod = {}));
var FranchiseType;
(function (FranchiseType) {
  FranchiseType["SUB_METERED_BY_TENANT"] = "SUB_METERED_BY_TENANT";
  FranchiseType["NON_SUB_METERED_BY_TENANT"] = "NON_SUB_METERED_BY_TENANT";
  FranchiseType["FLOOR_PLAN_AVAILABLE"] = "FLOOR_PLAN_AVAILABLE";
  FranchiseType["FLOOR_PLAN_NOT_AVAILABLE"] = "FLOOR_PLAN_NOT_AVAILABLE";
})(FranchiseType || (exports.FranchiseType = FranchiseType = {}));
var FranchiseBuildingType;
(function (FranchiseBuildingType) {
  FranchiseBuildingType["OFFICE_BUILDINGS"] = "OFFICE_BUILDINGS";
  FranchiseBuildingType["RETAIL_SPACES"] = "RETAIL_SPACES";
  FranchiseBuildingType["WAREHOUSE"] = "WAREHOUSE";
  FranchiseBuildingType["FACTORIES_INDUSTRIAL_BUILDINGS"] = "FACTORIES_INDUSTRIAL_BUILDINGS";
  FranchiseBuildingType["MULTI_FAMILY_RESIDENTIAL_BUILDINGS"] = "MULTI_FAMILY_RESIDENTIAL_BUILDINGS";
  FranchiseBuildingType["SINGLE_FAMILY_RESIDENTIAL_BUILDINGS"] = "SINGLE_FAMILY_RESIDENTIAL_BUILDINGS";
  FranchiseBuildingType["HOTELS_AND_HOSPITALITY_BUILDINGS"] = "HOTELS_AND_HOSPITALITY_BUILDINGS";
  FranchiseBuildingType["EDUCATIONAL_BUILDINGS"] = "EDUCATIONAL_BUILDINGS";
  FranchiseBuildingType["HEALTHCARE_FACILITIES"] = "HEALTHCARE_FACILITIES";
  FranchiseBuildingType["PUBLIC_BUILDINGS"] = "PUBLIC_BUILDINGS";
  FranchiseBuildingType["DATA_CENTERS"] = "DATA_CENTERS";
  FranchiseBuildingType["RESTURANTS"] = "RESTURANTS";
  FranchiseBuildingType["OTHERS"] = "OTHERS";
})(FranchiseBuildingType || (exports.FranchiseBuildingType = FranchiseBuildingType = {}));
var UseSoldType;
(function (UseSoldType) {
  UseSoldType["DIRECT_USE_PHASE_EMISSIONS"] = "DIRECT_USE_PHASE_EMISSIONS";
  UseSoldType["INDIRECT_USE_PHASE_EMISSIONS"] = "INDIRECT_USE_PHASE_EMISSIONS";
})(UseSoldType || (exports.UseSoldType = UseSoldType = {}));
var UseSoldMethod;
(function (UseSoldMethod) {
  UseSoldMethod["PRODUCTS_DIRECTLY_CONSUMING_ENERGY"] = "PRODUCTS_DIRECTLY_CONSUMING_ENERGY";
  UseSoldMethod["FUELS_AND_FEED_STOCKS"] = "FUELS_AND_FEED_STOCKS";
  UseSoldMethod["GHG_EMISSIONS_DURING_USE"] = "GHG_EMISSIONS_DURING_USE";
  UseSoldMethod["PRODUCTS_INDIRECTLY_CONSUMING_ENERGY"] = "PRODUCTS_INDIRECTLY_CONSUMING_ENERGY";
  UseSoldMethod["INTERMEDIATE_PRODUCTS_CONSUMING_ENERGY_DIRECTLY"] = "INTERMEDIATE_PRODUCTS_CONSUMING_ENERGY_DIRECTLY";
})(UseSoldMethod || (exports.UseSoldMethod = UseSoldMethod = {}));
var FuelAndFeedStock;
(function (FuelAndFeedStock) {
  FuelAndFeedStock["NATURAL_GAS"] = "NATURAL_GAS";
  FuelAndFeedStock["DIESEL"] = "DIESEL";
  FuelAndFeedStock["PETROL"] = "PETROL";
  FuelAndFeedStock["COAL"] = "COAL";
  FuelAndFeedStock["LPG"] = "LPG";
  FuelAndFeedStock["KAROSENE"] = "KAROSENE";
  FuelAndFeedStock["BIOMASS"] = "BIOMASS";
  FuelAndFeedStock["FUEL_OIL"] = "FUEL_OIL";
  FuelAndFeedStock["BIODIESEL"] = "BIODIESEL";
  FuelAndFeedStock["ETHANOL"] = "ETHANOL";
  FuelAndFeedStock["HYDROGEN"] = "HYDROGEN";
  FuelAndFeedStock["PROPANE"] = "PROPANE";
  FuelAndFeedStock["CRUDE_OIL"] = "CRUDE_OIL";
  FuelAndFeedStock["NATURAL_GAS_LIQUIDS"] = "NATURAL_GAS_LIQUIDS";
  FuelAndFeedStock["METHANOL"] = "METHANOL";
  FuelAndFeedStock["NEPHTHA"] = "NEPHTHA";
  FuelAndFeedStock["ETHANE"] = "ETHANE";
  FuelAndFeedStock["BUTANE"] = "BUTANE";
  FuelAndFeedStock["PROPYLENE"] = "PROPYLENE";
  FuelAndFeedStock["AMMONIA"] = "AMMONIA";
  FuelAndFeedStock["SYNTHESIS_GAS"] = "SYNTHESIS_GAS";
  FuelAndFeedStock["SUGARCANE"] = "SUGARCANE";
  FuelAndFeedStock["PALM_OIL"] = "PALM_OIL";
})(FuelAndFeedStock || (exports.FuelAndFeedStock = FuelAndFeedStock = {}));
var Ghg;
(function (Ghg) {
  Ghg["CARBON_DIOXIDE"] = "CARBON_DIOXIDE";
  Ghg["METHANE"] = "METHANE";
  Ghg["NITROUS"] = "NITROUS";
  Ghg["HYDROFLUOROCARBONS"] = "HYDROFLUOROCARBONS";
  Ghg["PERFLUOROCARBONS"] = "PERFLUOROCARBONS";
  Ghg["SULFUR_HEXAFLUROIDE"] = "SULFUR_HEXAFLUROIDE";
  Ghg["NITROGEN_TRIFLUORIDE"] = "NITROGEN_TRIFLUORIDE";
  Ghg["OZONE"] = "OZONE";
  Ghg["CHLOROFLUOROCARBONS"] = "CHLOROFLUOROCARBONS";
  Ghg["HALONS"] = "HALONS";
  Ghg["CARBON_MONOXIDE"] = "CARBON_MONOXIDE";
})(Ghg || (exports.Ghg = Ghg = {}));
var YesNo;
(function (YesNo) {
  YesNo["YES"] = "Yes";
  YesNo["NO"] = "No";
})(YesNo || (exports.YesNo = YesNo = {}));
var ExtinctionRiskLevels;
(function (ExtinctionRiskLevels) {
  ExtinctionRiskLevels["CRITICALLY"] = "CRITICALLY";
  ExtinctionRiskLevels["ENDANGERED"] = "ENDANGERED";
  ExtinctionRiskLevels["VULNERABLE"] = "VULNERABLE";
  ExtinctionRiskLevels["NEAR_THREATENED"] = "NEAR_THREATENED";
  ExtinctionRiskLevels["LEAST_CONCERN"] = "LEAST_CONCERN";
})(ExtinctionRiskLevels || (exports.ExtinctionRiskLevels = ExtinctionRiskLevels = {}));
var OwnershipStatuses;
(function (OwnershipStatuses) {
  OwnershipStatuses["OWNED"] = "OWNED";
  OwnershipStatuses["LEASED"] = "LEASED";
  OwnershipStatuses["MANAGED"] = "MANAGED";
  OwnershipStatuses["ADJACENT"] = "ADJACENT";
})(OwnershipStatuses || (exports.OwnershipStatuses = OwnershipStatuses = {}));
var SubsurfaceLandStatuses;
(function (SubsurfaceLandStatuses) {
  SubsurfaceLandStatuses["OWNED"] = "OWNED";
  SubsurfaceLandStatuses["LEASED"] = "LEASED";
  SubsurfaceLandStatuses["MANAGED"] = "MANAGED";
})(SubsurfaceLandStatuses || (exports.SubsurfaceLandStatuses = SubsurfaceLandStatuses = {}));
var RelationToProtectedArea;
(function (RelationToProtectedArea) {
  RelationToProtectedArea["IN_THE_AREA"] = "IN_THE_AREA";
  RelationToProtectedArea["ADJACENT_TO"] = "ADJACENT_TO";
  RelationToProtectedArea["CONTAINING_PORTIONS"] = "CONTAINING_PORTIONS";
})(RelationToProtectedArea || (exports.RelationToProtectedArea = RelationToProtectedArea = {}));
var OperationTypes;
(function (OperationTypes) {
  OperationTypes["OFFICE"] = "OFFICE";
  OperationTypes["MANUFACTURING_OR_PRODUCTION"] = "MANUFACTURING_OR_PRODUCTION";
  OperationTypes["EXTRACTIVE"] = "EXTRACTIVE";
})(OperationTypes || (exports.OperationTypes = OperationTypes = {}));
var BiodiversityValueAttributes;
(function (BiodiversityValueAttributes) {
  BiodiversityValueAttributes["TERRESTRIAL"] = "TERRESTRIAL";
  BiodiversityValueAttributes["FRESHWATER"] = "FRESHWATER";
  BiodiversityValueAttributes["MARITIME"] = "MARITIME";
})(BiodiversityValueAttributes || (exports.BiodiversityValueAttributes = BiodiversityValueAttributes = {}));
var ImpactNatureTypes;
(function (ImpactNatureTypes) {
  ImpactNatureTypes["CONSTRUCTION_MANUFACTURING"] = "CONSTRUCTION_MANUFACTURING";
  ImpactNatureTypes["POLLUTION"] = "POLLUTION";
  ImpactNatureTypes["INVASIVE_SPECIES"] = "INVASIVE_SPECIES";
  ImpactNatureTypes["SPECIES_REDUCTION"] = "SPECIES_REDUCTION";
  ImpactNatureTypes["HABITAT_CONVERSION"] = "HABITAT_CONVERSION";
  ImpactNatureTypes["ECOLOGICAL_CHANGES"] = "ECOLOGICAL_CHANGES";
})(ImpactNatureTypes || (exports.ImpactNatureTypes = ImpactNatureTypes = {}));
var ImpactTypes;
(function (ImpactTypes) {
  ImpactTypes["POSITIVE"] = "POSITIVE";
  ImpactTypes["NEGATIVE"] = "NEGATIVE";
})(ImpactTypes || (exports.ImpactTypes = ImpactTypes = {}));
var ImpactReversibilityTypes;
(function (ImpactReversibilityTypes) {
  ImpactReversibilityTypes["REVERSIBLE"] = "REVERSIBLE";
  ImpactReversibilityTypes["IRREVERSIBLE"] = "IRREVERSIBLE";
})(ImpactReversibilityTypes || (exports.ImpactReversibilityTypes = ImpactReversibilityTypes = {}));
var RestorationSuccessTypes;
(function (RestorationSuccessTypes) {
  RestorationSuccessTypes["APPROVED"] = "APPROVED";
  RestorationSuccessTypes["NOT_APPROVED"] = "NOT_APPROVED";
})(RestorationSuccessTypes || (exports.RestorationSuccessTypes = RestorationSuccessTypes = {}));
var PartnershipStatuses;
(function (PartnershipStatuses) {
  PartnershipStatuses["EXISTS"] = "EXISTS";
  PartnershipStatuses["DOES_NOT_EXIST"] = "DOES_NOT_EXIST";
})(PartnershipStatuses || (exports.PartnershipStatuses = PartnershipStatuses = {}));
var BusinessTravelMethods;
(function (BusinessTravelMethods) {
  BusinessTravelMethods["FUEL_BASED_METHOD"] = "FUEL_BASED_METHOD";
  BusinessTravelMethods["DISTANCE_BASED_METHOD"] = "DISTANCE_BASED_METHOD";
})(BusinessTravelMethods || (exports.BusinessTravelMethods = BusinessTravelMethods = {}));
var BusinessTraveFuelCalculationMethods;
(function (BusinessTraveFuelCalculationMethods) {
  BusinessTraveFuelCalculationMethods["SPEND_BASED"] = "SPEND_BASED";
  BusinessTraveFuelCalculationMethods["DISTANCE_BASED"] = "DISTANCE_BASED";
})(BusinessTraveFuelCalculationMethods || (exports.BusinessTraveFuelCalculationMethods = BusinessTraveFuelCalculationMethods = {}));
var TravelMethods;
(function (TravelMethods) {
  TravelMethods["AIR_TRAVEL_DOMESTIC_FLIGHTS"] = "AIR_TRAVEL_DOMESTIC_FLIGHTS";
  TravelMethods["AIR_TRAVEL_INTERNATIONAL_FLIGHTS"] = "AIR_TRAVEL_INTERNATIONAL_FLIGHTS";
  TravelMethods["AIR_TRAVEL_PRIVATE_JET_TRAVEL"] = "AIR_TRAVEL_PRIVATE_JET_TRAVEL";
  TravelMethods["RAIL_TRAVEL_INTERCITY_TRAIN"] = "RAIL_TRAVEL_INTERCITY_TRAIN";
  TravelMethods["RAIL_TRAVEL_SUBURBAN_REGIONAL_TRAIN"] = "RAIL_TRAVEL_SUBURBAN_REGIONAL_TRAIN";
  TravelMethods["RAIL_TRAVEL_HIGH_SPEED_RAIL"] = "RAIL_TRAVEL_HIGH_SPEED_RAIL";
  TravelMethods["RAIL_TRAVEL_URBAN_RAIL_METRO"] = "RAIL_TRAVEL_URBAN_RAIL_METRO";
  TravelMethods["ROAD_TRAVEL_COMPANY_OWNED_VEHICLES"] = "ROAD_TRAVEL_COMPANY_OWNED_VEHICLES";
  TravelMethods["ROAD_TRAVEL_RENTAL_CARS"] = "ROAD_TRAVEL_RENTAL_CARS";
  TravelMethods["ROAD_TRAVEL_EMPLOYEE_OWNED_VEHICLES"] = "ROAD_TRAVEL_EMPLOYEE_OWNED_VEHICLES";
  TravelMethods["ROAD_TRAVEL_TAXIS_AND_RIDE_HAILING"] = "ROAD_TRAVEL_TAXIS_AND_RIDE_HAILING";
  TravelMethods["ROAD_TRAVEL_BUS_TRAVEL"] = "ROAD_TRAVEL_BUS_TRAVEL";
  TravelMethods["ROAD_TRAVEL_MOTORCYCLES"] = "ROAD_TRAVEL_MOTORCYCLES";
  TravelMethods["WATER_TRANSPORT_FERRY_TRAVEL"] = "WATER_TRANSPORT_FERRY_TRAVEL";
  TravelMethods["WATER_TRANSPORT_BOAT_TRAVEL"] = "WATER_TRANSPORT_BOAT_TRAVEL";
  TravelMethods["OTHER_TRAVEL_ACTIVITIES_CARPOOLING_SHARED_RIDES"] = "OTHER_TRAVEL_ACTIVITIES_CARPOOLING_SHARED_RIDES";
  TravelMethods["OTHER_TRAVEL_ACTIVITIES_BICYCLE_TRAVEL"] = "OTHER_TRAVEL_ACTIVITIES_BICYCLE_TRAVEL";
  TravelMethods["OTHER_TRAVEL_ACTIVITIES_ELECTRIC_SCOOTER_TRAVEL"] = "OTHER_TRAVEL_ACTIVITIES_ELECTRIC_SCOOTER_TRAVEL";
})(TravelMethods || (exports.TravelMethods = TravelMethods = {}));
var VehicleTypes;
(function (VehicleTypes) {
  VehicleTypes["PASSENGER_CAR_SMALL"] = "PASSENGER_CAR_SMALL";
  VehicleTypes["PASSENGER_CAR_MEDIUM"] = "PASSENGER_CAR_MEDIUM";
  VehicleTypes["PASSENGER_CAR_LARGE"] = "PASSENGER_CAR_LARGE";
  VehicleTypes["PASSENGER_CAR_ELECTRIC"] = "PASSENGER_CAR_ELECTRIC";
  VehicleTypes["PASSENGER_CAR_HYBRID"] = "PASSENGER_CAR_HYBRID";
  VehicleTypes["PASSENGER_CAR_DIESEL"] = "PASSENGER_CAR_DIESEL";
  VehicleTypes["PASSENGER_CAR_PETROL"] = "PASSENGER_CAR_PETROL";
  VehicleTypes["MOTORCYCLE_SMALL"] = "MOTORCYCLE_SMALL";
  VehicleTypes["MOTORCYCLE_LARGE"] = "MOTORCYCLE_LARGE";
  VehicleTypes["MOTORCYCLE_ELECTRIC"] = "MOTORCYCLE_ELECTRIC";
  VehicleTypes["BUS_URBAN"] = "BUS_URBAN";
  VehicleTypes["BUS_COACH"] = "BUS_COACH";
  VehicleTypes["BUS_SCHOOL"] = "BUS_SCHOOL";
  VehicleTypes["BUS_ELECTRIC"] = "BUS_ELECTRIC";
  VehicleTypes["TRUCK_LIGHT"] = "TRUCK_LIGHT";
  VehicleTypes["TRUCK_MEDIUM"] = "TRUCK_MEDIUM";
  VehicleTypes["TRUCK_HEAVY"] = "TRUCK_HEAVY";
  VehicleTypes["TRUCK_ELECTRIC"] = "TRUCK_ELECTRIC";
  VehicleTypes["VAN_DELIVERY"] = "VAN_DELIVERY";
  VehicleTypes["VAN_PASSENGER"] = "VAN_PASSENGER";
  VehicleTypes["VAN_ELECTRIC"] = "VAN_ELECTRIC";
  VehicleTypes["TRAIN_DIESEL"] = "TRAIN_DIESEL";
  VehicleTypes["TRAIN_ELECTRIC"] = "TRAIN_ELECTRIC";
  VehicleTypes["TRAIN_HIGH_SPEED"] = "TRAIN_HIGH_SPEED";
  VehicleTypes["TRAIN_FREIGHT"] = "TRAIN_FREIGHT";
  VehicleTypes["TRAIN_LIGHT_RAIL"] = "TRAIN_LIGHT_RAIL";
  VehicleTypes["AIRCRAFT_SHORT_HAUL"] = "AIRCRAFT_SHORT_HAUL";
  VehicleTypes["AIRCRAFT_MEDIUM_HAUL"] = "AIRCRAFT_MEDIUM_HAUL";
  VehicleTypes["AIRCRAFT_LONG_HAUL"] = "AIRCRAFT_LONG_HAUL";
  VehicleTypes["AIRCRAFT_PRIVATE_JET"] = "AIRCRAFT_PRIVATE_JET";
  VehicleTypes["AIRCRAFT_HELICOPTER"] = "AIRCRAFT_HELICOPTER";
  VehicleTypes["SHIP_FERRY"] = "SHIP_FERRY";
  VehicleTypes["SHIP_CARGO"] = "SHIP_CARGO";
  VehicleTypes["SHIP_CRUISE"] = "SHIP_CRUISE";
  VehicleTypes["SHIP_SPEEDBOAT"] = "SHIP_SPEEDBOAT";
  VehicleTypes["SHIP_SAILBOAT"] = "SHIP_SAILBOAT";
  VehicleTypes["SHIP_FISHING_BOAT"] = "SHIP_FISHING_BOAT";
  VehicleTypes["HUMAN_POWERED_BICYCLE"] = "HUMAN_POWERED_BICYCLE";
  VehicleTypes["HUMAN_POWERED_ELECTRIC_SCOOTER"] = "HUMAN_POWERED_ELECTRIC_SCOOTER";
  VehicleTypes["HUMAN_POWERED_RICKSHAW"] = "HUMAN_POWERED_RICKSHAW";
  VehicleTypes["SPECIALIZED_AMBULANCE"] = "SPECIALIZED_AMBULANCE";
  VehicleTypes["SPECIALIZED_FIRE_TRUCK"] = "SPECIALIZED_FIRE_TRUCK";
  VehicleTypes["SPECIALIZED_POLICE_CAR"] = "SPECIALIZED_POLICE_CAR";
  VehicleTypes["SPECIALIZED_CONSTRUCTION"] = "SPECIALIZED_CONSTRUCTION";
  VehicleTypes["SPECIALIZED_AGRICULTURAL"] = "SPECIALIZED_AGRICULTURAL";
})(VehicleTypes || (exports.VehicleTypes = VehicleTypes = {}));
var EmployeeCommutingMethods;
(function (EmployeeCommutingMethods) {
  EmployeeCommutingMethods["FUEL_BASED_METHOD"] = "FUEL_BASED_METHOD";
  EmployeeCommutingMethods["DISTANCE_BASED_METHOD"] = "DISTANCE_BASED_METHOD";
  EmployeeCommutingMethods["AVERAGE_BASED_METHOD"] = "AVERAGE_BASED_METHOD";
})(EmployeeCommutingMethods || (exports.EmployeeCommutingMethods = EmployeeCommutingMethods = {}));
var ECFuelBasedCalculationMethods;
(function (ECFuelBasedCalculationMethods) {
  ECFuelBasedCalculationMethods["SPEND_BASED_METHOD"] = "SPEND_BASED_METHOD";
  ECFuelBasedCalculationMethods["DISTANCE_BASED_METHOD"] = "DISTANCE_BASED_METHOD";
})(ECFuelBasedCalculationMethods || (exports.ECFuelBasedCalculationMethods = ECFuelBasedCalculationMethods = {}));
var ECDistanceBasedMethod;
(function (ECDistanceBasedMethod) {
  ECDistanceBasedMethod["COMMUTING"] = "COMMUTING";
  ECDistanceBasedMethod["TELEWORKING"] = "TELEWORKING";
})(ECDistanceBasedMethod || (exports.ECDistanceBasedMethod = ECDistanceBasedMethod = {}));
var CommutingActivity;
(function (CommutingActivity) {
  CommutingActivity["PERSONAL_VEHICLE_COMMUTE"] = "PERSONAL_VEHICLE_COMMUTE";
  CommutingActivity["PUBLIC_TRANSPORTATION_BUS_COMMUTE"] = "PUBLIC_TRANSPORTATION_BUS_COMMUTE";
  CommutingActivity["PUBLIC_TRANSPORTATION_TRAIN_METRO_COMMUTE"] = "PUBLIC_TRANSPORTATION_TRAIN_METRO_COMMUTE";
  CommutingActivity["PUBLIC_TRANSPORTATION_TRAM_COMMUTE"] = "PUBLIC_TRANSPORTATION_TRAM_COMMUTE";
  CommutingActivity["BICYCLE_COMMUTE"] = "BICYCLE_COMMUTE";
  CommutingActivity["WALKING_COMMUTE"] = "WALKING_COMMUTE";
  CommutingActivity["MOTORCYCLE_COMMUTE"] = "MOTORCYCLE_COMMUTE";
  CommutingActivity["RIDE_HAILING_TAXI_COMMUTE"] = "RIDE_HAILING_TAXI_COMMUTE";
  CommutingActivity["CARPOOL_VANPOOL_COMMUTE"] = "CARPOOL_VANPOOL_COMMUTE";
  CommutingActivity["ELECTRIC_SCOOTER_COMMUTE"] = "ELECTRIC_SCOOTER_COMMUTE";
  CommutingActivity["COMPANY_SHUTTLE_COMMUTE"] = "COMPANY_SHUTTLE_COMMUTE";
})(CommutingActivity || (exports.CommutingActivity = CommutingActivity = {}));
var TeleWorkingType;
(function (TeleWorkingType) {
  TeleWorkingType["HOME_OFFICE"] = "HOME_OFFICE";
  TeleWorkingType["SHARED_WORKSPACE"] = "SHARED_WORKSPACE";
  TeleWorkingType["CLIENT_SITE"] = "CLIENT_SITE";
  TeleWorkingType["HYBRID"] = "HYBRID";
  TeleWorkingType["REMOTE_WORK_ABROAD"] = "REMOTE_WORK_ABROAD";
})(TeleWorkingType || (exports.TeleWorkingType = TeleWorkingType = {}));
var CommputingVehicleType;
(function (CommputingVehicleType) {
  CommputingVehicleType["CAR_PETROL"] = "CAR_PETROL";
  CommputingVehicleType["CAR_DIESEL"] = "CAR_DIESEL";
  CommputingVehicleType["CAR_ELECTRIC"] = "CAR_ELECTRIC";
  CommputingVehicleType["CAR_HYBRID"] = "CAR_HYBRID";
  CommputingVehicleType["MOTORCYCLE_PETROL"] = "MOTORCYCLE_PETROL";
  CommputingVehicleType["MOTORCYCLE_ELECTRIC"] = "MOTORCYCLE_ELECTRIC";
  CommputingVehicleType["BUS"] = "BUS";
  CommputingVehicleType["TRAIN"] = "TRAIN";
  CommputingVehicleType["METRO_SUBWAY"] = "METRO_SUBWAY";
  CommputingVehicleType["TRAM"] = "TRAM";
  CommputingVehicleType["FERRY"] = "FERRY";
  CommputingVehicleType["BICYCLE"] = "BICYCLE";
  CommputingVehicleType["ELECTRIC_BICYCLE"] = "ELECTRIC_BICYCLE";
  CommputingVehicleType["WALKING"] = "WALKING";
  CommputingVehicleType["CAR_CARPOOL"] = "CAR_CARPOOL";
  CommputingVehicleType["VAN_SHARED_RIDE"] = "VAN_SHARED_RIDE";
  CommputingVehicleType["RIDE_HAILING_CAR"] = "RIDE_HAILING_CAR";
  CommputingVehicleType["RIDE_HAILING_VAN"] = "RIDE_HAILING_VAN";
})(CommputingVehicleType || (exports.CommputingVehicleType = CommputingVehicleType = {}));
var CommputingTansportMode;
(function (CommputingTansportMode) {
  CommputingTansportMode["PERSONAL_VEHICLES_CAR_PETROL"] = "PERSONAL_VEHICLES_CAR_PETROL";
  CommputingTansportMode["PERSONAL_VEHICLES_CAR_DIESEL"] = "PERSONAL_VEHICLES_CAR_DIESEL";
  CommputingTansportMode["PERSONAL_VEHICLES_CAR_ELECTRIC"] = "PERSONAL_VEHICLES_CAR_ELECTRIC";
  CommputingTansportMode["PERSONAL_VEHICLES_CAR_HYBRID"] = "PERSONAL_VEHICLES_CAR_HYBRID";
  CommputingTansportMode["PERSONAL_VEHICLES_MOTORCYCLE_PETROL"] = "PERSONAL_VEHICLES_MOTORCYCLE_PETROL";
  CommputingTansportMode["PERSONAL_VEHICLES_MOTORCYCLE_ELECTRIC"] = "PERSONAL_VEHICLES_MOTORCYCLE_ELECTRIC";
  CommputingTansportMode["PUBLIC_TRANSPORTATION_BUS"] = "PUBLIC_TRANSPORTATION_BUS";
  CommputingTansportMode["PUBLIC_TRANSPORTATION_TRAIN"] = "PUBLIC_TRANSPORTATION_TRAIN";
  CommputingTansportMode["PUBLIC_TRANSPORTATION_METRO_SUBWAY"] = "PUBLIC_TRANSPORTATION_METRO_SUBWAY";
  CommputingTansportMode["PUBLIC_TRANSPORTATION_TRAM"] = "PUBLIC_TRANSPORTATION_TRAM";
  CommputingTansportMode["PUBLIC_TRANSPORTATION_FERRY"] = "PUBLIC_TRANSPORTATION_FERRY";
  CommputingTansportMode["NON_MOTORIZED_TRANSPORT_BICYCLE"] = "NON_MOTORIZED_TRANSPORT_BICYCLE";
  CommputingTansportMode["NON_MOTORIZED_TRANSPORT_ELECTRIC_BICYCLE"] = "NON_MOTORIZED_TRANSPORT_ELECTRIC_BICYCLE";
  CommputingTansportMode["NON_MOTORIZED_TRANSPORT_WALKING"] = "NON_MOTORIZED_TRANSPORT_WALKING";
  CommputingTansportMode["CARPOOLING_SHARED_RIDES_CAR_CARPOOL"] = "CARPOOLING_SHARED_RIDES_CAR_CARPOOL";
  CommputingTansportMode["CARPOOLING_SHARED_RIDES_VAN_SHARED_RIDE"] = "CARPOOLING_SHARED_RIDES_VAN_SHARED_RIDE";
  CommputingTansportMode["CARPOOLING_SHARED_RIDES_RIDE_HAILING_CAR"] = "CARPOOLING_SHARED_RIDES_RIDE_HAILING_CAR";
  CommputingTansportMode["CARPOOLING_SHARED_RIDES_RIDE_HAILING_VAN"] = "CARPOOLING_SHARED_RIDES_RIDE_HAILING_VAN";
})(CommputingTansportMode || (exports.CommputingTansportMode = CommputingTansportMode = {}));
var CapitalPurchasedGoodsMethod;
(function (CapitalPurchasedGoodsMethod) {
  CapitalPurchasedGoodsMethod["SUPPLIER_SPECIFIC_METHOD"] = "SUPPLIER_SPECIFIC_METHOD";
  CapitalPurchasedGoodsMethod["HYBRID_METHOD"] = "HYBRID_METHOD";
  CapitalPurchasedGoodsMethod["AVERAGE_DATA_METHOD"] = "AVERAGE_DATA_METHOD";
  CapitalPurchasedGoodsMethod["SPEND_BASED_METHOD"] = "SPEND_BASED_METHOD";
})(CapitalPurchasedGoodsMethod || (exports.CapitalPurchasedGoodsMethod = CapitalPurchasedGoodsMethod = {}));
var PurchaseGoodsEmissionCategory;
(function (PurchaseGoodsEmissionCategory) {
  PurchaseGoodsEmissionCategory["RECOMMENDED_AI"] = "RECOMMeNDED_AI";
  PurchaseGoodsEmissionCategory["CURRENT_SPEND"] = "CURRENT_SPEND";
  PurchaseGoodsEmissionCategory["CUSTOM"] = "CUSTOM";
})(PurchaseGoodsEmissionCategory || (exports.PurchaseGoodsEmissionCategory = PurchaseGoodsEmissionCategory = {}));
var BlockchainCallStatus;
(function (BlockchainCallStatus) {
  BlockchainCallStatus["PENDING"] = "PENDING";
  BlockchainCallStatus["SYNCHRONIZED"] = "SYNCHRONIZED";
  BlockchainCallStatus["ERROR"] = "ERROR";
})(BlockchainCallStatus || (exports.BlockchainCallStatus = BlockchainCallStatus = {}));
var ElectricitySource;
(function (ElectricitySource) {
  ElectricitySource["GRID_ELECTRICITY"] = "GRID_ELECTRICITY";
  ElectricitySource["SOLAR_ENERGY"] = "SOLAR_ENERGY";
  ElectricitySource["WIND_ENERGY"] = "WIND_ENERGY";
  ElectricitySource["HYDRO_ENERGY"] = "HYDRO_ENERGY";
  ElectricitySource["NUCLEAR_ENERGY"] = "NUCLEAR_ENERGY";
})(ElectricitySource || (exports.ElectricitySource = ElectricitySource = {}));
var ElectricityInstruments;
(function (ElectricityInstruments) {
  ElectricityInstruments["RECS"] = "RECS";
  ElectricityInstruments["PPAS"] = "PPAS";
  ElectricityInstruments["GOS"] = "GOS";
  ElectricityInstruments["SUPPLIER_SPECIFIC_CONTRACTS"] = "SUPPLIER_SPECIFIC_CONTRACTS";
  ElectricityInstruments["GREEN_TARIFFS"] = "GREEN_TARIFFS";
  ElectricityInstruments["NA"] = "NA";
})(ElectricityInstruments || (exports.ElectricityInstruments = ElectricityInstruments = {}));
var StakeholderGroups;
(function (StakeholderGroups) {
  StakeholderGroups["COMMUNITIES"] = "COMMUNITIES";
  StakeholderGroups["INVESTORS"] = "INVESTORS";
  StakeholderGroups["SHAREHOLDERS"] = "SHAREHOLDERS";
  StakeholderGroups["EMPLOYEES_AND_WORKERS"] = "EMPLOYEES_AND_WORKERS";
  StakeholderGroups["CUSTOMERS"] = "CUSTOMERS";
  StakeholderGroups["VALUE_CHAIN_PARTNERS"] = "VALUE_CHAIN_PARTNERS";
  StakeholderGroups["OTHER"] = "Other";
})(StakeholderGroups || (exports.StakeholderGroups = StakeholderGroups = {}));
var AssessorTypes;
(function (AssessorTypes) {
  AssessorTypes["INTERNAL"] = "INTERNAL";
  AssessorTypes["STATUTORY_AUTHORITY"] = "STATUTORY_AUTHORITY";
  AssessorTypes["THIRD_PARTY"] = "THIRD_PARTY";
  AssessorTypes["MULTIPLE_ASSESSORS"] = "MULTIPLE_ASSESSORS";
})(AssessorTypes || (exports.AssessorTypes = AssessorTypes = {}));
var AreasAssessedTypes;
(function (AreasAssessedTypes) {
  AreasAssessedTypes["CHILD_LABOR"] = "CHILD_LABOR";
  AreasAssessedTypes["FORCED_INVOLUNTARY_LABOR"] = "FORCED_INVOLUNTARY_LABOR";
  AreasAssessedTypes["SEXUAL_HARASSMENT"] = "SEXUAL_HARASSMENT";
  AreasAssessedTypes["DISCRIMINATION_AT_WORKPLACE"] = "DISCRIMINATION_AT_WORKPLACE";
  AreasAssessedTypes["WAGES"] = "WAGES";
  AreasAssessedTypes["OTHER"] = "OTHER";
})(AreasAssessedTypes || (exports.AreasAssessedTypes = AreasAssessedTypes = {}));
var OpennessOfBusinessParameterTypes;
(function (OpennessOfBusinessParameterTypes) {
  OpennessOfBusinessParameterTypes["CONCENTRATION_OF_PURCHASES"] = "CONCENTRATION_OF_PURCHASES";
  OpennessOfBusinessParameterTypes["CONCENTRATION_OF_SALES"] = "CONCENTRATION_OF_SALES";
  OpennessOfBusinessParameterTypes["SHARE_OF_RPTS"] = "SHARE_OF_RPTS";
})(OpennessOfBusinessParameterTypes || (exports.OpennessOfBusinessParameterTypes = OpennessOfBusinessParameterTypes = {}));
var ConcentrationOfPurchases;
(function (ConcentrationOfPurchases) {
  ConcentrationOfPurchases["CONCENTRATION_OF_PURCHASES"] = "CONCENTRATION_OF_PURCHASES";
  ConcentrationOfPurchases["CONCENTRATION_OF_SALES"] = "CONCENTRATION_OF_SALES";
  ConcentrationOfPurchases["SHARE_OF_RPTS"] = "SHARE_OF_RPTS";
})(ConcentrationOfPurchases || (exports.ConcentrationOfPurchases = ConcentrationOfPurchases = {}));
var OpennessOfBusinessMetrics;
(function (OpennessOfBusinessMetrics) {
  OpennessOfBusinessMetrics["PURCHASES_FROM_TRADING_HOUSES_AS_PERCENT_OF_TOTAL_PURCHASES"] = "PURCHASES_FROM_TRADING_HOUSES_AS_PERCENT_OF_TOTAL_PURCHASES";
  OpennessOfBusinessMetrics["NUMBER_OF_TRADING_HOUSES_WHERE_PURCHASES_ARE_MADE_FROM"] = "NUMBER_OF_TRADING_HOUSES_WHERE_PURCHASES_ARE_MADE_FROM";
  OpennessOfBusinessMetrics["PURCHASES_FROM_TOP_10_TRADING_HOUSES_AS_PERCENT_OF_TOTAL_PURCHASES_FROM_TRADING_HOUSES"] = "PURCHASES_FROM_TOP_10_TRADING_HOUSES_AS_PERCENT_OF_TOTAL_PURCHASES_FROM_TRADING_HOUSES";
  OpennessOfBusinessMetrics["SALES_TO_DEALER_DISTRIBUTORS_AS_PERCENT_OF_TOTAL_SALE"] = "SALES_TO_DEALER_DISTRIBUTORS_AS_PERCENT_OF_TOTAL_SALE";
  OpennessOfBusinessMetrics["NUMBER_OF_DEALERS_DISTRIBUTORS_TO_WHOM_SALES_ARE_MADE"] = "NUMBER_OF_DEALERS_DISTRIBUTORS_TO_WHOM_SALES_ARE_MADE";
  OpennessOfBusinessMetrics["SALES_TO_TOP_10_DEALERS_DISTRIBUTORS_AS_PERCENT_OF_TOTAL_SALES_TO_DEALERS_DISTRIBUTORS"] = "SALES_TO_TOP_10_DEALERS_DISTRIBUTORS_AS_PERCENT_OF_TOTAL_SALES_TO_DEALERS_DISTRIBUTORS";
  OpennessOfBusinessMetrics["PURCHASES_WITH_RELATED_PARTIES_TOTAL_PURCHASES"] = "PURCHASES_WITH_RELATED_PARTIES_TOTAL_PURCHASES";
  OpennessOfBusinessMetrics["SALES_TO_RELATED_PARTIES_TOTAL_SALES"] = "SALES_TO_RELATED_PARTIES_TOTAL_SALES";
  OpennessOfBusinessMetrics["LOANS_AND_ADVANCES_GIVEN_TO_RELATED_PARTIES_TOTAL_LOANS_AND_ADVANCES"] = "LOANS_AND_ADVANCES_GIVEN_TO_RELATED_PARTIES_TOTAL_LOANS_AND_ADVANCES";
  OpennessOfBusinessMetrics["INVESTMENTS_IN_RELATED_PARTIES_TOTAL_INVESTMENTS_MADE"] = "INVESTMENTS_IN_RELATED_PARTIES_TOTAL_INVESTMENTS_MADE";
})(OpennessOfBusinessMetrics || (exports.OpennessOfBusinessMetrics = OpennessOfBusinessMetrics = {}));
var ValueTypes;
(function (ValueTypes) {
  ValueTypes["PERCENTAGE"] = "PERCENTAGE";
  ValueTypes["NUMBER"] = "NUMBER";
})(ValueTypes || (exports.ValueTypes = ValueTypes = {}));
var SupplierCateory;
(function (SupplierCateory) {
  SupplierCateory["MSMES_SMALL_PRODUCERS"] = "MSMES_SMALL_PRODUCERS";
  SupplierCateory["DIRECTLY_FROM_WITHIN_INDIA"] = "DIRECTLY_FROM_WITHIN_INDIA";
})(SupplierCateory || (exports.SupplierCateory = SupplierCateory = {}));
var ProductWasteType;
(function (ProductWasteType) {
  ProductWasteType["PLASTICS"] = "PLASTICS";
  ProductWasteType["E_WASTE"] = "E_WASTE";
  ProductWasteType["HAZARDOUS_WASTE"] = "HAZARDOUS_WASTE";
  ProductWasteType["OTHER_WASTE"] = "OTHER_WASTE";
})(ProductWasteType || (exports.ProductWasteType = ProductWasteType = {}));
var WorkforceType;
(function (WorkforceType) {
  WorkforceType["EMPLOYEES"] = "EMPLOYEES";
  WorkforceType["WORKERS"] = "WORKERS";
})(WorkforceType || (exports.WorkforceType = WorkforceType = {}));
var EmploymentStatus;
(function (EmploymentStatus) {
  EmploymentStatus["PERMANENT"] = "PERMANENT";
  EmploymentStatus["OTHER_THAN_PERMANENT"] = "OTHER_THAN_PERMANENT";
})(EmploymentStatus || (exports.EmploymentStatus = EmploymentStatus = {}));
var BenefitType;
(function (BenefitType) {
  BenefitType["HEALTH_INSURANCE"] = "HEALTH_INSURANCE";
  BenefitType["ACCIDENT_INSURANCE"] = "ACCIDENT_INSURANCE";
  BenefitType["MATERNITY_BENEFITS"] = "MATERNITY_BENEFITS";
  BenefitType["PATERNITY_BENEFITS"] = "PATERNITY_BENEFITS";
  BenefitType["DAY_CARE_FACILITIES"] = "DAY_CARE_FACILITIES";
})(BenefitType || (exports.BenefitType = BenefitType = {}));
var RecallTypeEnum;
(function (RecallTypeEnum) {
  RecallTypeEnum["VOLUNTARY_RECALL"] = "VOLUNTARY RECALL";
  RecallTypeEnum["FORCED_RECALL"] = "FORCED RECALL";
})(RecallTypeEnum || (exports.RecallTypeEnum = RecallTypeEnum = {}));
var LocationCategoryEnum;
(function (LocationCategoryEnum) {
  LocationCategoryEnum["RURAL"] = "RURAL";
  LocationCategoryEnum["SEMI_URBAN"] = "SEMI-URBAN";
  LocationCategoryEnum["URBAN"] = "URBAN";
  LocationCategoryEnum["METROPOLITAN"] = "METROPOLITAN";
})(LocationCategoryEnum || (exports.LocationCategoryEnum = LocationCategoryEnum = {}));
var TypeOfBenefitPlan;
(function (TypeOfBenefitPlan) {
  TypeOfBenefitPlan["PF"] = "PF";
  TypeOfBenefitPlan["GRATUITY"] = "GRATUITY";
  TypeOfBenefitPlan["ESI"] = "ESI";
  TypeOfBenefitPlan["HEALTH_INSURANCE"] = "HEALTH_INSURANCE";
  TypeOfBenefitPlan["LIFE_INSURANCE"] = "LIFE_INSURANCE";
  TypeOfBenefitPlan["ACCIDENT_INSURANCE"] = "ACCIDENT_INSURANCE";
  TypeOfBenefitPlan["DISABILITY_BENEFITS"] = "DISABILITY_BENEFITS";
  TypeOfBenefitPlan["MATERNITY_BENEFITS"] = "MATERNITY_BENEFITS";
  TypeOfBenefitPlan["PATERNITY_BENEFITS"] = "PATERNITY_BENEFITS";
  TypeOfBenefitPlan["PENSION_PLAN"] = "PENSION_PLAN";
  TypeOfBenefitPlan["STOCK_OPTIONS"] = "STOCK_OPTIONS";
  TypeOfBenefitPlan["EDUCATION_ASSISTANCE"] = "EDUCATION_ASSISTANCE";
  TypeOfBenefitPlan["HOUSING_ALLOWANCE"] = "HOUSING_ALLOWANCE";
  TypeOfBenefitPlan["TRANSPORTATION_ALLOWANCE"] = "TRANSPORTATION_ALLOWANCE";
  TypeOfBenefitPlan["MEAL_ALLOWANCE"] = "MEAL_ALLOWANCE";
  TypeOfBenefitPlan["OTHER"] = "OTHER";
})(TypeOfBenefitPlan || (exports.TypeOfBenefitPlan = TypeOfBenefitPlan = {}));
var PlanType;
(function (PlanType) {
  PlanType["DEFINED_BENEFIT"] = "DEFINED_BENEFIT";
  PlanType["DEFINED_CONTRIBUTION"] = "DEFINED_CONTRIBUTION";
  PlanType["MIXED"] = "MIXED";
  PlanType["OTHER"] = "OTHER";
})(PlanType || (exports.PlanType = PlanType = {}));
var CategoryType;
(function (CategoryType) {
  CategoryType["PERMANENT_EMPLOYEES"] = "PERMANENT_EMPLOYEES";
  CategoryType["OTHER_EMPLOYEES"] = "OTHER_EMPLOYEES";
  CategoryType["PERMANENT_WORKERS"] = "PERMANENT_WORKERS";
  CategoryType["OTHER_WORKERS"] = "OTHER_WORKERS";
})(CategoryType || (exports.CategoryType = CategoryType = {}));
var FundingStatus;
(function (FundingStatus) {
  FundingStatus["FULLY_FUNDED"] = "FULLY_FUNDED";
  FundingStatus["PARTIALLY_FUNDED"] = "PARTIALLY_FUNDED";
  FundingStatus["UNFUNDED"] = "UNFUNDED";
})(FundingStatus || (exports.FundingStatus = FundingStatus = {}));
var FundingMethod;
(function (FundingMethod) {
  FundingMethod["YES"] = "YES";
  FundingMethod["NO"] = "NO";
  FundingMethod["NOT_APPLICABLE"] = "NOT_APPLICABLE";
})(FundingMethod || (exports.FundingMethod = FundingMethod = {}));
var WagePeriod;
(function (WagePeriod) {
  WagePeriod["HOURLY"] = "HOURLY";
  WagePeriod["DAILY"] = "DAILY";
  WagePeriod["WEEKLY"] = "WEEKLY";
  WagePeriod["MONTHLY"] = "MONTHLY";
})(WagePeriod || (exports.WagePeriod = WagePeriod = {}));
var WageWorkerCategory;
(function (WageWorkerCategory) {
  WageWorkerCategory["PERMANENT_EMPLOYEES"] = "PERMANENT_EMPLOYEES";
  WageWorkerCategory["OTHER_EMPLOYEES"] = "OTHER_EMPLOYEES";
  WageWorkerCategory["PERMANENT_WORKERS"] = "PERMANENT_WORKERS";
  WageWorkerCategory["OTHER_WORKERS"] = "OTHER_WORKERS";
})(WageWorkerCategory || (exports.WageWorkerCategory = WageWorkerCategory = {}));
var ActivityTypes;
(function (ActivityTypes) {
  ActivityTypes["GOVERNANCE_BODY_MEMBERS"] = "GOVERNANCE_BODY_MEMBERS";
  ActivityTypes["COMMUNICATION"] = "COMMUNICATION";
  ActivityTypes["TRAINING"] = "TRAINING";
})(ActivityTypes || (exports.ActivityTypes = ActivityTypes = {}));
var FormatTypes;
(function (FormatTypes) {
  FormatTypes["IN_PERSON"] = "IN_PERSON";
  FormatTypes["ONLINE"] = "ONLINE";
  FormatTypes["HYBRID"] = "HYBRID";
  FormatTypes["WRITTEN_MATERIALS"] = "WRITTEN_MATERIALS";
})(FormatTypes || (exports.FormatTypes = FormatTypes = {}));
var CorruptionTypes;
(function (CorruptionTypes) {
  CorruptionTypes["BRIBERY"] = "BRIBERY";
  CorruptionTypes["FRAUD"] = "FRAUD";
  CorruptionTypes["EMBEZZLEMENT"] = "EMBEZZLEMENT";
  CorruptionTypes["EXTORTION"] = "EXTORTION";
  CorruptionTypes["MONEY_LAUNDERING"] = "MONEY_LAUNDERING";
  CorruptionTypes["CONFLICT_OF_INTEREST"] = "CONFLICT_OF_INTEREST";
  CorruptionTypes["OTHER"] = "OTHER";
})(CorruptionTypes || (exports.CorruptionTypes = CorruptionTypes = {}));
var IncidentStatusTypes;
(function (IncidentStatusTypes) {
  IncidentStatusTypes["UNDER_INVESTIGATION"] = "UNDER_INVESTIGATION";
  IncidentStatusTypes["CONFIRMED"] = "CONFIRMED";
  IncidentStatusTypes["DISMISSED"] = "DISMISSED";
  IncidentStatusTypes["RESOLVED"] = "RESOLVED";
})(IncidentStatusTypes || (exports.IncidentStatusTypes = IncidentStatusTypes = {}));
var IndividualCategoryTypes;
(function (IndividualCategoryTypes) {
  IndividualCategoryTypes["BOARD_OF_DIRECTORS"] = "BOARD_OF_DIRECTORS";
  IndividualCategoryTypes["KEY_MANAGERIAL_PERSONNEL"] = "KEY_MANAGERIAL_PERSONNEL";
  IndividualCategoryTypes["PERMANENT_EMPLOYEES"] = "PERMANENT_EMPLOYEES";
  IndividualCategoryTypes["OTHER_EMPLOYEES"] = "OTHER_EMPLOYEES";
  IndividualCategoryTypes["PERMANENT_WORKER"] = "PERMANENT_WORKER";
  IndividualCategoryTypes["OTHER_WORKERS"] = "OTHER_WORKERS";
  IndividualCategoryTypes["BUSINESS_PARTNERS"] = "BUSINESS_PARTNERS";
})(IndividualCategoryTypes || (exports.IndividualCategoryTypes = IndividualCategoryTypes = {}));
var ActionTakenTypes;
(function (ActionTakenTypes) {
  ActionTakenTypes["WARNING"] = "WARNING";
  ActionTakenTypes["SUSPENSION"] = "SUSPENSION";
  ActionTakenTypes["TERMINATION"] = "TERMINATION";
  ActionTakenTypes["LEGAL_ACTION"] = "LEGAL_ACTION";
  ActionTakenTypes["CONTRACT_TERMINATION"] = "CONTRACT_TERMINATION";
  ActionTakenTypes["OTHER"] = "OTHER";
})(ActionTakenTypes || (exports.ActionTakenTypes = ActionTakenTypes = {}));
var EmploymentWorkerCategory;
(function (EmploymentWorkerCategory) {
  EmploymentWorkerCategory["PERMANENT_EMPLOYEES"] = "PERMANENT_EMPLOYEES";
  EmploymentWorkerCategory["OTHER_EMPLOYEES"] = "OTHER_EMPLOYEES";
  EmploymentWorkerCategory["PERMANENT_WORKERS"] = "PERMANENT_WORKERS";
  EmploymentWorkerCategory["OTHER_WORKERS"] = "OTHER_WORKERS";
})(EmploymentWorkerCategory || (exports.EmploymentWorkerCategory = EmploymentWorkerCategory = {}));
var LeaveType;
(function (LeaveType) {
  LeaveType["MATERNITY"] = "MATERNITY";
  LeaveType["PATERNITY"] = "PATERNITY";
  LeaveType["ADOPTION"] = "ADOPTION";
  LeaveType["OTHER"] = "OTHER";
})(LeaveType || (exports.LeaveType = LeaveType = {}));
var TrainingCategoryEnum;
(function (TrainingCategoryEnum) {
  TrainingCategoryEnum["BOARD_OF_DIRECTORS"] = "BOARD_OF_DIRECTORS";
  TrainingCategoryEnum["KEY_MANAGERIAL_PERSONNEL"] = "KEY_MANAGERIAL_PERSONNEL";
  TrainingCategoryEnum["PERMANENT_EMPLOYEES"] = "PERMANENT_EMPLOYEES";
  TrainingCategoryEnum["OTHER_EMPLOYEES"] = "OTHER_EMPLOYEES";
  TrainingCategoryEnum["PERMANENT_WORKERS"] = "PERMANENT_WORKERS";
  TrainingCategoryEnum["OTHER_WORKERS"] = "OTHER_WORKERS";
})(TrainingCategoryEnum || (exports.TrainingCategoryEnum = TrainingCategoryEnum = {}));
var TrainingTypeEnum;
(function (TrainingTypeEnum) {
  TrainingTypeEnum["SKILL_UPGRADATION"] = "SKILL_UPGRADATION";
  TrainingTypeEnum["HEALTH_AND_SAFETY"] = "HEALTH_AND_SAFETY";
  TrainingTypeEnum["CODE_OF_CONDUCT"] = "CODE_OF_CONDUCT";
  TrainingTypeEnum["HUMAN_RIGHTS"] = "HUMAN_RIGHTS";
  TrainingTypeEnum["POSH"] = "POSH";
  TrainingTypeEnum["ANTI_CORRUPTION"] = "ANTI_CORRUPTION";
  TrainingTypeEnum["ESG_SUSTAINABILITY"] = "ESG_SUSTAINABILITY";
  TrainingTypeEnum["OTHER"] = "OTHER";
})(TrainingTypeEnum || (exports.TrainingTypeEnum = TrainingTypeEnum = {}));
var ProgramTypeEnum;
(function (ProgramTypeEnum) {
  ProgramTypeEnum["SKILL_UPGRADATION"] = "SKILL_UPGRADATION";
  ProgramTypeEnum["CAREER_DEVELOPMENT"] = "CAREER_DEVELOPMENT";
  ProgramTypeEnum["HEALTH_AND_SAFETY"] = "HEALTH_AND_SAFETY";
  ProgramTypeEnum["CODE_OF_CONDUCT"] = "CODE_OF_CONDUCT";
  ProgramTypeEnum["HUMAN_RIGHTS"] = "HUMAN_RIGHTS";
  ProgramTypeEnum["POSH"] = "POSH";
  ProgramTypeEnum["ANTI_CORRUPTION"] = "ANTI_CORRUPTION";
  ProgramTypeEnum["ESG_SUSTAINABILITY"] = "ESG_SUSTAINABILITY";
  ProgramTypeEnum["TRANSITION_ASSISTANCE"] = "TRANSITION_ASSISTANCE";
  ProgramTypeEnum["RETIREMENT_PLANNING"] = "RETIREMENT_PLANNING";
  ProgramTypeEnum["RETRAINING"] = "RETRAINING";
  ProgramTypeEnum["OUTPLACEMENT_SERVICES"] = "OUTPLACEMENT_SERVICES";
  ProgramTypeEnum["OTHER"] = "OTHER";
})(ProgramTypeEnum || (exports.ProgramTypeEnum = ProgramTypeEnum = {}));
var TargetAudienceEnum;
(function (TargetAudienceEnum) {
  TargetAudienceEnum["BOARD_OF_DIRECTORS"] = "BOARD_OF_DIRECTORS";
  TargetAudienceEnum["KEY_MANAGERIAL_PERSONNEL"] = "KEY_MANAGERIAL_PERSONNEL";
  TargetAudienceEnum["PERMANENT_EMPLOYEES"] = "PERMANENT_EMPLOYEES";
  TargetAudienceEnum["OTHER_EMPLOYEES"] = "OTHER_EMPLOYEES";
  TargetAudienceEnum["PERMANENT_WORKERS"] = "PERMANENT_WORKERS";
  TargetAudienceEnum["OTHER_WORKERS"] = "OTHER_WORKERS";
})(TargetAudienceEnum || (exports.TargetAudienceEnum = TargetAudienceEnum = {}));
var FrequencyEnum;
(function (FrequencyEnum) {
  FrequencyEnum["ONE_TIME"] = "ONE_TIME";
  FrequencyEnum["ANNUAL"] = "ANNUAL";
  FrequencyEnum["QUARTERLY"] = "QUARTERLY";
  FrequencyEnum["MONTHLY"] = "MONTHLY";
  FrequencyEnum["WEEKLY"] = "WEEKLY";
  FrequencyEnum["ONGOING"] = "ONGOING";
})(FrequencyEnum || (exports.FrequencyEnum = FrequencyEnum = {}));
var InternalExternalEnum;
(function (InternalExternalEnum) {
  InternalExternalEnum["INTERNAL"] = "INTERNAL";
  InternalExternalEnum["EXTERNAL"] = "EXTERNAL";
  InternalExternalEnum["MIXED"] = "MIXED";
})(InternalExternalEnum || (exports.InternalExternalEnum = InternalExternalEnum = {}));
var ReviewTypeEnum;
(function (ReviewTypeEnum) {
  ReviewTypeEnum["PERFORMANCE_APPRAISAL"] = "PERFORMANCE_APPRAISAL";
  ReviewTypeEnum["CAREER_DEVELOPMENT"] = "CAREER_DEVELOPMENT";
  ReviewTypeEnum["COMPETENCY_ASSESSMENT"] = "COMPETENCY_ASSESSMENT";
  ReviewTypeEnum["SKILLS_GAP_ANALYSIS"] = "SKILLS_GAP_ANALYSIS";
  ReviewTypeEnum["DEGREE_FEEDBACK_360"] = "DEGREE_FEEDBACK_360";
  ReviewTypeEnum["GOAL_SETTING"] = "GOAL_SETTING";
  ReviewTypeEnum["PROMOTION_REVIEW"] = "PROMOTION_REVIEW";
  ReviewTypeEnum["OTHER"] = "OTHER";
})(ReviewTypeEnum || (exports.ReviewTypeEnum = ReviewTypeEnum = {}));
var ReviewFrequencyEnum;
(function (ReviewFrequencyEnum) {
  ReviewFrequencyEnum["ANNUAL"] = "ANNUAL";
  ReviewFrequencyEnum["BI_ANNUAL"] = "BI_ANNUAL";
  ReviewFrequencyEnum["QUARTERLY"] = "QUARTERLY";
  ReviewFrequencyEnum["MONTHLY"] = "MONTHLY";
  ReviewFrequencyEnum["AS_NEEDED"] = "AS_NEEDED";
})(ReviewFrequencyEnum || (exports.ReviewFrequencyEnum = ReviewFrequencyEnum = {}));
var ReviewMethodEnum;
(function (ReviewMethodEnum) {
  ReviewMethodEnum["FACE_TO_FACE"] = "FACE_TO_FACE";
  ReviewMethodEnum["DIGITAL_FORM"] = "DIGITAL_FORM";
  ReviewMethodEnum["COMBINATION"] = "COMBINATION";
  ReviewMethodEnum["OTHER"] = "OTHER";
})(ReviewMethodEnum || (exports.ReviewMethodEnum = ReviewMethodEnum = {}));
var EntityTypeEnum;
(function (EntityTypeEnum) {
  EntityTypeEnum["HOLDING_COMPANY"] = "HOLDING_COMPANY";
  EntityTypeEnum["SUBSIDIARY"] = "SUBSIDIARY";
  EntityTypeEnum["ASSOCIATE"] = "ASSOCIATE";
  EntityTypeEnum["JOINT_VENTURE"] = "JOINT_VENTURE";
  EntityTypeEnum["OTHER"] = "OTHER";
})(EntityTypeEnum || (exports.EntityTypeEnum = EntityTypeEnum = {}));
var NGRBC_PRINCIPLE_ENUM;
(function (NGRBC_PRINCIPLE_ENUM) {
  NGRBC_PRINCIPLE_ENUM["P1"] = "P1_ETHICS_TRANSPARENCY_ACCOUNTABILITY";
  NGRBC_PRINCIPLE_ENUM["P2"] = "P2_SAFETY_SUSTAINABILITY";
  NGRBC_PRINCIPLE_ENUM["P3"] = "P3_EMPLOYEE_WELLBEING";
  NGRBC_PRINCIPLE_ENUM["P4"] = "P4_STAKEHOLDER_ENGAGEMENT";
  NGRBC_PRINCIPLE_ENUM["P5"] = "P5_HUMAN_RIGHTS";
  NGRBC_PRINCIPLE_ENUM["P6"] = "P6_ENVIRONMENT";
  NGRBC_PRINCIPLE_ENUM["P7"] = "P7_POLICY_ADVOCACY";
  NGRBC_PRINCIPLE_ENUM["P8"] = "P8_INCLUSIVE_GROWTH";
  NGRBC_PRINCIPLE_ENUM["P9"] = "P9_CUSTOMER_VALUE";
  NGRBC_PRINCIPLE_ENUM["N_A"] = "N_A";
})(NGRBC_PRINCIPLE_ENUM || (exports.NGRBC_PRINCIPLE_ENUM = NGRBC_PRINCIPLE_ENUM = {}));
var COMPLIANCE_TYPE_ENUM;
(function (COMPLIANCE_TYPE_ENUM) {
  COMPLIANCE_TYPE_ENUM["MONETARY_FINE"] = "MONETARY_FINE";
  COMPLIANCE_TYPE_ENUM["SETTLEMENT"] = "SETTLEMENT";
  COMPLIANCE_TYPE_ENUM["COMPOUNDING_FEE"] = "COMPOUNDING_FEE";
  COMPLIANCE_TYPE_ENUM["IMPRISONMENT"] = "IMPRISONMENT";
  COMPLIANCE_TYPE_ENUM["PUNISHMENT"] = "PUNISHMENT";
  COMPLIANCE_TYPE_ENUM["WARNING"] = "WARNING";
  COMPLIANCE_TYPE_ENUM["ORDER_TO_COMPLY"] = "ORDER_TO_COMPLY";
})(COMPLIANCE_TYPE_ENUM || (exports.COMPLIANCE_TYPE_ENUM = COMPLIANCE_TYPE_ENUM = {}));
var STATUS_ENUM;
(function (STATUS_ENUM) {
  STATUS_ENUM["UNDER_INVESTIGATION"] = "UNDER_INVESTIGATION";
  STATUS_ENUM["IN_PROGRESS"] = "IN_PROGRESS";
  STATUS_ENUM["RESOLVED"] = "RESOLVED";
  STATUS_ENUM["APPEAL_FILED"] = "APPEAL_FILED";
  STATUS_ENUM["DISMISSED"] = "DISMISSED";
})(STATUS_ENUM || (exports.STATUS_ENUM = STATUS_ENUM = {}));
var ngrbcPrincipleEnum;
(function (ngrbcPrincipleEnum) {
  ngrbcPrincipleEnum["P1"] = "P1_ETHICS_TRANSPARENCY_ACCOUNTABILITY";
  ngrbcPrincipleEnum["P2"] = "P2_SAFETY_SUSTAINABILITY";
  ngrbcPrincipleEnum["P3"] = "P3_EMPLOYEE_WELLBEING";
  ngrbcPrincipleEnum["P4"] = "P4_STAKEHOLDER_ENGAGEMENT";
  ngrbcPrincipleEnum["P5"] = "P5_HUMAN_RIGHTS";
  ngrbcPrincipleEnum["P6"] = "P6_ENVIRONMENT";
  ngrbcPrincipleEnum["P7"] = "P7_POLICY_ADVOCACY";
  ngrbcPrincipleEnum["P8"] = "P8_INCLUSIVE_GROWTH";
  ngrbcPrincipleEnum["P9"] = "P9_CUSTOMER_VALUE";
  ngrbcPrincipleEnum["N_A"] = "N_A";
})(ngrbcPrincipleEnum || (exports.ngrbcPrincipleEnum = ngrbcPrincipleEnum = {}));
var complianceTypeEnum;
(function (complianceTypeEnum) {
  complianceTypeEnum["MONETARY_FINE"] = "MONETARY_FINE";
  complianceTypeEnum["SETTLEMENT"] = "SETTLEMENT";
  complianceTypeEnum["COMPOUNDING_FEE"] = "COMPOUNDING_FEE";
  complianceTypeEnum["IMPRISONMENT"] = "IMPRISONMENT";
  complianceTypeEnum["PUNISHMENT"] = "PUNISHMENT";
  complianceTypeEnum["WARNING"] = "WARNING";
  complianceTypeEnum["ORDER_TO_COMPLY"] = "ORDER_TO_COMPLY";
})(complianceTypeEnum || (exports.complianceTypeEnum = complianceTypeEnum = {}));
var statusEnum;
(function (statusEnum) {
  statusEnum["UNDER_INVESTIGATION"] = "UNDER_INVESTIGATION";
  statusEnum["IN_PROGRESS"] = "IN_PROGRESS";
  statusEnum["RESOLVED"] = "RESOLVED";
  statusEnum["APPEAL_FILED"] = "APPEAL_FILED";
  statusEnum["DISMISSED"] = "DISMISSED";
})(statusEnum || (exports.statusEnum = statusEnum = {}));
var StakeholderGroupEnum;
(function (StakeholderGroupEnum) {
  StakeholderGroupEnum["COMMUNITIES"] = "COMMUNITIES";
  StakeholderGroupEnum["INVESTORS"] = "INVESTORS";
  StakeholderGroupEnum["SHAREHOLDERS"] = "SHAREHOLDERS";
  StakeholderGroupEnum["EMPLOYEES"] = "EMPLOYEES";
  StakeholderGroupEnum["WORKERS"] = "WORKERS";
  StakeholderGroupEnum["CUSTOMERS"] = "CUSTOMERS";
  StakeholderGroupEnum["VALUE_CHAIN_PARTNERS"] = "VALUE_CHAIN_PARTNERS";
  StakeholderGroupEnum["OTHER"] = "OTHER";
})(StakeholderGroupEnum || (exports.StakeholderGroupEnum = StakeholderGroupEnum = {}));
var AssociationTypeEnum;
(function (AssociationTypeEnum) {
  AssociationTypeEnum["industryAssociation"] = "INDUSTRY_ASSOCIATION";
  AssociationTypeEnum["tradeChamber"] = "TRADE_CHAMBER";
  AssociationTypeEnum["professionalBody"] = "PROFESSIONAL_BODY";
  AssociationTypeEnum["sustainabilityInitiative"] = "SUSTAINABILITY_INITIATIVE";
  AssociationTypeEnum["other"] = "OTHER";
})(AssociationTypeEnum || (exports.AssociationTypeEnum = AssociationTypeEnum = {}));
var ReachEnum;
(function (ReachEnum) {
  ReachEnum["state"] = "STATE";
  ReachEnum["national"] = "NATIONAL";
  ReachEnum["international"] = "INTERNATIONAL";
})(ReachEnum || (exports.ReachEnum = ReachEnum = {}));
