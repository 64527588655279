"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.LeasedEnergyUnit = exports.LeasedCoolingUnit = exports.LeasedHeatingUnit = exports.LeasedSteamUnit = exports.LeasedElectricityUnit = exports.LeasedFuelUnit = exports.FuelEnergyUnit = exports.WasteUnits = exports.UpstremStoredUnit = exports.UpstreamSolidUnit = exports.HeatSteamUnit = exports.YesNoNA = exports.HeatSteamCoolingUnit = exports.HeatSteamHeatingUnit = exports.defaultUnits = exports.DensityInputUnit = exports.EnergyOutput = exports.ImpactType = exports.SizeUnits = exports.TypeOfOperation = exports.Beneficiaries = exports.InvestmentTypes = exports.ProjectStatus = exports.PositiveNegative = exports.investmentCategory = exports.EnergyUnit = exports.EnergyIntensityPerRevenue = exports.EnergyIntensityPerProduct = exports.EnergyIntensityPerEmployee = exports.EnergyIntensityPerFloorArea = exports.EnergyIntensityOptionalHours = exports.EnergyIntensityServicesProvided = exports.EnergyIntensityNumberOfEmployee = exports.EnergyIntensitySize = exports.EnergyIntensityProductionVolume = exports.EnergySteamConsumptionUnit = exports.EnergyCoolingConsumptionUnit = exports.EnergyHeatingConsumptionUnit = exports.FuelLiquidUnit = exports.BusinessTravelDistanceUnit = exports.VehicleUnit = exports.Currency = exports.DistanceUnit = exports.HeatUnit = exports.ElectricPowerUnit = exports.ElectricUnit = exports.GasUnit = exports.LiquidUnit = exports.SolidUnit = exports.ConsumptionType = void 0;
exports.productAndPackagingEndOfLifeMassVolumeUnit = exports.MassAndVolumeUnits = exports.EnergyIntensity = exports.EnergyIntensityDistanceTraveled = exports.EnergyIntensityMonetaryUnits = exports.SolidUnitWithProductionVolume = exports.EnergyConsumptionUnit = exports.FuelConsumptionUnit = exports.MobileCombustionUnit = exports.StationaryCombustionUnit = exports.ConsumptionUnitMap = exports.ConsumptionUnitTypeMap = exports.EnergyUnitCategoryMap = exports.PackagingUnits = exports.ProductsSoldUnits = exports.EnergyUnitCategory = exports.EnergyElectricUnit = exports.EnergyGasUnit = exports.EnergyLiquidUnit = exports.EnergySolidUnit = exports.UseOfSoldElectricityUnit = exports.UseOfSoldMassVolumeUnit = exports.EndOfLifeMassVolumeUnit = exports.ProcessingElectricityQuantityUnit = exports.ProcessingMassVolumeUnit = void 0;
exports.unitToUnitCode = unitToUnitCode;
exports.codeToUnit = codeToUnit;
var ConsumptionType;
(function (ConsumptionType) {
  ConsumptionType["FUEL_SOLID"] = "SOLID";
  ConsumptionType["FUEL_LIQUID"] = "LIQUID";
  ConsumptionType["FUEL_GAS"] = "GAS";
  ConsumptionType["FUEL_ELECTRIC"] = "ELECTRIC";
  ConsumptionType["ELECTRICITY"] = "ELECTRICITY";
  ConsumptionType["HEAT_STEAM_GAS"] = "HEAT_STEAM_GAS";
  ConsumptionType["DISTANCE"] = "DISTANCE";
  ConsumptionType["CURRENCY"] = "CURRENCY";
  ConsumptionType["VEHICLE"] = "VEHICLE";
  ConsumptionType["ENERGY"] = "ENERGY";
  ConsumptionType["HEATING"] = "HEATING";
  ConsumptionType["COOLING"] = "COOLING";
  ConsumptionType["STEAM"] = "STEAM";
})(ConsumptionType || (exports.ConsumptionType = ConsumptionType = {}));
var SolidUnit;
(function (SolidUnit) {
  SolidUnit["POUND"] = "lb";
  SolidUnit["SHORT_TON"] = "ST";
  SolidUnit["METRIC_TON"] = "MT";
  SolidUnit["LONG_TON"] = "LT";
  SolidUnit["KG"] = "kg";
  SolidUnit["GRAM"] = "g";
})(SolidUnit || (exports.SolidUnit = SolidUnit = {}));
var LiquidUnit;
(function (LiquidUnit) {
  LiquidUnit["GALLON"] = "gal";
  LiquidUnit["LITTER"] = "L";
})(LiquidUnit || (exports.LiquidUnit = LiquidUnit = {}));
var GasUnit;
(function (GasUnit) {
  GasUnit["STANDARD_CUBIC_FOOT"] = "scf";
})(GasUnit || (exports.GasUnit = GasUnit = {}));
var ElectricUnit;
(function (ElectricUnit) {
  ElectricUnit["KILOWATT_HOUR"] = "kWh";
})(ElectricUnit || (exports.ElectricUnit = ElectricUnit = {}));
var ElectricPowerUnit;
(function (ElectricPowerUnit) {
  ElectricPowerUnit["KILOWATT_HOUR"] = "kWh";
  ElectricPowerUnit["MEGAWATT_HOUR"] = "MWh";
  ElectricPowerUnit["GIGAWATT_HOUR"] = "GWh";
})(ElectricPowerUnit || (exports.ElectricPowerUnit = ElectricPowerUnit = {}));
var HeatUnit;
(function (HeatUnit) {
  HeatUnit["MEGAJOULE"] = "MJ";
  HeatUnit["GIGAJOULE"] = "GJ";
  HeatUnit["MMBTU"] = "MMBtu";
})(HeatUnit || (exports.HeatUnit = HeatUnit = {}));
var DistanceUnit;
(function (DistanceUnit) {
  DistanceUnit["KILOMETER"] = "km";
  DistanceUnit["MILE"] = "mi";
  DistanceUnit["NAUTICAL_MILE"] = "NM";
})(DistanceUnit || (exports.DistanceUnit = DistanceUnit = {}));
var Currency;
(function (Currency) {
  Currency["USD"] = "USD";
  Currency["INR"] = "INR";
  Currency["EUR"] = "EUR";
  Currency["GBP"] = "GBP";
  Currency["JPY"] = "JPY";
  Currency["AUD"] = "AUD";
  Currency["CNY"] = "CNY";
  Currency["CAD"] = "CAD";
  Currency["CHF"] = "CHF";
  Currency["BRL"] = "BRL";
})(Currency || (exports.Currency = Currency = {}));
var VehicleUnit;
(function (VehicleUnit) {
  VehicleUnit["KILOMETER_PER_LITTER"] = "km/l";
  VehicleUnit["MILE_PER_GALLON"] = "MPG";
})(VehicleUnit || (exports.VehicleUnit = VehicleUnit = {}));
var BusinessTravelDistanceUnit;
(function (BusinessTravelDistanceUnit) {
  BusinessTravelDistanceUnit["KILOMETER"] = "km";
  BusinessTravelDistanceUnit["MILE"] = "mi";
  BusinessTravelDistanceUnit["NAUTICAL_MILE"] = "NM";
  BusinessTravelDistanceUnit["VEHICLE_KILOMETER"] = "vkm";
  BusinessTravelDistanceUnit["PASSENGER_KILOMETER"] = "pkm";
})(BusinessTravelDistanceUnit || (exports.BusinessTravelDistanceUnit = BusinessTravelDistanceUnit = {}));
var FuelLiquidUnit;
(function (FuelLiquidUnit) {
  FuelLiquidUnit["CUBIC_METER"] = "m3";
})(FuelLiquidUnit || (exports.FuelLiquidUnit = FuelLiquidUnit = {}));
exports.EnergyHeatingConsumptionUnit = {
  BRITISH_THERMAL_UNIT: 'BTU',
  THERM: 'thm',
  MILLION_BTU: HeatUnit.MMBTU
};
var EnergyCoolingConsumptionUnit;
(function (EnergyCoolingConsumptionUnit) {
  EnergyCoolingConsumptionUnit["TON_HOURS"] = "ton-hr";
})(EnergyCoolingConsumptionUnit || (exports.EnergyCoolingConsumptionUnit = EnergyCoolingConsumptionUnit = {}));
exports.EnergySteamConsumptionUnit = {
  POUND_OF_STEAM: 'lbs',
  KILOGRAM_OF_STEAM: SolidUnit.KG
};
var EnergyIntensityProductionVolume;
(function (EnergyIntensityProductionVolume) {
  EnergyIntensityProductionVolume["UNITS"] = "UNITS";
})(EnergyIntensityProductionVolume || (exports.EnergyIntensityProductionVolume = EnergyIntensityProductionVolume = {}));
var EnergyIntensitySize;
(function (EnergyIntensitySize) {
  EnergyIntensitySize["SQUARE_METER"] = "m2";
  EnergyIntensitySize["SQUARE_FEET"] = "ft2";
  EnergyIntensitySize["CUBIC_METER"] = "m3";
})(EnergyIntensitySize || (exports.EnergyIntensitySize = EnergyIntensitySize = {}));
var EnergyIntensityNumberOfEmployee;
(function (EnergyIntensityNumberOfEmployee) {
  EnergyIntensityNumberOfEmployee["EMPLOYEES"] = "EMPLOYEES";
})(EnergyIntensityNumberOfEmployee || (exports.EnergyIntensityNumberOfEmployee = EnergyIntensityNumberOfEmployee = {}));
var EnergyIntensityServicesProvided;
(function (EnergyIntensityServicesProvided) {
  EnergyIntensityServicesProvided["OPERATIONAL_HOURS"] = "OPERATIONAL_HOURS";
  EnergyIntensityServicesProvided["KILOMETER_TRAVELED"] = "KILOMETER_TRAVELED";
  EnergyIntensityServicesProvided["MILE_TRAVELED"] = "MILE_TRAVELED";
  EnergyIntensityServicesProvided["NAUTICAL_MILE_TRAVELED"] = "NAUTICAL_MILE_TRAVELED";
})(EnergyIntensityServicesProvided || (exports.EnergyIntensityServicesProvided = EnergyIntensityServicesProvided = {}));
var EnergyIntensityOptionalHours;
(function (EnergyIntensityOptionalHours) {
  EnergyIntensityOptionalHours["HOURS"] = "h";
})(EnergyIntensityOptionalHours || (exports.EnergyIntensityOptionalHours = EnergyIntensityOptionalHours = {}));
var EnergyIntensityPerFloorArea;
(function (EnergyIntensityPerFloorArea) {
  EnergyIntensityPerFloorArea["KILOJOULES_PER_SQUARE_METER"] = "kJ/m\u00B2";
})(EnergyIntensityPerFloorArea || (exports.EnergyIntensityPerFloorArea = EnergyIntensityPerFloorArea = {}));
var EnergyIntensityPerEmployee;
(function (EnergyIntensityPerEmployee) {
  EnergyIntensityPerEmployee["KILOJOULES_PER_EMPLOYEE"] = "kJ/Employee";
})(EnergyIntensityPerEmployee || (exports.EnergyIntensityPerEmployee = EnergyIntensityPerEmployee = {}));
var EnergyIntensityPerProduct;
(function (EnergyIntensityPerProduct) {
  EnergyIntensityPerProduct["KILOJOULES_PER_UNIT"] = "kJ/Unit";
})(EnergyIntensityPerProduct || (exports.EnergyIntensityPerProduct = EnergyIntensityPerProduct = {}));
var EnergyIntensityPerRevenue;
(function (EnergyIntensityPerRevenue) {
  EnergyIntensityPerRevenue["KILOJOULES_PER_DOLLAR"] = "kJ/Dollar";
})(EnergyIntensityPerRevenue || (exports.EnergyIntensityPerRevenue = EnergyIntensityPerRevenue = {}));
var EnergyUnit;
(function (EnergyUnit) {
  EnergyUnit["KILOJOULE"] = "kJ";
  EnergyUnit["MEGAJOULE"] = "MJ";
  EnergyUnit["GIGAJOULE"] = "GJ";
  EnergyUnit["CALORIE"] = "cal";
  EnergyUnit["KILOCALORIE"] = "kcal";
  EnergyUnit["WATTHOUR"] = "Wh";
  EnergyUnit["KILOWATT_HOUR"] = "kWh";
  EnergyUnit["MEGAWATT_HOUR"] = "MWh";
  EnergyUnit["GIGAWATT_HOUR"] = "GWh";
  EnergyUnit["BRITISH_THERMAL_UNIT"] = "BTU";
  EnergyUnit["THERM"] = "thm";
  EnergyUnit["MMBTU"] = "MMBtu";
  EnergyUnit["TON_HOURS"] = "ton-hr";
  EnergyUnit["POUND_OF_STEAM"] = "lbs";
  EnergyUnit["KG"] = "kg";
  EnergyUnit["JOULE"] = "J";
})(EnergyUnit || (exports.EnergyUnit = EnergyUnit = {}));
var investmentCategory;
(function (investmentCategory) {
  investmentCategory["PHYSICAL_INFRASTRUCTURE"] = "PHYSICAL_INFRASTRUCTURE";
  investmentCategory["SOCIAL_INFRASTRUCTURE"] = "SOCIAL_INFRASTRUCTURE";
  investmentCategory["ENVIRONMENTAL_INFRASTRUCTURE"] = "ENVIRONMENTAL_INFRASTRUCTURE";
})(investmentCategory || (exports.investmentCategory = investmentCategory = {}));
var PositiveNegative;
(function (PositiveNegative) {
  PositiveNegative["POSITIVE"] = "POSITIVE";
  PositiveNegative["NEGATIVE"] = "NEGATIVE";
})(PositiveNegative || (exports.PositiveNegative = PositiveNegative = {}));
var ProjectStatus;
(function (ProjectStatus) {
  ProjectStatus["PLANNED"] = "PLANNED";
  ProjectStatus["ONGOING"] = "ONGOING";
  ProjectStatus["COMPLETED"] = "COMPLETED";
})(ProjectStatus || (exports.ProjectStatus = ProjectStatus = {}));
var InvestmentTypes;
(function (InvestmentTypes) {
  InvestmentTypes["COMMERCIAL"] = "COMMERCIAL";
  InvestmentTypes["IN_KIND"] = "IN_KIND";
  InvestmentTypes["PRO_BONO"] = "PRO_BONO";
})(InvestmentTypes || (exports.InvestmentTypes = InvestmentTypes = {}));
var Beneficiaries;
(function (Beneficiaries) {
  Beneficiaries["LOCAL_COMMUNITY"] = "LOCAL_COMMUNITY";
  Beneficiaries["EMPLOYEES"] = "EMPLOYEES";
  Beneficiaries["SUPPLIERS"] = "SUPPLIERS";
  Beneficiaries["CUSTOMERS"] = "CUSTOMERS";
})(Beneficiaries || (exports.Beneficiaries = Beneficiaries = {}));
var TypeOfOperation;
(function (TypeOfOperation) {
  TypeOfOperation["SUPPLIER"] = "SUPPLIER";
  TypeOfOperation["FACTORY"] = "FACTORY";
})(TypeOfOperation || (exports.TypeOfOperation = TypeOfOperation = {}));
var SizeUnits;
(function (SizeUnits) {
  SizeUnits["KILOMETERS"] = "KILOMETERS";
  SizeUnits["BEDS"] = "BEDS";
  SizeUnits["MW"] = "MW";
  SizeUnits["TERMINALS"] = "TERMINALS";
  SizeUnits["SCHOOLS"] = "SCHOOLS";
})(SizeUnits || (exports.SizeUnits = SizeUnits = {}));
var ImpactType;
(function (ImpactType) {
  ImpactType["PRODUCTIVITY_CHANGE"] = "PRODUCTIVITY_CHANGE";
  ImpactType["ECONOMIC_DEVELOPMENT"] = "ECONOMIC_DEVELOPMENT";
  ImpactType["SOCIAL_ENVIRONMENTAL_CONDITION_CHANGE"] = "SOCIAL_ENVIRONMENTAL_CONDITION_CHANGE";
  ImpactType["PRODUCT_SERVICE_AVAILABILITY"] = "PRODUCT_SERVICE_AVAILABILITY";
  ImpactType["SKILL_ENHANCEMENT"] = "SKILL_ENHANCEMENT";
  ImpactType["JOB_CREATION"] = "JOB_CREATION";
  ImpactType["FOREIGN_INVESTMENT"] = "FOREIGN_INVESTMENT";
  ImpactType["OPERATIONAL_CHANGE"] = "OPERATIONAL_CHANGE";
  ImpactType["PRODUCT_SERVICE_USE_IMPACT"] = "PRODUCT_SERVICE_USE_IMPACT";
})(ImpactType || (exports.ImpactType = ImpactType = {}));
exports.EnergyOutput = {
  KILOJOULE: EnergyUnit.KILOJOULE,
  MEGAJOULE: EnergyUnit.MEGAJOULE,
  GIGAJOULE: EnergyUnit.GIGAJOULE,
  JOULE: EnergyUnit.JOULE
};
exports.DensityInputUnit = {
  GALLON: LiquidUnit.GALLON,
  LITTER: LiquidUnit.LITTER,
  CUBIC_METER: FuelLiquidUnit.CUBIC_METER
};
exports.defaultUnits = {
  SOLID: 'ST',
  LIQUID: 'gal',
  GAS: 'scf',
  ELECTRIC: 'kWh',
  ELECTRICITY: 'kWh',
  HEAT_STEAM_GAS: 'MMBtu',
  DISTANCE: 'mi',
  CURRENCY: 'USD'
};
var HeatSteamHeatingUnit;
(function (HeatSteamHeatingUnit) {
  HeatSteamHeatingUnit["BRITISH_THERMAL_UNIT"] = "BTU";
  HeatSteamHeatingUnit["THERM"] = "thm";
  HeatSteamHeatingUnit["CUBIC_FEET"] = "ft3";
  HeatSteamHeatingUnit["KILOWATT_HOUR"] = "kWh";
  HeatSteamHeatingUnit["GALLON"] = "gal";
  HeatSteamHeatingUnit["LITTER"] = "L";
  HeatSteamHeatingUnit["JOULE"] = "J";
  HeatSteamHeatingUnit["MEGAJOULE"] = "MJ";
  HeatSteamHeatingUnit["GIGAJOULE"] = "GJ";
})(HeatSteamHeatingUnit || (exports.HeatSteamHeatingUnit = HeatSteamHeatingUnit = {}));
var HeatSteamCoolingUnit;
(function (HeatSteamCoolingUnit) {
  HeatSteamCoolingUnit["TON"] = "TR";
  HeatSteamCoolingUnit["KILOWATT_HOUR"] = "kWh";
  HeatSteamCoolingUnit["BRITISH_THERMAL_UNIT"] = "BTU";
  HeatSteamCoolingUnit["MEGAJOULE"] = "MJ";
})(HeatSteamCoolingUnit || (exports.HeatSteamCoolingUnit = HeatSteamCoolingUnit = {}));
exports.YesNoNA = {
  YES: 'YES',
  NO: 'NO',
  NA: 'NA'
};
var HeatSteamUnit;
(function (HeatSteamUnit) {
  HeatSteamUnit["POUND"] = "lb";
  HeatSteamUnit["KG"] = "kg";
  HeatSteamUnit["BRITISH_THERMAL_UNIT"] = "BTU";
  HeatSteamUnit["KILOWATT_HOUR"] = "kWh";
  HeatSteamUnit["MEGAJOULE"] = "MJ";
  HeatSteamUnit["GIGAJOULE"] = "GJ";
})(HeatSteamUnit || (exports.HeatSteamUnit = HeatSteamUnit = {}));
var UpstreamSolidUnit;
(function (UpstreamSolidUnit) {
  UpstreamSolidUnit["KG"] = "kg";
  UpstreamSolidUnit["POUND"] = "lb";
  UpstreamSolidUnit["SHORT_TON"] = "ST";
  UpstreamSolidUnit["METRIC_TON"] = "MT";
  UpstreamSolidUnit["LONG_TON"] = "LT";
  UpstreamSolidUnit["GRAM"] = "g";
  UpstreamSolidUnit["CUBIC_METER"] = "m3";
  UpstreamSolidUnit["LITTER"] = "L";
})(UpstreamSolidUnit || (exports.UpstreamSolidUnit = UpstreamSolidUnit = {}));
var UpstremStoredUnit;
(function (UpstremStoredUnit) {
  UpstremStoredUnit["CUBIC_METER"] = "m3";
  UpstremStoredUnit["TWENTY_FOOT_EQUIVALENT_UNIT"] = "TEU";
  UpstremStoredUnit["PALLET"] = "pallet";
})(UpstremStoredUnit || (exports.UpstremStoredUnit = UpstremStoredUnit = {}));
var WasteUnits;
(function (WasteUnits) {
  WasteUnits["CUBIC_METER"] = "m3";
  WasteUnits["METRIC_TON"] = "MT";
})(WasteUnits || (exports.WasteUnits = WasteUnits = {}));
var FuelEnergyUnit;
(function (FuelEnergyUnit) {
  FuelEnergyUnit["KG"] = "m3";
  FuelEnergyUnit["POUND"] = "MT";
  FuelEnergyUnit["SHORT_TON"] = "ST";
  FuelEnergyUnit["METRIC_TON"] = "MT";
  FuelEnergyUnit["LONG_TON"] = "LT";
  FuelEnergyUnit["LITTER"] = "L";
  FuelEnergyUnit["GALLON"] = "gal";
  FuelEnergyUnit["STANDARD_CUBIC_FOOT"] = "scf";
})(FuelEnergyUnit || (exports.FuelEnergyUnit = FuelEnergyUnit = {}));
var LeasedFuelUnit;
(function (LeasedFuelUnit) {
  LeasedFuelUnit["KG"] = "m3";
  LeasedFuelUnit["POUND"] = "MT";
  LeasedFuelUnit["SHORT_TON"] = "ST";
  LeasedFuelUnit["METRIC_TON"] = "MT";
  LeasedFuelUnit["LONG_TON"] = "LT";
  LeasedFuelUnit["LITTER"] = "L";
  LeasedFuelUnit["GALLON"] = "gal";
  LeasedFuelUnit["STANDARD_CUBIC_FOOT"] = "scf";
})(LeasedFuelUnit || (exports.LeasedFuelUnit = LeasedFuelUnit = {}));
var LeasedElectricityUnit;
(function (LeasedElectricityUnit) {
  LeasedElectricityUnit["KILOWATT_HOUR"] = "kWh";
  LeasedElectricityUnit["MEGAWATT_HOUR"] = "MWh";
  LeasedElectricityUnit["GIGAWATT_HOUR"] = "GWh";
})(LeasedElectricityUnit || (exports.LeasedElectricityUnit = LeasedElectricityUnit = {}));
var LeasedSteamUnit;
(function (LeasedSteamUnit) {
  LeasedSteamUnit["POUND"] = "lb";
  LeasedSteamUnit["KG"] = "kg";
  LeasedSteamUnit["BRITISH_THERMAL_UNIT"] = "BTU";
  LeasedSteamUnit["KILOWATT_HOUR"] = "kWh";
  LeasedSteamUnit["MEGAJOULE"] = "MJ";
  LeasedSteamUnit["GIGAJOULE"] = "GJ";
})(LeasedSteamUnit || (exports.LeasedSteamUnit = LeasedSteamUnit = {}));
var LeasedHeatingUnit;
(function (LeasedHeatingUnit) {
  LeasedHeatingUnit["BRITISH_THERMAL_UNIT"] = "BTU";
  LeasedHeatingUnit["THERM"] = "thm";
  LeasedHeatingUnit["CUBIC_FEET"] = "ft3";
  LeasedHeatingUnit["KILOWATT_HOUR"] = "kWh";
  LeasedHeatingUnit["GALLON"] = "gal";
  LeasedHeatingUnit["LITTER"] = "L";
  LeasedHeatingUnit["JOULE"] = "J";
  LeasedHeatingUnit["MEGAJOULE"] = "MJ";
  LeasedHeatingUnit["GIGAJOULE"] = "GJ";
})(LeasedHeatingUnit || (exports.LeasedHeatingUnit = LeasedHeatingUnit = {}));
var LeasedCoolingUnit;
(function (LeasedCoolingUnit) {
  LeasedCoolingUnit["TON"] = "TR";
  LeasedCoolingUnit["KILOWATT_HOUR"] = "kWh";
  LeasedCoolingUnit["BRITISH_THERMAL_UNIT"] = "BTU";
  LeasedCoolingUnit["MEGAJOULE"] = "MJ";
})(LeasedCoolingUnit || (exports.LeasedCoolingUnit = LeasedCoolingUnit = {}));
var LeasedEnergyUnit;
(function (LeasedEnergyUnit) {
  LeasedEnergyUnit["KILOJOULE"] = "kJ";
  LeasedEnergyUnit["MEGAJOULE"] = "MJ";
  LeasedEnergyUnit["GIGAJOULE"] = "GJ";
  LeasedEnergyUnit["CALORIE"] = "cal";
  LeasedEnergyUnit["KILOCALORIE"] = "kcal";
  LeasedEnergyUnit["WATTHOUR"] = "Wh";
  LeasedEnergyUnit["KILOWATT_HOUR"] = "kWh";
  LeasedEnergyUnit["MEGAWATT_HOUR"] = "MWh";
  LeasedEnergyUnit["GIGAWATT_HOUR"] = "GWh";
  LeasedEnergyUnit["BRITISH_THERMAL_UNIT"] = "BTU";
  LeasedEnergyUnit["THERM"] = "thm";
  LeasedEnergyUnit["MMBTU"] = "MMBtu";
  LeasedEnergyUnit["TON_HOURS"] = "ton-hr";
  LeasedEnergyUnit["POUND_OF_STEAM"] = "lbs";
  LeasedEnergyUnit["KG"] = "kg";
  LeasedEnergyUnit["JOULE"] = "J";
})(LeasedEnergyUnit || (exports.LeasedEnergyUnit = LeasedEnergyUnit = {}));
var ProcessingMassVolumeUnit;
(function (ProcessingMassVolumeUnit) {
  ProcessingMassVolumeUnit["KG"] = "m3";
  ProcessingMassVolumeUnit["POUND"] = "MT";
  ProcessingMassVolumeUnit["SHORT_TON"] = "ST";
  ProcessingMassVolumeUnit["METRIC_TON"] = "MT";
  ProcessingMassVolumeUnit["LONG_TON"] = "LT";
  ProcessingMassVolumeUnit["LITTER"] = "L";
  ProcessingMassVolumeUnit["GALLON"] = "gal";
  ProcessingMassVolumeUnit["STANDARD_CUBIC_FOOT"] = "scf";
})(ProcessingMassVolumeUnit || (exports.ProcessingMassVolumeUnit = ProcessingMassVolumeUnit = {}));
var ProcessingElectricityQuantityUnit;
(function (ProcessingElectricityQuantityUnit) {
  ProcessingElectricityQuantityUnit["KILOWATT_HOUR"] = "kWh";
  ProcessingElectricityQuantityUnit["MEGAWATT_HOUR"] = "MWh";
  ProcessingElectricityQuantityUnit["GIGAWATT_HOUR"] = "GWh";
})(ProcessingElectricityQuantityUnit || (exports.ProcessingElectricityQuantityUnit = ProcessingElectricityQuantityUnit = {}));
var EndOfLifeMassVolumeUnit;
(function (EndOfLifeMassVolumeUnit) {
  EndOfLifeMassVolumeUnit["KG"] = "m3";
  EndOfLifeMassVolumeUnit["POUND"] = "MT";
  EndOfLifeMassVolumeUnit["SHORT_TON"] = "ST";
  EndOfLifeMassVolumeUnit["METRIC_TON"] = "MT";
  EndOfLifeMassVolumeUnit["LONG_TON"] = "LT";
  EndOfLifeMassVolumeUnit["LITTER"] = "L";
  EndOfLifeMassVolumeUnit["GALLON"] = "gal";
  EndOfLifeMassVolumeUnit["STANDARD_CUBIC_FOOT"] = "scf";
})(EndOfLifeMassVolumeUnit || (exports.EndOfLifeMassVolumeUnit = EndOfLifeMassVolumeUnit = {}));
var UseOfSoldMassVolumeUnit;
(function (UseOfSoldMassVolumeUnit) {
  UseOfSoldMassVolumeUnit["KG"] = "m3";
  UseOfSoldMassVolumeUnit["POUND"] = "MT";
  UseOfSoldMassVolumeUnit["SHORT_TON"] = "ST";
  UseOfSoldMassVolumeUnit["METRIC_TON"] = "MT";
  UseOfSoldMassVolumeUnit["LONG_TON"] = "LT";
  UseOfSoldMassVolumeUnit["LITTER"] = "L";
  UseOfSoldMassVolumeUnit["GALLON"] = "gal";
  UseOfSoldMassVolumeUnit["STANDARD_CUBIC_FOOT"] = "scf";
})(UseOfSoldMassVolumeUnit || (exports.UseOfSoldMassVolumeUnit = UseOfSoldMassVolumeUnit = {}));
var UseOfSoldElectricityUnit;
(function (UseOfSoldElectricityUnit) {
  UseOfSoldElectricityUnit["KILOJOULE"] = "kJ";
  UseOfSoldElectricityUnit["MEGAJOULE"] = "MJ";
  UseOfSoldElectricityUnit["GIGAJOULE"] = "GJ";
  UseOfSoldElectricityUnit["CALORIE"] = "cal";
  UseOfSoldElectricityUnit["KILOCALORIE"] = "kcal";
  UseOfSoldElectricityUnit["WATTHOUR"] = "Wh";
  UseOfSoldElectricityUnit["KILOWATT_HOUR"] = "kWh";
  UseOfSoldElectricityUnit["MEGAWATT_HOUR"] = "MWh";
  UseOfSoldElectricityUnit["GIGAWATT_HOUR"] = "GWh";
  UseOfSoldElectricityUnit["BRITISH_THERMAL_UNIT"] = "BTU";
  UseOfSoldElectricityUnit["THERM"] = "thm";
  UseOfSoldElectricityUnit["MMBTU"] = "MMBtu";
  UseOfSoldElectricityUnit["TON_HOURS"] = "ton-hr";
  UseOfSoldElectricityUnit["POUND_OF_STEAM"] = "lbs";
  UseOfSoldElectricityUnit["KG"] = "kg";
  UseOfSoldElectricityUnit["JOULE"] = "J";
})(UseOfSoldElectricityUnit || (exports.UseOfSoldElectricityUnit = UseOfSoldElectricityUnit = {}));
exports.EnergySolidUnit = {
  ...SolidUnit
};
exports.EnergyLiquidUnit = {
  ...SolidUnit,
  ...LiquidUnit,
  KILLO_LITTER: 'KL'
};
exports.EnergyGasUnit = {
  ...SolidUnit,
  ...LiquidUnit,
  ...GasUnit,
  ...FuelLiquidUnit,
  STANDARD_CUBIC_METER: 'sm3'
};
exports.EnergyElectricUnit = {
  ...ElectricPowerUnit
};
var EnergyUnitCategory;
(function (EnergyUnitCategory) {
  EnergyUnitCategory["SOLID"] = "SOLID";
  EnergyUnitCategory["LIQUID"] = "LIQUID";
  EnergyUnitCategory["GAS"] = "GAS";
  EnergyUnitCategory["ELECTRIC"] = "ELECTRIC";
})(EnergyUnitCategory || (exports.EnergyUnitCategory = EnergyUnitCategory = {}));
var ProductsSoldUnits;
(function (ProductsSoldUnits) {
  ProductsSoldUnits["UNITS"] = "UNITS";
  ProductsSoldUnits["METRIC_TONNES"] = "METRIC_TONNES";
  ProductsSoldUnits["KILOGRAMS"] = "KILOGRAMS";
})(ProductsSoldUnits || (exports.ProductsSoldUnits = ProductsSoldUnits = {}));
var PackagingUnits;
(function (PackagingUnits) {
  PackagingUnits["METRIC_TONNES"] = "METRIC_TONNES";
  PackagingUnits["KILOGRAMS"] = "KILOGRAMS";
})(PackagingUnits || (exports.PackagingUnits = PackagingUnits = {}));
exports.EnergyUnitCategoryMap = {
  [exports.EnergySolidUnit.POUND]: EnergyUnitCategory.SOLID,
  [exports.EnergySolidUnit.SHORT_TON]: EnergyUnitCategory.SOLID,
  [exports.EnergySolidUnit.METRIC_TON]: EnergyUnitCategory.SOLID,
  [exports.EnergySolidUnit.LONG_TON]: EnergyUnitCategory.SOLID,
  [exports.EnergySolidUnit.KG]: EnergyUnitCategory.SOLID,
  [exports.EnergySolidUnit.GRAM]: EnergyUnitCategory.SOLID,
  [exports.EnergyLiquidUnit.GALLON]: EnergyUnitCategory.LIQUID,
  [exports.EnergyLiquidUnit.LITTER]: EnergyUnitCategory.LIQUID,
  [exports.EnergyLiquidUnit.KILLO_LITTER]: EnergyUnitCategory.LIQUID,
  [exports.EnergyGasUnit.STANDARD_CUBIC_FOOT]: EnergyUnitCategory.GAS,
  [exports.EnergyGasUnit.STANDARD_CUBIC_METER]: EnergyUnitCategory.GAS,
  [exports.EnergyGasUnit.CUBIC_METER]: EnergyUnitCategory.GAS,
  [exports.EnergyElectricUnit.KILOWATT_HOUR]: EnergyUnitCategory.ELECTRIC,
  [exports.EnergyElectricUnit.MEGAWATT_HOUR]: EnergyUnitCategory.ELECTRIC,
  [exports.EnergyElectricUnit.GIGAWATT_HOUR]: EnergyUnitCategory.ELECTRIC
};
exports.ConsumptionUnitTypeMap = {
  [ConsumptionType.FUEL_SOLID]: SolidUnit,
  [ConsumptionType.FUEL_LIQUID]: LiquidUnit,
  [ConsumptionType.FUEL_GAS]: GasUnit,
  [ConsumptionType.FUEL_ELECTRIC]: ElectricUnit,
  [ConsumptionType.ELECTRICITY]: ElectricPowerUnit,
  [ConsumptionType.HEAT_STEAM_GAS]: HeatUnit,
  [ConsumptionType.DISTANCE]: DistanceUnit,
  [ConsumptionType.CURRENCY]: Currency,
  [ConsumptionType.VEHICLE]: VehicleUnit,
  [ConsumptionType.ENERGY]: EnergyUnit,
  [ConsumptionType.HEATING]: HeatSteamHeatingUnit,
  [ConsumptionType.COOLING]: HeatSteamCoolingUnit,
  [ConsumptionType.STEAM]: HeatSteamUnit
};
function typeToUnits() {
  const consumptionUnitMap = {};
  for (const [consumptionType, unitEnum] of Object.entries(exports.ConsumptionUnitTypeMap)) {
    consumptionUnitMap[consumptionType] = Object.values(unitEnum);
  }
  return consumptionUnitMap;
}
exports.ConsumptionUnitMap = typeToUnits();
function unitToUnitCode(ct, value) {
  for (const [unitCode, unitEnum] of Object.entries(exports.ConsumptionUnitTypeMap[ct])) {
    if (unitEnum === value) {
      return unitCode;
    }
  }
  return null;
}
function codeToUnit(ct, code) {
  for (const [unitCode, unitEnum] of Object.entries(exports.ConsumptionUnitTypeMap[ct])) {
    if (unitCode === code) {
      return unitEnum;
    }
  }
  return null;
}
exports.StationaryCombustionUnit = {
  ...SolidUnit,
  ...LiquidUnit,
  ...GasUnit
};
exports.MobileCombustionUnit = {
  ...DistanceUnit,
  ...LiquidUnit,
  ...GasUnit,
  ...ElectricPowerUnit
};
exports.FuelConsumptionUnit = {
  ...exports.EnergySolidUnit,
  ...exports.EnergyLiquidUnit,
  ...exports.EnergyElectricUnit,
  ...exports.EnergyGasUnit
};
exports.EnergyConsumptionUnit = {
  ...ElectricPowerUnit,
  ...exports.EnergyHeatingConsumptionUnit,
  ...EnergyCoolingConsumptionUnit,
  ...exports.EnergySteamConsumptionUnit,
  ...SolidUnit
};
var SolidUnitWithProductionVolume;
(function (SolidUnitWithProductionVolume) {
  SolidUnitWithProductionVolume["KILLO_LITTER"] = "KL";
  SolidUnitWithProductionVolume["LITTER"] = "L";
  SolidUnitWithProductionVolume["GALLON"] = "gal";
  SolidUnitWithProductionVolume["METRIC_TON"] = "MT";
  SolidUnitWithProductionVolume["KILLOGRAM"] = "kg";
  SolidUnitWithProductionVolume["SHORT_TON"] = "ST";
  SolidUnitWithProductionVolume["LONG_TON"] = "LT";
})(SolidUnitWithProductionVolume || (exports.SolidUnitWithProductionVolume = SolidUnitWithProductionVolume = {}));
var EnergyIntensityMonetaryUnits;
(function (EnergyIntensityMonetaryUnits) {
  EnergyIntensityMonetaryUnits["E_LAKH_INR"] = "E_LAKH_INR";
  EnergyIntensityMonetaryUnits["E_CRORE_INR"] = "E_CRORE_INR";
  EnergyIntensityMonetaryUnits["E_INR_PPP"] = "E_INR_PPP";
  EnergyIntensityMonetaryUnits["E_USD"] = "E_USD";
  EnergyIntensityMonetaryUnits["E_USD_PPP"] = "E_USD_PPP";
  EnergyIntensityMonetaryUnits["E_EUR"] = "E_EUR";
  EnergyIntensityMonetaryUnits["E_GBP"] = "E_GBP";
  EnergyIntensityMonetaryUnits["E_JPY"] = "E_JPY";
  EnergyIntensityMonetaryUnits["E_AUD"] = "E_AUD";
  EnergyIntensityMonetaryUnits["E_CNY"] = "E_CNY";
  EnergyIntensityMonetaryUnits["E_CAD"] = "E_CAD";
  EnergyIntensityMonetaryUnits["E_CHF"] = "E_CHF";
  EnergyIntensityMonetaryUnits["E_BRL"] = "E_BRL";
})(EnergyIntensityMonetaryUnits || (exports.EnergyIntensityMonetaryUnits = EnergyIntensityMonetaryUnits = {}));
exports.EnergyIntensityDistanceTraveled = {
  ...DistanceUnit
};
exports.EnergyIntensity = {
  ...SolidUnitWithProductionVolume,
  ...EnergyIntensitySize,
  ...EnergyIntensityNumberOfEmployee,
  ...EnergyIntensityMonetaryUnits,
  ...EnergyIntensityServicesProvided,
  ...exports.EnergyIntensityDistanceTraveled,
  ...EnergyIntensityOptionalHours,
  ...EnergyIntensityPerFloorArea,
  ...EnergyIntensityPerEmployee,
  ...EnergyIntensityPerProduct,
  ...EnergyIntensityPerRevenue
};
exports.MassAndVolumeUnits = {
  ...SolidUnit,
  ...LiquidUnit,
  ...GasUnit
};
var productAndPackagingEndOfLifeMassVolumeUnit;
(function (productAndPackagingEndOfLifeMassVolumeUnit) {
  productAndPackagingEndOfLifeMassVolumeUnit["KG"] = "m3";
  productAndPackagingEndOfLifeMassVolumeUnit["POUND"] = "MT";
  productAndPackagingEndOfLifeMassVolumeUnit["SHORT_TON"] = "ST";
  productAndPackagingEndOfLifeMassVolumeUnit["METRIC_TON"] = "MT";
  productAndPackagingEndOfLifeMassVolumeUnit["LONG_TON"] = "LT";
  productAndPackagingEndOfLifeMassVolumeUnit["LITTER"] = "L";
  productAndPackagingEndOfLifeMassVolumeUnit["GALLON"] = "gal";
  productAndPackagingEndOfLifeMassVolumeUnit["STANDARD_CUBIC_FOOT"] = "scf";
})(productAndPackagingEndOfLifeMassVolumeUnit || (exports.productAndPackagingEndOfLifeMassVolumeUnit = productAndPackagingEndOfLifeMassVolumeUnit = {}));
