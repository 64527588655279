"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.formSelectFiltersMapping = exports.FormSelectFiltersFunctions = void 0;
const activity_1 = require("../enum/activity");
function filterTransportAndDistributionOptions(options, data) {
  const {
    type
  } = data;
  return options.filter(option => {
    switch (type) {
      case activity_1.UpstreamDownstreamType.EMISSIONS_FROM_TRANSPORTATION:
        return [activity_1.EmissionFromTransportation.FUEL_BASED_METHOD, activity_1.EmissionFromTransportation.DISTANCE_BASED_METHOD, activity_1.EmissionFromTransportation.SPEND_BASED_METHOD].includes(option.key);
      case activity_1.UpstreamDownstreamType.EMISSIONS_FROM_DISTRIBUTION:
        return [activity_1.EmissionFromTransportation.SITE_SPECIFIC_METHOD, activity_1.EmissionFromTransportation.AVERAGE_DATA_METHOD].includes(option.key);
      default:
        return true;
    }
  });
}
function filterPublicPolicyTypeOptions(options, data) {
  const {
    category
  } = data;
  return options.filter(option => {
    switch (category) {
      case activity_1.PolicyCategory.FINANCIAL:
        return [activity_1.PublicPolicyType.DONATIONS, activity_1.PublicPolicyType.LOANS, activity_1.PublicPolicyType.SPONSORSHIP, activity_1.PublicPolicyType.RETAINERS, activity_1.PublicPolicyType.PURCHASE_OF_EVENT_TICKETS, activity_1.PublicPolicyType.OTHER].includes(option.key);
      case activity_1.PolicyCategory.IN_KIND:
        return [activity_1.PublicPolicyType.ADVERTISING, activity_1.PublicPolicyType.USE_OF_FACILITIES, activity_1.PublicPolicyType.DESIGN_AND_PRINTING, activity_1.PublicPolicyType.DONATION_OF_EQUIPMENT, activity_1.PublicPolicyType.PROVISION_OF_BOARD_MEMBERSHIP, activity_1.PublicPolicyType.EMPLOYMENT_CONSULTANCY_WORK_OFFERED].includes(option.key);
      default:
        return false;
    }
  });
}
function filterFranchises(options, data) {
  const {
    method
  } = data;
  return options.filter(option => {
    switch (method) {
      case activity_1.FranchiseMethod.FRANCHISE_SPECIFIC_METHOD:
        return [activity_1.FranchiseType.SUB_METERED_BY_TENANT, activity_1.FranchiseType.NON_SUB_METERED_BY_TENANT].includes(option.key);
      case activity_1.FranchiseMethod.AVERAGE_DATA_METHOD:
        return [activity_1.FranchiseType.FLOOR_PLAN_AVAILABLE, activity_1.FranchiseType.FLOOR_PLAN_NOT_AVAILABLE].includes(option.key);
      default:
        return true;
    }
  });
}
function filterUseSold(options, data) {
  const {
    type
  } = data;
  return options.filter(option => {
    switch (type) {
      case activity_1.UseSoldType.DIRECT_USE_PHASE_EMISSIONS:
        return [activity_1.UseSoldMethod.PRODUCTS_DIRECTLY_CONSUMING_ENERGY, activity_1.UseSoldMethod.FUELS_AND_FEED_STOCKS, activity_1.UseSoldMethod.GHG_EMISSIONS_DURING_USE].includes(option.key);
      case activity_1.UseSoldType.INDIRECT_USE_PHASE_EMISSIONS:
        return [activity_1.UseSoldMethod.PRODUCTS_INDIRECTLY_CONSUMING_ENERGY, activity_1.UseSoldMethod.INTERMEDIATE_PRODUCTS_CONSUMING_ENERGY_DIRECTLY].includes(option.key);
      default:
        return true;
    }
  });
}
function baseMetricUnit(options, data) {
  const {
    baseMetric
  } = data;
  const baseMetricUnitMap = {
    [activity_1.BaseMetric.UNITS_OF_PRODUCT]: [activity_1.BaseMetricUnit.PER_KILOGRAM, activity_1.BaseMetricUnit.UNIT_OF_PRODUCT],
    [activity_1.BaseMetric.PRODUCTION_VOLUME]: [activity_1.BaseMetricUnit.PER_METRIC_TONS, activity_1.BaseMetricUnit.PER_LITER, activity_1.BaseMetricUnit.PER_MWH],
    [activity_1.BaseMetric.SIZE]: [activity_1.BaseMetricUnit.PER_SQUARE_METERS, activity_1.BaseMetricUnit.PER_CUBIC_METERS],
    [activity_1.BaseMetric.NUMBER_OF_FULL_TIME_EMPLOYEES]: [activity_1.BaseMetricUnit.NUMBER],
    [activity_1.BaseMetric.REVENUE]: [activity_1.BaseMetricUnit.REVENUE, activity_1.BaseMetricUnit.SALES, activity_1.BaseMetricUnit.NET_SALES, activity_1.BaseMetricUnit.VALUE_ADDED, activity_1.BaseMetricUnit.EBITDA, activity_1.BaseMetricUnit.OPERATING_PROFIT_INCOME, activity_1.BaseMetricUnit.NET_PROFIT_INCOME, activity_1.BaseMetricUnit.MARKET_CAPITALIZATION, activity_1.BaseMetricUnit.TOTAL_ASSETS, activity_1.BaseMetricUnit.SHAREHOLDER_EQUITY]
  };
  const validOptions = baseMetricUnitMap[baseMetric];
  return options.filter(option => {
    return validOptions.includes(option.key);
  });
}
function metricFilter(options, data) {
  const {
    parameter
  } = data;
  const metricMap = {
    [activity_1.OpennessOfBusinessParameterTypes.CONCENTRATION_OF_PURCHASES]: [activity_1.OpennessOfBusinessMetrics.PURCHASES_FROM_TRADING_HOUSES_AS_PERCENT_OF_TOTAL_PURCHASES, activity_1.OpennessOfBusinessMetrics.NUMBER_OF_TRADING_HOUSES_WHERE_PURCHASES_ARE_MADE_FROM, activity_1.OpennessOfBusinessMetrics.PURCHASES_FROM_TOP_10_TRADING_HOUSES_AS_PERCENT_OF_TOTAL_PURCHASES_FROM_TRADING_HOUSES],
    [activity_1.OpennessOfBusinessParameterTypes.CONCENTRATION_OF_SALES]: [activity_1.OpennessOfBusinessMetrics.NUMBER_OF_DEALERS_DISTRIBUTORS_TO_WHOM_SALES_ARE_MADE, activity_1.OpennessOfBusinessMetrics.SALES_TO_DEALER_DISTRIBUTORS_AS_PERCENT_OF_TOTAL_SALE, activity_1.OpennessOfBusinessMetrics.SALES_TO_TOP_10_DEALERS_DISTRIBUTORS_AS_PERCENT_OF_TOTAL_SALES_TO_DEALERS_DISTRIBUTORS],
    [activity_1.OpennessOfBusinessParameterTypes.SHARE_OF_RPTS]: [activity_1.OpennessOfBusinessMetrics.PURCHASES_WITH_RELATED_PARTIES_TOTAL_PURCHASES, activity_1.OpennessOfBusinessMetrics.SALES_TO_RELATED_PARTIES_TOTAL_SALES, activity_1.OpennessOfBusinessMetrics.LOANS_AND_ADVANCES_GIVEN_TO_RELATED_PARTIES_TOTAL_LOANS_AND_ADVANCES, activity_1.OpennessOfBusinessMetrics.INVESTMENTS_IN_RELATED_PARTIES_TOTAL_INVESTMENTS_MADE]
  };
  const validOptions = metricMap[parameter];
  return options.filter(option => {
    return validOptions.includes(option.key);
  });
}
function valueTypeFilter(options, data) {
  const {
    metric
  } = data;
  const valueTypeMap = {
    [activity_1.OpennessOfBusinessMetrics.PURCHASES_FROM_TRADING_HOUSES_AS_PERCENT_OF_TOTAL_PURCHASES]: [activity_1.ValueTypes.PERCENTAGE],
    [activity_1.OpennessOfBusinessMetrics.NUMBER_OF_TRADING_HOUSES_WHERE_PURCHASES_ARE_MADE_FROM]: [activity_1.ValueTypes.NUMBER],
    [activity_1.OpennessOfBusinessMetrics.PURCHASES_FROM_TOP_10_TRADING_HOUSES_AS_PERCENT_OF_TOTAL_PURCHASES_FROM_TRADING_HOUSES]: [activity_1.ValueTypes.PERCENTAGE],
    [activity_1.OpennessOfBusinessMetrics.NUMBER_OF_DEALERS_DISTRIBUTORS_TO_WHOM_SALES_ARE_MADE]: activity_1.ValueTypes.NUMBER,
    [activity_1.OpennessOfBusinessMetrics.SALES_TO_DEALER_DISTRIBUTORS_AS_PERCENT_OF_TOTAL_SALE]: activity_1.ValueTypes.PERCENTAGE,
    [activity_1.OpennessOfBusinessMetrics.SALES_TO_TOP_10_DEALERS_DISTRIBUTORS_AS_PERCENT_OF_TOTAL_SALES_TO_DEALERS_DISTRIBUTORS]: activity_1.ValueTypes.PERCENTAGE,
    [activity_1.OpennessOfBusinessMetrics.PURCHASES_WITH_RELATED_PARTIES_TOTAL_PURCHASES]: [activity_1.ValueTypes.PERCENTAGE],
    [activity_1.OpennessOfBusinessMetrics.SALES_TO_RELATED_PARTIES_TOTAL_SALES]: [activity_1.ValueTypes.PERCENTAGE],
    [activity_1.OpennessOfBusinessMetrics.LOANS_AND_ADVANCES_GIVEN_TO_RELATED_PARTIES_TOTAL_LOANS_AND_ADVANCES]: [activity_1.ValueTypes.PERCENTAGE],
    [activity_1.OpennessOfBusinessMetrics.INVESTMENTS_IN_RELATED_PARTIES_TOTAL_INVESTMENTS_MADE]: [activity_1.ValueTypes.PERCENTAGE]
  };
  const validOptions = valueTypeMap[metric];
  return options.filter(option => {
    return validOptions.includes(option.key);
  });
}
var FormSelectFiltersFunctions;
(function (FormSelectFiltersFunctions) {
  FormSelectFiltersFunctions["TRANSPORT_AND_DISTRIBUTION_FILTER"] = "TRANSPORT_AND_DISTRIBUTION_FILTER";
  FormSelectFiltersFunctions["FRANCHISE_FILTER"] = "FRANCHISE_FILTER";
  FormSelectFiltersFunctions["SOLD_PRODUCT_FILTER"] = "SOLD_PRODUCT_FILTER";
  FormSelectFiltersFunctions["PUBLIC_POLICY_TYPE_FILTER"] = "PUBLIC_POLICY_TYPE_FILTER";
  FormSelectFiltersFunctions["BASE_METRIC_UNIT_FILTER"] = "BASE_METRIC_UNIT_FILTER";
  FormSelectFiltersFunctions["METRIC_FILTER"] = "METRIC_FILTER";
  FormSelectFiltersFunctions["VALUE_TYPE_FILTER"] = "VALUE_TYPE_FILTER";
})(FormSelectFiltersFunctions || (exports.FormSelectFiltersFunctions = FormSelectFiltersFunctions = {}));
exports.formSelectFiltersMapping = {
  TRANSPORT_AND_DISTRIBUTION_FILTER: filterTransportAndDistributionOptions,
  PUBLIC_POLICY_TYPE_FILTER: filterPublicPolicyTypeOptions,
  FRANCHISE_FILTER: filterFranchises,
  SOLD_PRODUCT_FILTER: filterUseSold,
  BASE_METRIC_UNIT_FILTER: baseMetricUnit,
  METRIC_FILTER: metricFilter,
  VALUE_TYPE_FILTER: valueTypeFilter
};
